import { useMutation } from "react-query";
import pb from "lib/pocketbase";

export default function useLogin() {
  async function login({ email, password }) {
    const user = await pb.collection("users").authWithPassword(email, password);
    return user;
  }
  // useMutation does the following:
  // Wraps try/catch blocks around login mutation function automatically
  // Generates the "isLoading" state (sets isLoading to true)
  return useMutation(login);
}
