import React from "react";
import ReportCultureOutput from "./ReportCultureOutput";
import ReportSubstrateOutput from "./ReportSubstrateOutput";
import ReportGrowthOutput from "./ReportGrowthOutput";
import ReportHarvestOutput from "./ReportHarvestOutput";
import "css/report-output.css";

// THEN ADD IN DEFAULTS FOR CHECKED - ensure these stay the same on a recheck
// FIX FIRST!!! -- Adding Analytics section - if nothing is in there...return blank rather than null (likely what is causing crash)
// ADD IN PARAGRAPH GOING OVER AVERAGES BEFORE EACH SECTION
// --- Need to ensure these are correct
// Need to add dates section in for notes
// ADD IN ABILITY TO EXPORT DATA TO PDF/EXCEL SPREADSHEET

export default function ReportOutput(props) {
  const { selectedOptions, growBags, cultures, substrates } = props;
  const formatDate = (dateString) => {
    if (!dateString || isNaN(new Date(dateString).getTime())) {
      return ""; // Return empty string if date is not valid
    }
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  // Since json structure is the same, this can be used for cultures AND substrates
  const formatNutrientConcentration = (nutrientArray) => {
    const elements = [];
    nutrientArray.forEach((nutrient, index) => {
      elements.push(`- ${nutrient.grams}g ${nutrient.ingredient}`);
      if (index !== nutrientArray.length - 1) {
        // If it's not the last element, add a <br /> tag
        elements.push(<br key={index} />);
      }
    });
    return elements;
  };

  const formatHeader = (key) => {
    if (key === "mLCultureUsed") {
      return "Avg mL Culture Used per Container";
    }
    return key
      .replace(/([A-Z])/g, " $1")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .trim();
  };
  //Function to check if a string contains any alphabetic characters
  const hasAlphabeticCharacters = (str) => /[a-zA-Z]/.test(str);

  //Calculate the number of bags discarded for each grow
  const calculateDiscardedBags = (batchId) => {
    const discardedBags = growBags.filter(
      (growBag) => growBag.batch_id_number === `discarded - ${batchId}`
    );
    return discardedBags.reduce((total, bag) => total + bag.number_of_bags, 0);
  };

  //Calculate the number of days to display in the report
  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  };

  //Creating the bullets for the Notes section - based on what the user checks in the checkbox
  const notesMapping = {
    preIncubationNote: "pre_incubation_note",
    preGrowthNote: "pre_growth_note",
    postHarvestNote: "post_harvest_note",
    postDriedNote: "post_dried_note",
    discardNote: "discard_note",
  };

  let filteredCultures = cultures;
  if (!selectedOptions.selectAllCulturesBoolean) {
    const startDate = new Date(selectedOptions.cultureDateRange.start);
    const endDate = new Date(selectedOptions.cultureDateRange.end);
    endDate.setHours(23, 59, 59, 999); // Set to end of day

    filteredCultures = cultures.filter((culture) => {
      const cultureDate = new Date(culture.date_started);
      return cultureDate >= startDate && cultureDate <= endDate;
    });
  }

  let filteredGrowBags = growBags;
  if (selectedOptions.growthSection) {
    const startDate = new Date(selectedOptions.growthDateRange.start);
    const endDate = new Date(selectedOptions.growthDateRange.end);
    endDate.setHours(23, 59, 59, 999); // Set to end of day

    filteredGrowBags = growBags.filter((growBag) => {
      const incubationDate = new Date(growBag.date_incubation_began);
      return (
        incubationDate >= startDate &&
        incubationDate <= endDate &&
        !hasAlphabeticCharacters(growBag.batch_id_number)
      );
    });

    // Sort the filteredGrowBags by batch_id_number in the desired order
    filteredGrowBags.sort((a, b) => {
      const [aMain, aSub] = a.batch_id_number.split("-").map(Number);
      const [bMain, bSub] = b.batch_id_number.split("-").map(Number);

      // First, compare the main batch id numbers
      if (aMain !== bMain) {
        return bMain - aMain; // For descending order
      }

      // If main batch id numbers are the same, compare the sub batch id numbers
      return (bSub || 0) - (aSub || 0); // For descending order
    });
  }

  // Filter growBags for harvest data
  let filteredHarvestBags = growBags.filter((growBag) => {
    const harvestDate = new Date(growBag.harvest_date);
    const startDate = new Date(selectedOptions.harvestDateRange.start);
    const endDate = new Date(selectedOptions.harvestDateRange.end);
    endDate.setHours(23, 59, 59, 999); // Set to end of day

    return (
      hasAlphabeticCharacters(growBag.batch_id_number) &&
      !growBag.batch_id_number.includes("discarded") &&
      harvestDate >= startDate &&
      harvestDate <= endDate
    );
  });

  return (
    <div className="report-generation-output">
      <h2>Report Output</h2>
      <p>
        This is where your generated report will be displayed based on the
        selected options and the provided data.
      </p>
      {selectedOptions.cultures && (
        <ReportCultureOutput
          filteredCultures={filteredCultures}
          formatDate={formatDate}
          formatNutrientConcentration={formatNutrientConcentration}
        />
      )}

      {selectedOptions.substrates && (
        <ReportSubstrateOutput
          substrates={substrates}
          formatNutrientConcentration={formatNutrientConcentration}
        />
      )}

      {selectedOptions.growthSection && (
        <ReportGrowthOutput
          filteredGrowBags={filteredGrowBags}
          formatDate={formatDate}
          selectedOptions={selectedOptions}
          calculateDiscardedBags={calculateDiscardedBags}
          formatHeader={formatHeader}
          calculateDaysDifference={calculateDaysDifference}
        />
      )}

      {selectedOptions.harvestSection && (
        <ReportHarvestOutput
          filteredHarvestBags={filteredHarvestBags}
          formatDate={formatDate}
          selectedOptions={selectedOptions}
          formatHeader={formatHeader}
          calculateDaysDifference={calculateDaysDifference}
        />
      )}

      {selectedOptions.notes && (
        <div className="report-notes-section">
          <h3>Notes</h3>
          <ul>
            {filteredGrowBags.map((growBag) => {
              const notesList = Object.entries(notesMapping)
                .filter(
                  ([key]) => selectedOptions[key] && growBag[notesMapping[key]]
                )
                .map(([key, property]) => {
                  return (
                    <li key={key}>
                      {formatHeader(key)}:
                      <ul>
                        <li>{growBag[property]}</li>
                      </ul>
                    </li>
                  );
                });

              if (notesList.length > 0) {
                return (
                  <li key={growBag.id}>
                    Batch ID: {growBag.batch_id_number} -- Incubation Began:{" "}
                    {formatDate(growBag.date_incubation_began)}
                    <ul>{notesList}</ul>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
