import React, { useState } from "react";
import InventoryTransactionModal from "./InventoryTransactionModal";
import AddRemoveProduct from "./AddRemoveProduct";

import "css/inventory.css";

////// NEXT STEPS!!! ///////

// ENSURE THE INVENTORY DISPLAY IS PULLING FROM THE INVENTORY DATABASE
// PUT TWO SECTIONS BENEATH THE DISPLAY -- ONE FOR ADDING TO INVENTORY, ONE FOR SUBTRACTING FROM INVENTORY
// FOR SUBTRACTING, HAVE AN INPUT FOR THE USER TO PUT DATE, WHERE IT IS GOING, FOR HOW MUCH, ETC.

export default function ViewInventory(props) {
  const {
    growBags,
    inventoryTransactions,
    mushrooms,
    currentUser,
    cultures,
    substrates,
    productInventoryTransactions,
    productTypes,
    containerTypes,
  } = props;

  const [showInventoryTransactionModal, setShowInventoryTransactionModal] =
    useState(false);
  const [addingRemovingProduct, setAddingRemovingProduct] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(
    "mushroom_transactions"
  );
  const [showSuccessModal, setShowSuccessModal] = useState({
    show: false,
    data: null,
    productTypeName: null,
  });

  const mushroomTypes = Array.from(
    new Set(mushrooms.map((mushroom) => mushroom.mushroom_name))
  );

  // May have to reengage this once I create a tent collection
  // const tents = Array.from(
  //   new Set(growBags.map((growBag) => growBag.tent_number))
  // ).filter((tent) => tent !== 0);

  const filterByStatusAndTent = (status, mushroomType, tent = null) => {
    return growBags.filter((growBag) => {
      return (
        growBag.current_status === status &&
        growBag.mushroom_type === mushroomType &&
        growBag.bag_type &&
        (tent === null || growBag.tent_number === tent)
      );
    });
  };

  const totalBagsByStatusAndTent = (status, mushroomType, tent = null) => {
    return filterByStatusAndTent(status, mushroomType, tent).reduce(
      (accumulator, growBag) => accumulator + growBag.number_of_bags,
      0
    );
  };

  const calculateTotal = (field, transactionType, mushroomType) => {
    return inventoryTransactions.reduce((accumulator, transaction) => {
      if (
        transaction.transaction_type === transactionType &&
        transaction.mushroom_type === mushroomType
      ) {
        return accumulator + (parseFloat(transaction[field]) || 0);
      }
      return accumulator;
    }, 0);
  };

  const calculateInventory = (field, mushroomType) => {
    return (
      calculateTotal(field, "add", mushroomType) -
      calculateTotal(field, "subtract", mushroomType)
    );
  };

  // Use "Both" as the driedType when adding together Dehydrated and Freeze Dried
  const calculateInventoryByDriedType = (field, mushroomType, driedType) => {
    let inventoryFromTransactions = inventoryTransactions.reduce(
      (accumulator, transaction) => {
        if (
          (transaction.mushroom_type === mushroomType &&
            transaction.dried_type === driedType) ||
          (transaction.mushroom_type === mushroomType &&
            transaction.dried_substrate_grams)
        ) {
          if (transaction.transaction_type === "add") {
            return accumulator + (parseFloat(transaction[field]) || 0);
          } else if (transaction.transaction_type === "subtract") {
            return accumulator - (parseFloat(transaction[field]) || 0);
          }
        }
        return accumulator;
      },
      0
    );

    return inventoryFromTransactions;
  };

  function calcProductInventoryByMushroomTypeAndDriedType(
    productType,
    mushroomType,
    driedType
  ) {
    let added = 0;
    let removed = 0;

    productInventoryTransactions.forEach((transaction) => {
      if (
        transaction.product_type === productType.id &&
        transaction.mushroom_type === mushroomType &&
        transaction.dried_type === driedType
      ) {
        if (transaction.added_or_removed === "add") {
          added += transaction.quantity;
        } else if (transaction.added_or_removed === "remove") {
          removed += transaction.quantity;
        }
      }
    });

    return added - removed;
  }

  function calculateTotalBagsForContainer(
    mushroomType,
    status,
    containerType,
    tentNumber
  ) {
    if (tentNumber) {
      return growBags
        .filter(
          (bag) =>
            bag.mushroom_type === mushroomType &&
            bag.current_status === status &&
            bag.container_type === containerType &&
            bag.tent_number === tentNumber
        )
        .reduce((total, bag) => total + bag.number_of_bags, 0);
    } else
      return growBags
        .filter(
          (bag) =>
            bag.mushroom_type === mushroomType &&
            bag.current_status === status &&
            bag.container_type === containerType
        )
        .reduce((total, bag) => total + bag.number_of_bags, 0);
  }

  console.log(
    growBags
      .filter(
        (bag) =>
          bag.mushroom_type === "Cordyceps" &&
          bag.current_status === "Growing" &&
          bag.container_type === "Bag" &&
          bag.tent_number === 1
      )
      .reduce((total, bag) => total + bag.number_of_bags, 0)
  );

  const transactionList = (transactionType) => {
    if (transactionType === "add") {
      return (
        <>
          <table className="transaction-table">
            <thead>
              <tr>
                <th>Batch ID/Product Add</th>
                <th>Date Added</th>
                <th>Add Details</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {inventoryTransactions
                .filter(
                  (transaction) =>
                    transaction.transaction_type === transactionType
                )
                .map((transaction) => (
                  <tr key={transaction.id}>
                    <td>
                      {transaction.from_batch_id
                        ? transaction.from_batch_id
                        : transaction.add_type}
                    </td>
                    <td>{formatDate(transaction.transaction_date)}</td>
                    <td>
                      {transaction.total_fresh ? (
                        <>
                          <span>
                            {transaction.total_fresh}g Fresh{" "}
                            {transaction.mushroom_type}
                          </span>
                          <br />
                          <span>
                            (A: {transaction.grade_a_fresh}g, B:{" "}
                            {transaction.grade_b_fresh}g, C:{" "}
                            {transaction.grade_c_fresh}g)
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            {transaction.total_dried}g {transaction.dried_type}{" "}
                            Dried {transaction.mushroom_type}
                          </span>
                          <br />
                          <span>
                            (A: {transaction.grade_a_dried}g, B:{" "}
                            {transaction.grade_b_dried}g, C:{" "}
                            {transaction.grade_c_dried}g)
                          </span>
                          <br />
                          <span>
                            Dried Substrate Added:{" "}
                            {transaction.dried_substrate_grams}g
                          </span>
                        </>
                      )}
                    </td>
                    <td>{transaction.transaction_note || ""}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      );
    } else if (transactionType === "subtract") {
      return (
        <>
          <table className="transaction-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Subtract Details</th>
                <th>Product Details</th>
                <th>Customer Details</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {inventoryTransactions
                .filter(
                  (transaction) =>
                    transaction.transaction_type === transactionType
                )
                .map((transaction) => {
                  // Determine the cost to powderize for this specific transaction
                  const displayCostToPowderize = `Cost to powderize: $${
                    transaction.cost_to_powderize || 0
                  }`;

                  // Determine the note to display based on the transaction type
                  const noteToDisplay =
                    transaction.subtract_type === "Powderizing" ? (
                      <>
                        {displayCostToPowderize}
                        <br />
                        {transaction.transaction_note || ""}
                      </>
                    ) : (
                      transaction.transaction_note || ""
                    );

                  return (
                    <tr key={transaction.id}>
                      <td>{transaction.subtract_type}</td>
                      <td>{formatDate(transaction.transaction_date)}</td>
                      <td>
                        {transaction.total_fresh ? (
                          <>
                            <span>
                              {transaction.total_fresh}g Fresh{" "}
                              {transaction.mushroom_type}
                            </span>
                            <br />
                            <span>
                              (A: {transaction.grade_a_fresh}g, B:{" "}
                              {transaction.grade_b_fresh}g, C:{" "}
                              {transaction.grade_c_fresh}g)
                            </span>
                          </>
                        ) : (
                          <>
                            <span>
                              {transaction.total_dried}g{" "}
                              {transaction.dried_type} Dried{" "}
                              {transaction.mushroom_type}
                            </span>
                            <br />
                            <span>
                              (A: {transaction.grade_a_dried}g, B:{" "}
                              {transaction.grade_b_dried}g, C:{" "}
                              {transaction.grade_c_dried}g)
                            </span>
                            <br />
                            <span>
                              Dried Substrate Removed:{" "}
                              {transaction.dried_substrate_grams}g
                            </span>
                          </>
                        )}
                      </td>
                      {transaction.product_type && (
                        <td>
                          <span>
                            {transaction.expand.product_type.name || "N/A"}
                          </span>
                          <br />
                          <span>
                            Quantity: {transaction.quantity_of_product || "N/A"}
                          </span>
                        </td>
                      )}
                      {!transaction.product_type && (
                        <td>
                          <span>N/A</span>
                        </td>
                      )}

                      <td>
                        <span>{transaction.customer_name || "N/A"}</span>
                        {transaction.money_received > 0 && (
                          <>
                            <br />
                            <span>Money Received:</span>
                            <br />
                            <span>${transaction.money_received}</span>
                          </>
                        )}
                      </td>
                      <td>{noteToDisplay}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      );
    }
  };

  function handleClickAddSubtractProduct() {
    setAddingRemovingProduct((previousState) => !previousState);
    window.scrollTo(0, 160);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "N/A";
    }
    return date.toLocaleDateString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  function gramsToPounds(grams) {
    const pounds = (grams / 453.59237).toFixed(2);
    return isNaN(pounds) ? "0.00" : pounds;
  }

  async function handleCancel() {
    setShowSuccessModal({ show: false, data: null, productTypeName: null });
    setAddingRemovingProduct(false);
  }

  return (
    <>
      {showSuccessModal.show && showSuccessModal.data && (
        <div className="product-added-success-modal">
          <div className="product-added-success-modal-content">
            <span
              className="product-added-success-modal-close-button"
              onClick={handleCancel}
            >
              &times;
            </span>
            <h2>
              {showSuccessModal.data.quantity}{" "}
              {showSuccessModal.productTypeName}
              {showSuccessModal.data.quantity > 1 ? "s" : ""}{" "}
              {showSuccessModal.data.dried_type}{" "}
              {showSuccessModal.data.added_or_removed === "add"
                ? `
              ${showSuccessModal.data.mushroom_type} successfully added to your
              product inventory!`
                : `${showSuccessModal.data.mushroom_type} successfully removed from your product inventory!`}
            </h2>
            {showSuccessModal.data.added_or_removed === "remove" && (
              <p>Removal Type: {showSuccessModal.data.type_of_removal}</p>
            )}
            <p>
              Mushroom Type: {showSuccessModal.data.dried_type}{" "}
              {showSuccessModal.data.mushroom_type}
            </p>
            <p>Product Type: {showSuccessModal.productTypeName}</p>
            <p>Product Quantity: {showSuccessModal.data.quantity}</p>
            <p>Culture Type: {showSuccessModal.data.culture_type}</p>
            <p>Substrate Type: {showSuccessModal.data.substrate_type}</p>
            {showSuccessModal.data.date_removed && (
              <p>
                {" "}
                Date Removed:{" "}
                {new Date(showSuccessModal.data.inventory_removed_date)
                  .toString()
                  .split(" ")
                  .slice(0, 4)
                  .join(" ")}
              </p>
            )}
            {showSuccessModal.data.customer_name && (
              <p>Customer Name: {showSuccessModal.data.customer_name}</p>
            )}
            {showSuccessModal.data.customer_email && (
              <p>Customer Email: {showSuccessModal.data.customer_email}</p>
            )}
            {showSuccessModal.data.customer_phone && (
              <p>Customer Phone: {showSuccessModal.data.customer_phone}</p>
            )}
            {showSuccessModal.data.money_received && (
              <p>Money Received: ${showSuccessModal.data.money_received}</p>
            )}
            {showSuccessModal.data.added_or_removed === "add" && (
              <>
                <p>
                  Total Overall {showSuccessModal.data.dried_type}{" "}
                  {showSuccessModal.data.mushroom_type} Used:{" "}
                </p>
                <p className="overall-gram-number">
                  {showSuccessModal.data.total_grams_overall}g
                </p>
                <p>
                  Total A Grade Used:{" "}
                  {showSuccessModal.data.total_grams_a_grade_used}g
                </p>
                <p>
                  Total B Grade Used:{" "}
                  {showSuccessModal.data.total_grams_b_grade_used}g
                </p>
                <p>
                  Total C Grade Used:{" "}
                  {showSuccessModal.data.total_grams_c_grade_used}g
                </p>
                <p>
                  Date Added:{" "}
                  {new Date(showSuccessModal.data.inventory_added_date)
                    .toString()
                    .split(" ")
                    .slice(0, 4)
                    .join(" ")}
                </p>
              </>
            )}
          </div>
        </div>
      )}

      <div className="inventory-container">
        <div className="all-sections">
          <div className="incubating">
            <h4>Incubating</h4>
            <table>
              <thead>
                <tr>
                  <th>Mushroom Type</th>
                  <th>Bags</th>
                  {containerTypes.map((container) =>
                    container.container_type !== "Bag" ? (
                      <th key={container.container_type}>
                        {container.container_type}s
                      </th>
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {mushroomTypes.map((type) => (
                  <tr key={`${type}-incubating`}>
                    <td>{type}</td>
                    <td>{totalBagsByStatusAndTent("Incubating", type)}</td>
                    {containerTypes.map((container) =>
                      container.container_type !== "Bag" ? (
                        <td key={container.container_type}>
                          {calculateTotalBagsForContainer(
                            type,
                            "Incubating",
                            container.container_type
                          )}
                        </td>
                      ) : null
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="growing">
            <h4>Growing</h4>
            <h5 className="growing-table-header">Total</h5>
            <table>
              <thead>
                <tr>
                  <th>Mushroom Type</th>
                  <th>Bags</th>
                  {containerTypes.map((container) =>
                    container.container_type !== "Bag" ? (
                      <th key={container.container_type}>
                        {container.container_type}s
                      </th>
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {mushroomTypes.map((type) => (
                  <tr key={`${type}-growing-total`}>
                    <td>{type}</td>
                    <td>
                      {calculateTotalBagsForContainer(type, "Growing", "Bag")}
                    </td>
                    {containerTypes.map((container) =>
                      container.container_type !== "Bag" ? (
                        <td key={`${type}-${container.container_type}`}>
                          {calculateTotalBagsForContainer(
                            type,
                            "Growing",
                            container.container_type
                          )}
                        </td>
                      ) : null
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <h5 className="growing-table-header">Tent 1</h5>
            <table>
              <thead>
                <tr>
                  <th>Mushroom Type</th>
                  <th>Bags</th>
                  {containerTypes.map((container) =>
                    container.container_type !== "Bag" ? (
                      <th key={container.container_type}>
                        {container.container_type}s
                      </th>
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {mushroomTypes.map((type) => (
                  <tr key={`${type}-growing-tent1`}>
                    <td>{type}</td>
                    <td>
                      {calculateTotalBagsForContainer(
                        type,
                        "Growing",
                        "Bag",
                        1
                      )}
                    </td>
                    {containerTypes.map((container) =>
                      container.container_type !== "Bag" ? (
                        <td key={container.container_type}>
                          {calculateTotalBagsForContainer(
                            type,
                            "Growing",
                            container.container_type,
                            1
                          )}
                        </td>
                      ) : null
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <h5 className="growing-table-header">Tent 2</h5>
            <table>
              <thead>
                <tr>
                  <th>Mushroom Type</th>
                  <th>Bags</th>
                  {containerTypes.map((container) =>
                    container.container_type !== "Bag" ? (
                      <th key={container.container_type}>
                        {container.container_type}s
                      </th>
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {mushroomTypes.map((type) => (
                  <tr key={`${type}-growing-tent2`}>
                    <td>{type}</td>
                    <td>
                      {calculateTotalBagsForContainer(
                        type,
                        "Growing",
                        "Bag",
                        2
                      )}
                    </td>
                    {containerTypes.map((container) =>
                      container.container_type !== "Bag" ? (
                        <td key={container.container_type}>
                          {calculateTotalBagsForContainer(
                            type,
                            "Growing",
                            container.container_type,
                            2
                          )}
                        </td>
                      ) : null
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <h5 className="growing-table-header">Tent 3</h5>
            <table>
              <thead>
                <tr>
                  <th>Mushroom Type</th>
                  <th>Bags</th>
                  {containerTypes.map((container) =>
                    container.container_type !== "Bag" ? (
                      <th key={container.container_type}>
                        {container.container_type}s
                      </th>
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {mushroomTypes.map((type) => (
                  <tr key={`${type}-growing-tent3`}>
                    <td>{type}</td>
                    <td>
                      {calculateTotalBagsForContainer(
                        type,
                        "Growing",
                        "Bag",
                        3
                      )}
                    </td>
                    {containerTypes.map((container) =>
                      container.container_type !== "Bag" ? (
                        <td key={container.container_type}>
                          {calculateTotalBagsForContainer(
                            type,
                            "Growing",
                            container.container_type,
                            3
                          )}
                        </td>
                      ) : null
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Commented out to go back to default in case */}
          {/* <div className="growing">
            <h4>Growing</h4>
            <table>
              <thead>
                <tr>
                  <th>Mushroom Type</th>
                  <th>Total Bags</th>
                  {tents.map((tent, i) => (
                    <th key={i}>Tent {tent}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {mushroomTypes.map((type) => (
                  <tr key={`${type}-growing`}>
                    <td>{type}</td>
                    <td>{totalBagsByStatusAndTent("Growing", type)}</td>
                    {tents.map((tent) => (
                      <td key={`tent-${tent}`}>
                        {totalBagsByStatusAndTent("Growing", type, tent)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
          {/* Commented out for now as we don't plan on having fresh-inventory for the foreseeable future */}
          {/* <div className="fresh-inventory">
            <h4>Fresh Mushroom Inventory</h4>
            <table>
              <thead>
                <tr>
                  <th>Mushroom Type</th>
                  <th>Total</th>
                  <th>A Grade</th>
                  <th>B Grade</th>
                  <th>C Grade</th>
                </tr>
              </thead>
              <tbody>
                {mushroomTypes.map((type) => (
                  <tr key={`${type}-fresh`}>
                    <td>{type}</td>
                    <td>
                      {calculateInventory("total_fresh", type)}g (
                      {gramsToPounds(calculateInventory("total_fresh", type))}{" "}
                      lbs)
                    </td>
                    <td>
                      {calculateInventory("grade_a_fresh", type)}g (
                      {gramsToPounds(calculateInventory("grade_a_fresh", type))}{" "}
                      lbs)
                    </td>
                    <td>
                      {calculateInventory("grade_b_fresh", type)}g (
                      {gramsToPounds(calculateInventory("grade_b_fresh", type))}{" "}
                      lbs)
                    </td>
                    <td>
                      {calculateInventory("grade_c_fresh", type)}g (
                      {gramsToPounds(calculateInventory("grade_c_fresh", type))}{" "}
                      lbs)
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
          <div className="dried-inventory">
            <h4>Dried Mushroom Inventory</h4>
            {mushroomTypes.map((type) => (
              <div key={`${type}-dried`}>
                <h5>{type}</h5>

                <div className="dehydrated-inventory">
                  <h6>Dehydrated</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Total Fruiting Body</th>
                        <th>A Grade</th>
                        <th>B Grade</th>
                        <th>C Grade</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {calculateInventoryByDriedType(
                            "total_dried",
                            type,
                            "Dehydrated"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "total_dried",
                              type,
                              "Dehydrated"
                            )
                          )}{" "}
                          lbs)
                        </td>
                        <td>
                          {calculateInventoryByDriedType(
                            "grade_a_dried",
                            type,
                            "Dehydrated"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "grade_a_dried",
                              type,
                              "Dehydrated"
                            )
                          )}{" "}
                          lbs)
                        </td>
                        <td>
                          {calculateInventoryByDriedType(
                            "grade_b_dried",
                            type,
                            "Dehydrated"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "grade_b_dried",
                              type,
                              "Dehydrated"
                            )
                          )}{" "}
                          lbs)
                        </td>
                        <td>
                          {calculateInventoryByDriedType(
                            "grade_c_dried",
                            type,
                            "Dehydrated"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "grade_c_dried",
                              type,
                              "Dehydrated"
                            )
                          )}{" "}
                          lbs)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="freeze-dried-inventory">
                  <h6>Freeze Dried</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Total Fruiting Body</th>
                        <th>A Grade</th>
                        <th>B Grade</th>
                        <th>C Grade</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {calculateInventoryByDriedType(
                            "total_dried",
                            type,
                            "Freeze"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "total_dried",
                              type,
                              "Freeze"
                            )
                          )}{" "}
                          lbs)
                        </td>
                        <td>
                          {calculateInventoryByDriedType(
                            "grade_a_dried",
                            type,
                            "Freeze"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "grade_a_dried",
                              type,
                              "Freeze"
                            )
                          )}{" "}
                          lbs)
                        </td>
                        <td>
                          {calculateInventoryByDriedType(
                            "grade_b_dried",
                            type,
                            "Freeze"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "grade_b_dried",
                              type,
                              "Freeze"
                            )
                          )}{" "}
                          lbs)
                        </td>
                        <td>
                          {calculateInventoryByDriedType(
                            "grade_c_dried",
                            type,
                            "Freeze"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "grade_c_dried",
                              type,
                              "Freeze"
                            )
                          )}{" "}
                          lbs)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="substrate-inventory">
                  <h6>Substrate (Myceliated Grain Inventory)</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Total Substrate (Myceliated Grain)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {calculateInventoryByDriedType(
                            "dried_substrate_grams",
                            type,
                            "Irrelevant because its substrate"
                          )}
                          g{" "}
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "dried_substrate_grams",
                              type,
                              "Irrelevant because its substrate"
                            )
                          )}
                          lbs
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
          {addingRemovingProduct && (
            <div className="add-remove-product-inventory">
              <AddRemoveProduct
                currentUser={currentUser}
                cultures={cultures}
                substrates={substrates}
                mushrooms={mushrooms}
                inventoryTransactions={inventoryTransactions}
                productInventoryTransactions={productInventoryTransactions}
                setAddingRemovingProduct={setAddingRemovingProduct}
                setShowSuccessModal={setShowSuccessModal}
                calculateInventoryByDriedType={calculateInventoryByDriedType}
              ></AddRemoveProduct>
            </div>
          )}
        </div>
        {/* Add new product inventory box */}
        <div className="product-inventory">
          <h4>Total Product Inventory</h4>
          <table>
            <thead>
              <tr>
                <th>Product Type</th>
                {mushroomTypes.map((type) => (
                  <>
                    <th>{`${type} (Dehydrated)`}</th>
                    <th>{`${type} (Freeze Dried)`}</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {productTypes.map((type) => (
                <tr key={type.id}>
                  <td>{type.name}</td>
                  {mushroomTypes.map((mushroomType) => (
                    <>
                      <td>
                        {calcProductInventoryByMushroomTypeAndDriedType(
                          type,
                          mushroomType,
                          "Dehydrated"
                        )}
                      </td>
                      <td>
                        {calcProductInventoryByMushroomTypeAndDriedType(
                          type,
                          mushroomType,
                          "Freeze Dried"
                        )}
                      </td>
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="inventory-button-container">
          {/* Button to open the modal */}
          <button
            onClick={(e) => {
              setShowInventoryTransactionModal(true);
            }}
            className="move-product-button"
          >
            Adjust Inventory{" "}
          </button>

          <button
            className="move-product-button"
            onClick={handleClickAddSubtractProduct}
          >
            Add/Subtract Product from Inventory
          </button>
        </div>
        {showInventoryTransactionModal && (
          <InventoryTransactionModal
            closeModal={() => setShowInventoryTransactionModal(false)}
            calculateInventory={calculateInventory}
            calculateInventoryByDriedType={calculateInventoryByDriedType}
            gramsToPounds={gramsToPounds}
            currentUser={currentUser}
          />
        )}
        <br />
        <div className="transaction-selection">
          <label htmlFor="transaction-type">
            Select which transaction list you'd like to see displayed:
          </label>
          <select
            id="transaction-type"
            value={selectedTransaction}
            onChange={(e) => setSelectedTransaction(e.target.value)}
          >
            <option value="mushroom_transactions">
              Mushroom Inventory Transactions
            </option>
            <option value="product_transactions">
              Product Inventory Transactions
            </option>
          </select>
        </div>

        <div className="transaction-sections">
          {selectedTransaction === "mushroom_transactions" ? (
            <>
              <div className="add-transactions">
                <h4>Add Mushroom Inventory Transactions</h4>
                <ul>{transactionList("add")}</ul>
              </div>
              <div className="subtract-transactions">
                <h4>Subtract Mushroom Inventory Transactions</h4>
                <ul>{transactionList("subtract")}</ul>
              </div>
            </>
          ) : (
            <>
              <div className="add-transactions">
                <h4>Add Product Inventory Transactions</h4>
                <table className="transaction-table">
                  <thead>
                    <tr>
                      <th>Product Type</th>
                      <th>Date Added</th>
                      <th>Mushroom Type</th>
                      <th>Quantity</th>
                      <th>Culture Type</th>
                      <th>Substrate Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productInventoryTransactions
                      .filter((t) => t.added_or_removed === "add")
                      .map((transaction, index) => (
                        <tr key={index}>
                          <td>{transaction.expand.product_type.name}</td>
                          <td>
                            {formatDate(transaction.inventory_added_date)}
                          </td>
                          <td>{transaction.mushroom_type}</td>
                          <td>{transaction.quantity}</td>
                          <td>{transaction.culture_type}</td>
                          <td>{transaction.substrate_type}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className="subtract-transactions">
                <h4>Subtract Product Inventory Transactions</h4>
                <table className="transaction-table">
                  <thead>
                    <tr>
                      <th>Product Type</th>
                      <th>Date Removed</th>
                      <th>Mushroom Type</th>
                      <th>Removal Type</th>
                      <th>Quantity</th>
                      <th>Culture Type</th>
                      <th>Substrate Type</th>
                      <th>Customer Details</th>
                      <th>Dollars Received</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productInventoryTransactions
                      .filter((t) => t.added_or_removed === "remove")
                      .map((transaction, index) => (
                        <tr key={index}>
                          <td>{transaction.expand.product_type.name}</td>
                          <td>
                            {formatDate(transaction.inventory_removed_date)}
                          </td>
                          <td>{transaction.mushroom_type}</td>
                          <td>{transaction.type_of_removal}</td>
                          <td>{transaction.quantity}</td>
                          <td>{transaction.culture_type}</td>
                          <td>{transaction.substrate_type}</td>
                          <td>
                            {transaction.customer_name &&
                              `Name: ${transaction.customer_name} \n`}
                            {transaction.customer_email &&
                              `Email: ${transaction.customer_email} \n`}
                            {transaction.customer_phone &&
                              `Phone: ${transaction.customer_phone}`}
                          </td>
                          <td>${transaction.money_received}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
