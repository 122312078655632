import { useState, useContext } from "react";
import { ContainerTypeContext } from "./LoggedIn";
import "css/mushroom-details.css";

export default function ContainerTypeList() {
  const {
    containerTypes,
    handleContainerTypeDelete,
    handleContainerTypeAdd,
  } = useContext(ContainerTypeContext);

  const [newContainerTypeName, setNewContainerTypeName] = useState("");
  const [showContainerTypeInput, setShowContainerTypeInput] = useState(false);
  const [newBagTypeName, setNewBagTypeName] = useState("");
  const [showBagTypeInput, setShowBagTypeInput] = useState(false);

  function handleAddContainerClick() {
    handleContainerTypeAdd(newContainerTypeName);
    setNewContainerTypeName("");
    setShowContainerTypeInput(false);
  }

  // Additional function to handle deletion
  function handleDeleteClick(id, type) {
    if (type === "Bag") {
      alert("The Bag type cannot be deleted");
      return;
    }

    if (window.confirm("Are you sure you want to delete this item?")) {
      handleContainerTypeDelete(id);
    }
  }

  // Separate function to handle bag add
  function handleAddBagClick() {
    handleContainerTypeAdd("Bag", newBagTypeName);
    setNewBagTypeName("");
    setShowBagTypeInput(false);
  }

  return (
    <div className="bag-type-list">
      <h2>Container Types</h2>
      <ul className="bag-type-management__bag-type-list">
        <li>Bag</li>
        {containerTypes.map((containerType) =>
          containerType.container_type === "Bag" ? null : (
            <li key={containerType.id}>
              {containerType.container_type}
              <button
                className="x-remove-btn"
                onClick={() =>
                  handleDeleteClick(
                    containerType.id,
                    containerType.container_type
                  )
                }
              >
                &times;
              </button>
            </li>
          )
        )}
      </ul>
      {showContainerTypeInput ? (
        <div className="mushroom-details-list__add-item">
          <button
            className="close-btn"
            onClick={() => setShowContainerTypeInput(false)}
          >
            &times;
          </button>
          <input
            type="text"
            placeholder="Enter container type"
            value={newContainerTypeName}
            onChange={(e) => setNewContainerTypeName(e.target.value)}
          />
          <button className="add-item-btn" onClick={handleAddContainerClick}>
            Add Container Type to List
          </button>
        </div>
      ) : (
        <button
          className="mushroom-details-list-add-btn"
          onClick={() => setShowContainerTypeInput(true)}
        >
          Add Container Type
        </button>
      )}
      <h2>Bag Types</h2>
      <ul className="bag-type-management__bag-type-list">
        {containerTypes
          .filter((containerType) => containerType.container_type === "Bag")
          .map((containerType) => (
            <li key={containerType.id}>
              {containerType.bag_type}
              <button
                className="x-remove-btn"
                onClick={() => handleDeleteClick(containerType.id)}
              >
                &times;
              </button>
            </li>
          ))}
      </ul>
      {showBagTypeInput ? (
        <div className="mushroom-details-list__add-item">
          <button
            className="close-btn"
            onClick={() => setShowBagTypeInput(false)}
          >
            &times;
          </button>
          <input
            type="text"
            placeholder="Enter bag type"
            value={newBagTypeName}
            onChange={(e) => setNewBagTypeName(e.target.value)}
          />
          <button className="add-item-btn" onClick={handleAddBagClick}>
            Add Bag Type to List
          </button>
        </div>
      ) : (
        <button
          className="mushroom-details-list-add-btn"
          onClick={() => setShowBagTypeInput(true)}
        >
          Add Bag Type
        </button>
      )}
    </div>
  );
}
