import { useContext, useState } from "react";
import Substrate from "./Substrate";
import { SubstrateContext } from "./LoggedIn";

export default function SubstrateList({ substrates }) {
  const { handleSubstrateAdd } = useContext(SubstrateContext);
  // State variable to keep track of selected mushroom type for filtering
  const [selectedMushroomType, setSelectedMushroomType] = useState("All");

  // Get the unique mushroom types from substrates
  const mushroomTypes = Array.from(
    new Set(substrates.map((substrate) => substrate.mushroom_type))
  );

  // Handle dropdown change
  function handleDropdownChange(e) {
    setSelectedMushroomType(e.target.value);
  }

  return (
    <div className="substrate-list">
      <div className="substrate-list__view-substrate-btn-container substrate-list-container">
        <button
          className="substrate-list__add-substrate-btn-container"
          onClick={handleSubstrateAdd}
        >
          Add Substrate
        </button>
      </div>
      <label htmlFor="mushroomTypeFilter">Filter by Mushroom Type:</label>
      <select
        name="mushroomTypeFilter"
        id="mushroomTypeFilter"
        value={selectedMushroomType}
        onChange={handleDropdownChange}
      >
        <option value="All">All</option>
        {mushroomTypes.map((mushroomType, index) => (
          <option key={index} value={mushroomType}>
            {mushroomType}
          </option>
        ))}
      </select>
      <div>
        {mushroomTypes.map((mushroomType) => {
          if (
            selectedMushroomType !== "All" &&
            mushroomType !== selectedMushroomType
          ) {
            return null;
          }
          const filteredSubstrates = substrates.filter(
            (substrate) => substrate.mushroom_type === mushroomType
          );

          return (
            <div key={mushroomType}>
              <h2 className="mushroom-type-header">{mushroomType}</h2>
              {filteredSubstrates.map((substrate) => (
                <Substrate
                  key={substrate.id}
                  {...substrate}
                  substrates={substrates}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
