import React from "react";
import "css/report-output.css";

export default function ReportSubstrateOutput(props) {
  const { substrates, formatNutrientConcentration } = props;

  return (
    <div className="report-table-container">
      <h3>Substrates</h3>
      <table>
        <thead>
          <tr>
            <th>Substrate Name</th>
            <th>Ingredient List</th>
            <th>mL Water Required</th>
            <th>Substrate Note</th>
            {/* Add more columns as needed */}
          </tr>
        </thead>
        <tbody>
          {substrates.map((substrate) => (
            <tr key={substrate.id}>
              <td>
                {substrate.mushroom_type}
                <br />
                {substrate.recipe_name}
              </td>
              <td>
                {substrate.ingredient_list_and_grams
                  ? formatNutrientConcentration(
                      substrate.ingredient_list_and_grams
                    )
                  : ""}
              </td>
              <td>{substrate.ml_water_required}mL</td>
              <td>{substrate.substrate_note}</td>
              {/* Add more data as needed */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
