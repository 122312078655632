import { useState, useEffect, useContext } from "react";
import "css/dried-modal.css";
import { GrowBagsContext } from "./LoggedIn";

const DriedModal = ({ closeModal, growBag, resetSelectedGrowBagsId }) => {
  const [dryingType, setDryingType] = useState("Freeze");
  const [driedDate, setDriedDate] = useState(getCurrentDateString);
  const [gradeA, setGradeA] = useState("");
  const [gradeB, setGradeB] = useState("");
  const [gradeC, setGradeC] = useState("");
  const [driedSubstrateWeight, setDriedSubstrateWeight] = useState("");
  const [totalDriedWeight, setTotalDriedWeight] = useState("");
  const [driedWeightManuallyUpdated, setDriedWeightManuallyUpdated] =
    useState("false");
  const [showGrades, setShowGrades] = useState(growBag.toggle_yield_grades);
  const [weighSubstrate, setWeighSubstrate] = useState(
    growBag.toggle_weigh_substrate
  );
  const [noDriedInputError, setNoDriedInputError] = useState("");
  const [gradeAError, setGradeAError] = useState("");
  const [gradeBError, setGradeBError] = useState("");
  const [gradeCError, setGradeCError] = useState("");
  const [substrateWeightError, setSubstrateWeightError] = useState("");
  const [driedWeightError, setDriedWeightError] = useState("");
  const [postDriedNote, setPostDriedNote] = useState("");

  const { handleGrowBagsChange } = useContext(GrowBagsContext);

  useEffect(() => {
    if (showGrades) {
      setTotalDriedWeight(gradeA + gradeB + gradeC);
    }
  }, [gradeA, gradeB, gradeC, showGrades]);

  const calculateRetentionPercentage = (driedWeight, freshWeight) => {
    const percentage = (
      (parseFloat(driedWeight) / parseFloat(freshWeight)) *
      100
    ).toFixed(2);

    return isNaN(percentage) ? 0 : percentage;
  };

  const handleInputChange = (setter) => (event) => {
    setter(parseFloat(event.target.value) || 0);
  };

  // Sets today's date as default
  function getCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function handleWeighSubstrateToggle() {
    const newValue = !weighSubstrate;
    setWeighSubstrate(newValue);
  }

  function validateDriedModal() {
    let isValid = true;
    if (gradeA > growBag.grade_a_fresh_grams) {
      isValid = false;
      setGradeAError("Grade A Dried Grams must be lower than fresh grams");
    }
    if (gradeB > growBag.grade_b_fresh_grams) {
      isValid = false;
      setGradeBError("Grade B Dried Grams must be lower than fresh grams");
    }
    if (gradeC > growBag.grade_c_fresh_grams) {
      isValid = false;
      setGradeCError("Grade C Dried Grams must be lower than fresh grams");
    }
    if (driedSubstrateWeight > growBag.substrate_weight_grams) {
      isValid = false;
      setSubstrateWeightError(
        "Dried substrate weight must be lower than fresh substrate weight"
      );
    }

    if (totalDriedWeight > growBag.fresh_grams_yielded) {
      isValid = false;
      setDriedWeightError("Dried weight must be lower than fresh weight");
    }
    if (totalDriedWeight === 0) {
      isValid = false;
      setNoDriedInputError("You must enter a number");
    }
    return isValid;
  }

  async function handleDriedSubmit(growBag) {
    if (!validateDriedModal()) {
      return;
    }

    if (!showGrades) {
      setGradeA(0);
      setGradeB(0);
      setGradeC(0);
    }

    growBag.current_status = "Dried";
    growBag.grade_a_dried_grams = gradeA;
    growBag.grade_b_dried_grams = gradeB;
    growBag.grade_c_dried_grams = gradeC;
    growBag.dried_substrate_weight_grams = driedSubstrateWeight;
    growBag.dried_date = driedDate;
    growBag.dried_grams_yielded = totalDriedWeight;
    growBag.dried_type = dryingType;
    growBag.post_dried_note = postDriedNote;

    closeModal();
    resetSelectedGrowBagsId();

    await handleGrowBagsChange(growBag.id, growBag);
  }

  return (
    <div className="dried-modal" onClick={(e) => e.stopPropagation()}>
      <div className="dried-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="dried-modal-header">
          <h4>
            Drying {growBag.fresh_grams_yielded} grams of fresh{" "}
            {growBag.mushroom_type} <br />
            Batch Id #: {growBag.batch_id_number}
          </h4>
        </div>
        <button
          className="dried-modal-close-btn"
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          &times;
        </button>
        <label>
          Drying Type:
          <select
            value={dryingType}
            onChange={(e) => setDryingType(e.target.value)}
          >
            <option value="Freeze">Freeze</option>
            <option value="Dehydrated">Dehydrated</option>
          </select>
        </label>
        <br />
        <label>
          Dried Date:
          <input
            type="date"
            value={driedDate}
            onChange={(e) => setDriedDate(e.target.value)}
          />
        </label>

        <div className="toggle-container">
          <label
            htmlFor="toggleWeighSubstrate"
            className="growbags-edit__label"
          >
            Input Substrate Weight?
          </label>
          <label className="switch">
            <input
              type="checkbox"
              id="toggleWeighSubstrate"
              checked={weighSubstrate}
              onChange={handleWeighSubstrateToggle}
            />
            <span className="slider round"></span>
          </label>
        </div>

        {weighSubstrate && (
          <div className="substrate-weight-container">
            <p className="fresh-yield-display-tag">
              {growBag.substrate_weight_grams} fresh substrate grams yielded
            </p>
            <label htmlFor="driedSubstrateWeight">
              Dried Substrate weight (grams):
            </label>
            {substrateWeightError && (
              <p className="error-message">{substrateWeightError}</p>
            )}
            <input
              type="number"
              id="driedSubstrateWeight"
              value={driedSubstrateWeight}
              placeholder="0"
              onChange={(e) => setDriedSubstrateWeight(Number(e.target.value))}
            />
          </div>
        )}

        <div className="toggle-container">
          <label htmlFor="toggleShowGrades" className="growbags-edit__label">
            Input Fruiting Body Weight by Grade?
          </label>
          <label className="switch">
            <input
              type="checkbox"
              id="toggleShowGrades"
              checked={showGrades}
              onChange={() => {
                const newValue = !showGrades;
                setShowGrades(newValue);
                setDriedWeightManuallyUpdated(!driedWeightManuallyUpdated);
              }}
            />
            <span className="slider round"></span>
          </label>
        </div>
        {showGrades && (
          <div className="dried-modal-grade-container">
            <div>
              <span className="percentage-column-title">
                Retention Percentage
              </span>
              <p className="fresh-yield-display-tag">
                {growBag.grade_a_fresh_grams} fresh grade A grams yielded
              </p>
              <div className="dried-modal-grade-input-container">
                <label>
                  Grade A Dried Weight:
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0"
                    value={gradeA}
                    onChange={handleInputChange(setGradeA)}
                  />
                </label>
                {gradeAError && <p className="error-message">{gradeAError}</p>}
                <span>
                  {calculateRetentionPercentage(
                    gradeA,
                    growBag.grade_a_fresh_grams
                  )}
                  %
                </span>
              </div>

              <p className="fresh-yield-display-tag">
                {growBag.grade_b_fresh_grams} fresh grade B grams yielded
              </p>
              <div className="dried-modal-grade-input-container">
                <label>
                  Grade B Dried Weight:
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0"
                    value={gradeB}
                    onChange={handleInputChange(setGradeB)}
                  />
                </label>
                {gradeBError && <p className="error-message">{gradeBError}</p>}

                <span>
                  {calculateRetentionPercentage(
                    gradeB,
                    growBag.grade_b_fresh_grams
                  )}
                  %
                </span>
              </div>

              <p className="fresh-yield-display-tag">
                {growBag.grade_c_fresh_grams} fresh grade C grams yielded
              </p>
              <div className="dried-modal-grade-input-container">
                <label>
                  Grade C Dried Weight:
                  <input
                    type="number"
                    step="0.01"
                    placeholder="0"
                    value={gradeC}
                    onChange={handleInputChange(setGradeC)}
                  />
                </label>
                {gradeCError && <p className="error-message">{gradeCError}</p>}

                <span>
                  {calculateRetentionPercentage(
                    gradeC,
                    growBag.grade_c_fresh_grams
                  )}
                  %
                </span>
              </div>
              <p
                className="dried-clickable-addition-button"
                onClick={(e) => setTotalDriedWeight(gradeA + gradeB + gradeC)}
              >
                Click to add all weights together
              </p>
            </div>
          </div>
        )}

        <p className="fresh-yield-display-tag">
          {growBag.fresh_grams_yielded} total fresh grams yielded
        </p>
        <label>
          Total Dried Weight:
          <input
            type="number"
            step="0.01"
            placeholder="0"
            value={totalDriedWeight}
            onChange={handleInputChange(setTotalDriedWeight)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </label>
        {driedWeightError && (
          <p className="error-message">{driedWeightError}</p>
        )}
        {noDriedInputError && (
          <p className="error-message">{noDriedInputError}</p>
        )}
        <div>
          Total Retention Percentage:{" "}
          {totalDriedWeight &&
            calculateRetentionPercentage(
              totalDriedWeight,
              growBag.fresh_grams_yielded
            ) + "%"}
        </div>
        {weighSubstrate && (
          <div>
            Total Substrate Retention Percentage:{" "}
            {calculateRetentionPercentage(
              driedSubstrateWeight,
              growBag.substrate_weight_grams
            ) + "%"}
          </div>
        )}

        <div className="drying-note-total-area">
          <label htmlFor="postDriedNote">Enter Post-Drying Note:</label>
          <br />
          <textarea
            className="drying-note-modal-textarea"
            name="postDriedNote"
            id="postDriedNote"
            value={postDriedNote}
            onChange={(e) => setPostDriedNote(e.target.value)}
          />
        </div>

        <button onClick={() => handleDriedSubmit(growBag)}>Submit</button>
      </div>
    </div>
  );
};

export default DriedModal;
