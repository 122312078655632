import React, { useState, useEffect } from "react";
import pb from "lib/pocketbase";
import Auth from "components/Auth";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const onLogout = () => {
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const unsubscribe = pb.authStore.onChange((authState) => {
      setIsLoggedIn(authState.isValid);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div>
        <Auth isLoggedIn={isLoggedIn} onLogout={onLogout} />
      </div>
    </>
  );
}

export default App;
