import { useState, useContext } from "react";
import { SubstrateContext } from "./LoggedIn";
import "css/substrates.css";

export default function SubstrateEdit({
  substrate,
  addingNewSubstrate,
  mushrooms,
  selectedSubstrateId,
  currentUser,
}) {
  // State for selected mushroom type
  const [selectedMushroomType, setSelectedMushroomType] = useState(
    substrate?.mushroom_type || ""
  );

  // This is state for updated substrate information
  // The ingredient_list_and_grams needs to be an array
  const [updatedSubstrate, setUpdatedSubstrate] = useState({
    ...(substrate || {
      mushroom_type: "",
      recipe_name: "",
      ml_water_required: "",
      ingredient_list_and_grams: [],
      substrate_note: "",
      created_by: currentUser,
    }),
    ingredient_list_and_grams: (substrate?.ingredient_list_and_grams || []).map(
      (ingredientObj) => ({ ...ingredientObj })
    ),
  });

  const {
    handleSubstrateChange,
    handleSubstrateSelect,
    handleSubstrateDelete,
  } = useContext(SubstrateContext);

  const [substrateNameError, setSubstrateNameError] = useState("");
  const [mushroomTypeError, setMushroomTypeError] = useState("");
  const [waterRequiredError, setWaterRequiredError] = useState("");
  const [noIngredientsError, setNoIngredientsError] = useState("");

  function validateForm() {
    let isValid = true;

    if (
      updatedSubstrate.recipe_name.trim() === "" ||
      updatedSubstrate.recipe_name === "ENTER SUBSTRATE RECIPE NAME"
    ) {
      setSubstrateNameError("You must enter a substrate recipe name");
      isValid = false;
    } else {
      setSubstrateNameError("");
    }

    if (
      updatedSubstrate.mushroom_type.trim() === "" ||
      updatedSubstrate.mushroom_type === "ENTER MUSHROOM TYPE" ||
      updatedSubstrate.mushroom_type === "Select a Mushroom Type"
    ) {
      setMushroomTypeError("Please select a mushroom type.");
      isValid = false;
    } else {
      setMushroomTypeError("");
    }

    if (!updatedSubstrate.ml_water_required) {
      setWaterRequiredError("Water required cannot be blank.");
      isValid = false;
    } else {
      setWaterRequiredError("");
    }

    if (updatedSubstrate.ingredient_list_and_grams.length === 0) {
      setNoIngredientsError(
        "You must have at least one ingredient in the substrate recipe."
      );
      isValid = false;
    } else {
      setNoIngredientsError("");

      updatedSubstrate.ingredient_list_and_grams.forEach((ingredient) => {
        if (!ingredient.ingredient.trim() || !ingredient.grams) {
          isValid = false;
        }
      });

      if (!isValid) {
        setNoIngredientsError(
          "No ingredient or gram values can be listed as blank."
        );
      }
    }

    return isValid;
  }

  // Adds new ingredient to "ingredient_list_and_grams" array
  function addIngredient() {
    setUpdatedSubstrate({
      ...updatedSubstrate,
      // Adds empty strings below to the end of the "updatedSubstrate" state
      ingredient_list_and_grams: [
        ...updatedSubstrate.ingredient_list_and_grams,
        { ingredient: "", grams: "" },
      ],
    });
  }

  // Updates an existing ingredient in the "ingredient_list_and_grams" array
  function updateIngredient(index, updatedIngredient) {
    const updatedIngredients = updatedSubstrate.ingredient_list_and_grams.map(
      // Checks if current index (idx) matches array of provided "index"
      // If a match occurs, this is updated with the updatedIngredient
      (ingredient, idx) => (idx === index ? updatedIngredient : ingredient)
    );
    setUpdatedSubstrate({
      ...updatedSubstrate,
      ingredient_list_and_grams: updatedIngredients,
    });
  }

  function removeIngredient(e, index) {
    const updatedIngredients =
      updatedSubstrate.ingredient_list_and_grams.filter(
        (_, idx) => idx !== index
      );
    setUpdatedSubstrate({
      ...updatedSubstrate,
      ingredient_list_and_grams: updatedIngredients,
    });
  }

  function handleChange(changes) {
    setUpdatedSubstrate({ ...updatedSubstrate, ...changes });
  }

  // Will be called when user clicks the "update" button
  // Updated to ensure time zone differences do not affect the display date
  function handleSubmit(e) {
    e.preventDefault();

    if (validateForm()) {
      handleSubstrateChange(substrate.id, updatedSubstrate);
      handleSubstrateSelect(updatedSubstrate.id);
    }
  }

  async function handleAdd(e) {
    e.preventDefault();

    if (validateForm()) {
      await handleSubstrateChange(null, updatedSubstrate);
      handleSubstrateSelect(undefined);
    }
  }

  async function handleCancel() {
    if (addingNewSubstrate) {
      handleSubstrateSelect(undefined);
    } else {
      handleSubstrateSelect(updatedSubstrate.id);
    }
  }

  // Will be called when use clicks the "add" button

  return (
    <div key={selectedSubstrateId || "add"} className="substrate-edit">
      <div className="substrate-edit__remove-substrate-container">
        <button className="substrate-close-btn" onClick={handleCancel}>
          &times;
        </button>
      </div>
      <form onSubmit={addingNewSubstrate ? handleAdd : handleSubmit}>
        <div className="substrate-edit__details--grid">
          <label htmlFor="recipe_name" className="substrate-edit__label">
            Substrate Recipe Name
          </label>
          <input
            type="text"
            id="recipe_name"
            name="recipe_name"
            placeholder="ENTER SUBSTRATE RECIPE NAME"
            value={updatedSubstrate.recipe_name}
            onChange={(e) => handleChange({ recipe_name: e.target.value })}
          />
          {substrateNameError && (
            <p className="error-message">{substrateNameError}</p>
          )}

          <label htmlFor="mushroom_type" className="substrate-edit__label">
            Mushroom Type
          </label>
          <select
            id="mushroom_type"
            name="mushroom_type"
            value={selectedMushroomType}
            onChange={(e) => {
              handleChange({ mushroom_type: e.target.value });
              setSelectedMushroomType(e.target.value);
            }}
          >
            <option value={selectedMushroomType.mushroom_name}>
              Select Mushroom Type
            </option>
            {mushrooms.map((mushroom) => (
              <option
                key={mushroom.mushroom_name}
                value={mushroom.mushroom_name}
              >
                {mushroom.mushroom_name}
              </option>
            ))}
          </select>
          {mushroomTypeError && (
            <p className="error-message">{mushroomTypeError}</p>
          )}
          <br />
          <label htmlFor="ml_water_required" className="substrate-edit__label">
            Water Required (ml)
          </label>
          <input
            type="number"
            id="ml_water_required"
            name="ml_water_required"
            value={updatedSubstrate.ml_water_required}
            onChange={(e) =>
              handleChange({ ml_water_required: e.target.value })
            }
          />
          {waterRequiredError && (
            <p className="error-message">{waterRequiredError}</p>
          )}

          <div className="substrate-edit__ingredients">
            <button
              type="button"
              className="substrate-edit__add-ingredient-btn"
              onClick={addIngredient}
            >
              Add Ingredient
            </button>
            {updatedSubstrate.ingredient_list_and_grams.map(
              (ingredientObj, index) => (
                <div key={index} className="substrate-edit__ingredient">
                  <label
                    htmlFor={`ingredient-${index}`}
                    className="substrate-edit__label ingredient-label"
                  >
                    <span>Ingredient </span>
                    <span>&nbsp;</span>
                    <span>{index + 1}:</span>
                  </label>
                  <input
                    type="text"
                    id={`ingredient-${index}`}
                    name={`ingredient-${index}`}
                    value={ingredientObj.ingredient}
                    className="substrate-edit__ingredient-input-box"
                    onChange={(e) =>
                      updateIngredient(index, {
                        ...ingredientObj,
                        ingredient: e.target.value,
                      })
                    }
                  />
                  <label
                    htmlFor={`grams-${index}`}
                    className="substrate-edit__label grams-label"
                  >
                    Grams:
                  </label>
                  <input
                    type="number"
                    id={`grams-${index}`}
                    name={`grams-${index}`}
                    value={ingredientObj.grams}
                    onChange={(e) =>
                      updateIngredient(index, {
                        ...ingredientObj,
                        grams: e.target.value,
                      })
                    }
                  />
                  <button
                    type="button"
                    className="remove-ingredient-btn"
                    onClick={(e) => removeIngredient(e, index)}
                  ></button>
                </div>
              )
            )}
            {noIngredientsError && (
              <p className="error-message">{noIngredientsError}</p>
            )}
          </div>
          <label htmlFor="substrate_note" className="substrate-edit__label">
            Note
          </label>
          <br />
          <textarea
            id="substrate_note"
            name="substrate_note"
            className="substrate-edit__textarea"
            value={updatedSubstrate.substrate_note}
            onChange={(e) => handleChange({ substrate_note: e.target.value })}
          />
        </div>
        <br />
        <button type="submit">{addingNewSubstrate ? "Add" : "Update"}</button>
        {!addingNewSubstrate && (
          <button
            type="delete"
            onClick={() => handleSubstrateDelete(substrate.id)}
          >
            Delete
          </button>
        )}
      </form>
    </div>
  );
}
