import React, { useState, useEffect } from "react";

import "css/phoneInput.css";

function PhoneInput({ onPhoneChange }) {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");

  useEffect(() => {
    if (first.length === 3 && second.length === 3 && third.length === 4) {
      onPhoneChange(`${first}-${second}-${third}`);
    }
  }, [first, second, third, onPhoneChange]);

  return (
    <div className="phone-row-header">
      <label>
        <input
          type="tel"
          pattern="[0-9]{3}"
          maxLength="3"
          value={first}
          onChange={(e) => setFirst(e.target.value)}
        />
        -
        <input
          type="tel"
          pattern="[0-9]{3}"
          maxLength="3"
          value={second}
          onChange={(e) => setSecond(e.target.value)}
        />
        -
        <input
          type="tel"
          pattern="[0-9]{4}"
          maxLength="4"
          value={third}
          onChange={(e) => setThird(e.target.value)}
        />
      </label>
    </div>
  );
}

export default PhoneInput;
