import React, { createContext, useState } from "react";
import useLogin from "components/hooks/useLogin";
import LoggedIn from "components/LoggedIn";
import useLogout from "components/hooks/useLogout";
import SignUp from "./SignUp";
import pb from "lib/pocketbase";
import { useForm } from "react-hook-form";
import "css/auth.css";

export const UserContext = createContext();

export default function Auth() {
  const { register, handleSubmit, reset } = useForm();
  const isLoggedIn = pb.authStore.isValid;
  const logout = useLogout();
  const { mutate: login, isLoading, isError } = useLogin();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [successEmail, setSuccessEmail] = useState("");

  // eslint-disable-next-line no-unused-vars
  function handleSignUp() {
    setIsSigningUp(true);
  }

  function handleForgotPassword() {
    setShowForgotPasswordModal(true);
  }

  function handleCloseModal() {
    setShowForgotPasswordModal(false);
    setForgotPasswordError("");
    setForgotPasswordSuccess(false);
  }

  async function handleForgotPasswordSubmit() {
    try {
      const response = await pb
        .collection("users")
        .requestPasswordReset(forgotPasswordEmail);
      if (response) {
        setForgotPasswordSuccess(true);
        setForgotPasswordError("");
        setSuccessEmail(forgotPasswordEmail);
        setForgotPasswordEmail("");
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      if (error?.data?.email?.message) {
        setForgotPasswordError(error.data.email.message);
      } else {
        setForgotPasswordError(
          "Error during password reset. We must have your email address on file to perform a password reset"
        );
      }
    }
  }

  async function onSubmit(data) {
    login({
      email: data.email,
      password: data.password,
    });

    reset();
  }

  const userContextValue = {
    pb,
    setIsSigningUp,
    logout,
    isVerified,
    setIsVerified,
  };

  return (
    <>
      {!isLoggedIn && !isSigningUp && (
        <div className="login-container">
          <div className="login-box">
            <h1 className="login-header">Welcome to the</h1>
            <div className="logo-container">
              <img
                src="/full_color_logo.png"
                alt="Company Logo"
                className="merchants-logo"
              />
            </div>
            <h1 className="login-header">Mushroom Database</h1>

            {isLoading && <p>Loading...</p>}
            {isError && (
              <p className="error-message">Invalid email or password</p>
            )}
            <h1 className="login-title">Please Log In</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="text" placeholder="email" {...register("email")} />
              <input
                type="password"
                placeholder="password"
                {...register("password")}
              />
              <div className="login-button-container">
                <button
                  className="homepage-login"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading" : "Login"}
                </button>
              </div>
              <p className="clickable-signup" onClick={handleForgotPassword}>
                Forgot Password?
              </p>
            </form>
            {/* <h5 className="signup-info clickable-signup" onClick={handleSignUp}>
              Click Here to Sign Up
            </h5> */}
            <h5>Currently in Closed Beta Testing -- SignUps occuring soon!</h5>
            <div className="partnership-container">
              <h6 className="partnership-text">Mush Love</h6>
              <br />
            </div>
            <img
              src="/MMLogo.png"
              alt="Mushroom Merchants Logo"
              className="partnership-logo"
            />
          </div>
        </div>
      )}

      {isSigningUp && !isLoggedIn && (
        <div>
          <UserContext.Provider value={userContextValue}>
            <SignUp />
          </UserContext.Provider>
        </div>
      )}
      {isLoggedIn && !pb.authStore.model?.verified && !isVerified && (
        <div>
          <UserContext.Provider value={userContextValue}>
            <SignUp />
            <button className="back-to-homepage" onClick={logout}>
              <span>Back to Homepage</span>
            </button>
          </UserContext.Provider>
        </div>
      )}

      {isLoggedIn && pb.authStore.model?.verified && (
        <div>
          <div className="logout-container">
            <button className="logout-button" onClick={logout}>
              Log Out
            </button>
          </div>
          <div className="logged-in-wrapper">
            <LoggedIn />
          </div>
        </div>
      )}
      {showForgotPasswordModal && (
        <div className="forgot-password-modal">
          <div className="forgot-password-modal-content">
            <button className="close-modal" onClick={handleCloseModal}>
              &times;
            </button>
            {!forgotPasswordSuccess ? (
              <>
                <h3 className="centered-header">Forgot Password</h3>
                <p className="centered-text">Please enter your email address</p>
                <input
                  type="email"
                  placeholder="Email"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                />
                <button
                  className="login-button"
                  onClick={handleForgotPasswordSubmit}
                >
                  Reset Password
                </button>
                {forgotPasswordError && (
                  <p className="error-message">{forgotPasswordError}</p>
                )}
              </>
            ) : (
              <>
                <h3>Password Reset Email Sent</h3>
                <p>
                  We've successfully sent a password reset link to{" "}
                  {successEmail}. Please check your email to reset your
                  password.
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
