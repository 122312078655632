import React from "react";
import "css/report-output.css";

export default function ReportCultureOutput(props) {
  const { filteredCultures, formatDate, formatNutrientConcentration } = props;

  return (
    <div className="report-table-container">
      <h3>Cultures</h3>
      <table>
        <thead>
          <tr>
            <th>Culture Name</th>
            <th>Creation Date</th>
            <th>Culture Origin</th>
            <th>Nutrient Concentration</th>
            <th>pH Level</th>
            <th>Days Incubating</th>
            <th>Incubation Temp (C)</th>
            <th>Light Conditions</th>
            <th>Spent Date</th>
            <th>Culture Note</th>
            {/* Add more columns as needed */}
          </tr>
        </thead>
        <tbody>
          {filteredCultures.map((culture) => (
            <tr key={culture.id}>
              <td>
                {culture.mushroom_type}
                <br />
                {culture.culture_name}
              </td>
              <td>{formatDate(culture.date_started)}</td>
              <td>{culture.master_culture_name}</td>
              <td>
                {culture.nutrient_concentration &&
                culture.nutrient_concentration.length > 0
                  ? formatNutrientConcentration(culture.nutrient_concentration)
                  : ""}
              </td>
              <td>{Number(culture.ph_level) === 0 ? "" : culture.ph_level}</td>
              <td>{culture.incubation_time_before_first_use}</td>
              <td>{culture.incubation_temp}</td>
              <td>{culture.light_conditions}</td>
              <td>
                {culture.date_culture_spent
                  ? formatDate(culture.date_culture_spent)
                  : ""}
              </td>
              <td>{culture.culture_note}</td>
              {/* Add more data as needed */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
