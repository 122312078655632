import React, { useState, useEffect, useCallback, createContext } from "react";
import CultureList from "./CultureList";
import CultureEdit from "./CultureEdit";
import SubstrateList from "./SubstrateList";
import SubstrateEdit from "./SubstrateEdit";
import SubstrateView from "./SubstrateView";
import GrowBagsList from "./GrowBagsList";
import GrowBagsEdit from "./GrowBagsEdit";
import MushroomList from "./MushroomList";
import ViewInventory from "./ViewInventory";
import FullyHarvestedBatches from "./FullyHarvestedBatches";
import Analytics from "./Analytics";
import ReportGeneration from "./ReportGeneration";
import CompletedBatches from "./CompletedBatches";
import HarvestedBagsEdit from "./HarvestedBagsEdit";
import pb from "lib/pocketbase";
import "css/logged-in.css";
import HarvestList from "./HarvestList";
import InventoryModal from "./InventoryModal";
import Modal from "react-modal";
// Commented out for now until I get a better grasp for Excel Exportation
// import * as XLSX from "xlsx";

export const CultureContext = createContext();
export const SubstrateContext = createContext();
export const GrowBagsContext = createContext();
export const MushroomContext = createContext();
export const ContainerTypeContext = createContext({ containerTypes: [] });
export const ProductTypeContext = createContext({ productTypes: [] });
export const InventoryContext = createContext();
export const ProductInventoryTransactionContext = createContext();

// showCultures is a boolean true/false if the view cultures button is selected or not
function LoggedIn() {
  const [selectedCultureId, setSelectedCultureId] = useState();
  const [selectedSubstrateId, setSelectedSubstrateId] = useState();
  const [selectedGrowBagsId, setSelectedGrowBagsId] = useState();
  // const [selectedInventoryTransactionId, setSelectedInventoryTransactionId] =
  //   useState();
  const [cultures, setCultures] = useState([]);
  const [activeCultures, setActiveCultures] = useState([]);
  const [substrates, setSubstrates] = useState([]);
  const [mushrooms, setMushrooms] = useState([]);
  const [growBags, setGrowBags] = useState([]);
  const [inventoryTransactions, setInventoryTransactions] = useState([]);
  const [productInventoryTransactions, setProductInventoryTransactions] =
    useState([]);
  const [containerTypes, setContainerTypes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [batchID, setBatchID] = useState([]);
  const [addingNewCulture, setAddingNewCulture] = useState(false);
  const [addingNewSubstrate, setAddingNewSubstrate] = useState(false);
  const [addingNewGrowBags, setAddingNewGrowBags] = useState(true);
  const [showSubstrates, setShowSubstrates] = useState(false);
  const [showCultures, setShowCultures] = useState(false);
  const [showMushrooms, setShowMushrooms] = useState(false);
  const [showGrowBags, setShowGrowBags] = useState(false);
  const [showHarvests, setShowHarvests] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showCompletedBatches, setShowCompletedBatches] = useState(false);
  const [showFullyHarvested, setShowFullyHarvested] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [showReportGeneration, setShowReportGeneration] = useState(false);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [isNewGrowBag, setIsNewGrowBag] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [showSubstrateEdit, setShowSubstrateEdit] = useState(false);
  const [showSubstrateView, setShowSubstrateView] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [selectedGrowBag, setSelectedGrowBag] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [finalDeleteModalOpen, setFinalDeleteModalOpen] = useState(false);
  const [toBeDeletedId, setToBeDeletedId] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [originalBatchData, setOriginalBatchData] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [harvestedOrDriedBatch, setHarvestedOrDriedBatch] = useState(false);
  const [addedOrUpdatedBatch, setAddedOrUpdatedBatch] = useState({
    ...growBags,
    originalGrowBag: growBags,
  });
  const [splitTentBatch1, setSplitTentBatch1] = useState();
  const [splitTentBatch2, setSplitTentBatch2] = useState();
  const [discardedBatchModal, setDiscardedBatchModal] = useState(false);
  // LEAVE OFF HERE -- FIX DISPLAY MODAL WHEN DISCARDING BAGS
  const [modalAddingText, setModalAddingText] = useState(false);
  const [inventoryTransferSuccessModal, setInventoryTransferSuccessModal] =
    useState(false);
  const hasChanged = (key) => {
    if (
      originalBatchData &&
      originalBatchData[key] !== addedOrUpdatedBatch[key]
    ) {
      // Check if the values are date strings
      const originalDate = Date.parse(originalBatchData[key]);
      const updatedDate = Date.parse(addedOrUpdatedBatch[key]);

      if (!isNaN(originalDate) && !isNaN(updatedDate)) {
        // If both values are valid dates, compare them as Date objects
        return (
          new Date(originalDate).getTime() !== new Date(updatedDate).getTime()
        );
      } else {
        // If the values are not dates, compare them as is
        return true;
      }
    }

    return false;
  };

  const boldIfChanged = (key) => {
    return hasChanged(key) ? { fontWeight: "bold", color: "darkred" } : {};
  };

  const resetSelectedGrowBagsId = () => {
    setSelectedGrowBagsId(null);
  };

  const toggleInventoryModal = (growBag) => {
    setSelectedGrowBag(growBag);
    setShowInventoryModal(!showInventoryModal);
  };

  // This has to be the first thing to populate the current user
  async function fetchCurrentUser() {
    const user = await pb.authStore.model.id;
    setCurrentUser(user);
  }

  // All of these fetch constants use the useCallback to ONLY pull the values from the currentUser
  const fetchInventoryTransactions = useCallback(async () => {
    const filteredInventoryList = await pb
      .collection("inventory_transactions")
      .getFullList({
        sort: "-created",
        filter: `created_by = "${currentUser}"`,
        expand: "product_type",
      });
    setInventoryTransactions(filteredInventoryList);
    return filteredInventoryList;
  }, [currentUser]);

  const fetchProductInventoryTransactions = useCallback(async () => {
    const filteredProductInventoryTransactionList = await pb
      .collection("product_in_inventory_transactions")
      .getFullList({
        sort: "-created",
        filter: `created_by = "${currentUser}"`,
        expand: "product_type",
      });
    setProductInventoryTransactions(filteredProductInventoryTransactionList);

    return filteredProductInventoryTransactionList;
  }, [currentUser]);

  const fetchSubstrates = useCallback(async () => {
    const filteredSubstrateList = await pb
      .collection("substrate_recipes")
      .getFullList({
        sort: "-created",
        filter: `created_by = "${currentUser}"`,
      });
    setSubstrates(filteredSubstrateList);
    return filteredSubstrateList;
  }, [currentUser]);

  const fetchCultures = useCallback(async () => {
    const filteredCultureList = await pb.collection("cultures").getFullList({
      sort: "-created",
      filter: `created_by = "${currentUser}"`,
    });
    setCultures(filteredCultureList);

    // Filter out cultures where is_finished is false
    const activeCultureList = filteredCultureList.filter(
      (culture) => !culture.is_finished
    );
    setActiveCultures(activeCultureList);

    return filteredCultureList;
  }, [currentUser]);

  const fetchContainerTypes = useCallback(async () => {
    const filteredContainerTypeList = await pb
      .collection("container_types")
      .getFullList({
        sort: "-created",
        filter: `created_by = "${currentUser}"`,
      });
    setContainerTypes(filteredContainerTypeList);
    return filteredContainerTypeList;
  }, [currentUser]);

  const fetchProductTypes = useCallback(async () => {
    const filteredProductTypeList = await pb
      .collection("product_type")
      .getFullList({
        sort: "-created",
        filter: `created_by = "${currentUser}"`,
      });
    setProductTypes(filteredProductTypeList);
    return filteredProductTypeList;
  }, [currentUser]);

  const fetchMushrooms = useCallback(async () => {
    const filteredMushroomList = await pb
      .collection("mushroom_types")
      .getFullList({
        sort: "-created",
        filter: `created_by = "${currentUser}"`,
      });

    setMushrooms(filteredMushroomList);
    return filteredMushroomList;
  }, [currentUser]);

  const fetchGrowBags = useCallback(async () => {
    const filteredGrowBagsList = await pb.collection("grow_bags").getFullList({
      sort: "-batch_id_number",
      filter: `created_by = "${currentUser}"`,
      expand: "culture_used",
    });
    setGrowBags(filteredGrowBagsList);
    // Check if the list is empty or not
    if (filteredGrowBagsList.length === 0) {
      setBatchID(1);
    } else {
      // Find the maximum batch_id_number and set batchID to max + 1
      const relevantFilteredGrowBagsList = filteredGrowBagsList.filter(
        (filteredGrowBagsList) =>
          filteredGrowBagsList.current_status === "Incubating" ||
          filteredGrowBagsList.current_status === "Growing"
      );

      if (relevantFilteredGrowBagsList.length === 0) {
        const regex = /(\d+)/; // This will match the number at the beginning of the string, before "-"
        const batchNumbers = filteredGrowBagsList.map((bag) => {
          const match = bag.batch_id_number.match(regex);
          return match ? parseInt(match[1], 10) : 0;
        });
        const maxBatchID = Math.max(...batchNumbers);
        setBatchID(maxBatchID + 1);
      } else {
        const regex = /^(\d+)/; // This will match the number at the beginning of the string, before "-"
        const batchNumbers = relevantFilteredGrowBagsList.map((bag) => {
          const match = bag.batch_id_number.match(regex);
          return match ? parseInt(match[1], 10) : 0;
        });
        const maxBatchID = Math.max(...batchNumbers);
        setBatchID(maxBatchID + 1);
      }
    }

    return filteredGrowBagsList;
  }, [currentUser]);

  ///////////////////////////////
  ////////
  // Here is the code for the excel generated backup
  ////////
  ///////////////////////////////////

  //// THIS SECTION IS COMMENTED OUT FOR NOW UNTIL I GET A BETTER EXCEL EXPORT

  // // array of fetch functions
  // const fetchFunctions = [
  //   fetchProductInventoryTransactions,
  //   fetchInventoryTransactions,
  //   fetchSubstrates,
  //   fetchCultures,
  //   fetchContainerTypes,
  //   fetchProductTypes,
  //   fetchMushrooms,
  //   fetchGrowBags,
  // ];

  // // async function to fetch all data and convert it into CSV
  // async function fetchDataAndConvertToCSV() {
  //   let csvData = "";

  //   // loop through each fetch function
  //   for (let i = 0; i < fetchFunctions.length; i++) {
  //     // fetch data and store it in data
  //     let data = await fetchFunctions[i]();

  //     // debug: print fetched data
  //     console.log(
  //       `Data fetched from function ${fetchFunctions[i].name}:`,
  //       data
  //     );

  //     // convert fetched data into CSV and append to csvData string
  //     // check if data is defined and not empty
  //     if (data && data.length > 0) {
  //       csvData += convertToCSV(data);
  //     }
  //   }

  //   return csvData;
  // }

  // // async function to submit inventory data

  // function convertToCSV(objArray) {
  //   const array =
  //     typeof objArray !== "object" ? JSON.parse(objArray) : objArray;

  //   let str = `${Object.keys(array[0])
  //     .map((value) => `"${value}"`)
  //     .join(",")}\r\n`;

  //   return array.reduce((str, next) => {
  //     str += `${Object.values(next)
  //       .map((value) => {
  //         // check if value is an object
  //         if (typeof value === "object" && value !== null) {
  //           // convert object to a string
  //           return `"${JSON.stringify(value)}"`;
  //         }
  //         return `"${value}"`; // Add this line
  //       })
  //       .join(",")}\r\n`;
  //     return str;
  //   }, str);
  // }

  // function downloadExcel(csvData, fileName) {
  //   // debug: print the CSV data
  //   console.log("CSV data:", csvData);

  //   // create a new workbook
  //   const workbook = XLSX.read(csvData, { type: "binary" });

  //   // write workbook to a file and trigger a download
  //   XLSX.writeFile(workbook, fileName);
  // }

  ////////////////////////////////////
  /////////////////////////////////////
  //////////////////////////////////////
  ////////////////////////////////

  useEffect(() => {
    fetchCurrentUser();
    fetchCultures();
    fetchSubstrates();
    fetchMushrooms();
    fetchGrowBags();
    fetchContainerTypes();
    fetchProductTypes();
    fetchInventoryTransactions();
    fetchProductInventoryTransactions();
  }, [
    fetchSubstrates,
    fetchCultures,
    fetchContainerTypes,
    fetchProductTypes,
    fetchGrowBags,
    fetchMushrooms,
    fetchInventoryTransactions,
    fetchProductInventoryTransactions,
  ]);

  // Used when clicking edit on a culture - sets selected culture to be displayed in CultureEdit component
  function handleCultureSelect(id) {
    setAddingNewCulture(false);
    setSelectedCultureId(id);
    window.scrollTo(0, 160);
  }

  // Used when clicking edit on a substrate - sets selected culture to be displayed in CultureEdit component
  function handleSubstrateSelect(id) {
    setSelectedSubstrateId(id);
    setShowSubstrateView(true);
    setShowSubstrateEdit(false);
    setAddingNewSubstrate(false);
  }

  function handleSubstrateEdit(id) {
    setShowSubstrateEdit(true);
    setSelectedSubstrateId(id);
    setShowSubstrateView(true);
  }

  function handleExitSubstrateView() {
    setShowSubstrateView(false);
    setSelectedSubstrateId(undefined);
  }

  // Used when clicking edit on growbags - sets selected grow bags to be displayed in GrowBagsEdit component
  function handleGrowBagsSelect(id) {
    setAddingNewGrowBags(false);
    setSelectedGrowBagsId(id);
    setIsNewGrowBag(false);
    window.scrollTo(0, 130);
  }

  ///
  //// TOGGLE SECTION FOR DIFFERENT TABS
  ///

  // Ensure all toggles set the other options to false when adding new toggle options
  function toggleCultures() {
    setShowCultures((prev) => {
      if (!prev) {
        setActiveButton("cultures");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setSelectedCultureId(undefined);
    setAddingNewCulture(false);
    setShowMushrooms(false);
    setShowSubstrates(false);
    setShowGrowBags(false);
    setShowInventory(false);
    setShowHarvests(false);
    setShowFullyHarvested(false);
    setShowAnalytics(false);
    setShowCompletedBatches(false);
    setShowDropdownMenu(false);
    setShowReportGeneration(false);
  }

  function toggleMushroomDetails() {
    setShowMushrooms((prev) => {
      if (!prev) {
        setActiveButton("mushrooms");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setShowCultures(false);
    setShowSubstrates(false);
    setShowGrowBags(false);
    setShowInventory(false);
    setShowHarvests(false);
    setShowFullyHarvested(false);
    setShowAnalytics(false);
    setShowCompletedBatches(false);
    setShowDropdownMenu(true);
    setShowReportGeneration(false);
  }

  function toggleSubstrates() {
    setShowSubstrates((prev) => {
      if (!prev) {
        setActiveButton("substrates");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setSelectedSubstrateId(undefined);
    setAddingNewSubstrate(false);
    setShowCultures(false);
    setShowMushrooms(false);
    setShowGrowBags(false);
    setShowInventory(false);
    setShowHarvests(false);
    setShowFullyHarvested(false);
    setShowAnalytics(false);
    setShowCompletedBatches(false);
    setShowDropdownMenu(false);
    setShowReportGeneration(false);
  }

  function toggleGrowBags() {
    setShowGrowBags((prev) => {
      if (!prev) {
        setActiveButton("growBags");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setSelectedGrowBagsId(undefined);
    setAddingNewGrowBags(false);
    setShowSubstrates(false);
    setShowMushrooms(false);
    setShowCultures(false);
    setShowInventory(false);
    setShowHarvests(false);
    setShowFullyHarvested(false);
    setShowAnalytics(false);
    setShowCompletedBatches(false);
    setShowDropdownMenu(false);
    setShowReportGeneration(false);
  }

  function toggleHarvests() {
    setShowHarvests((prev) => {
      if (!prev) {
        setActiveButton("harvests");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setSelectedGrowBagsId(undefined);
    setShowCultures(false);
    setShowSubstrates(false);
    setShowGrowBags(false);
    setShowMushrooms(false);
    setShowInventory(false);
    setShowFullyHarvested(false);
    setShowAnalytics(false);
    setShowCompletedBatches(false);
    setShowDropdownMenu(false);
    setShowReportGeneration(false);
  }

  function toggleInventory() {
    setShowInventory((prev) => {
      if (!prev) {
        setActiveButton("inventory");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setShowCultures(false);
    setShowSubstrates(false);
    setShowGrowBags(false);
    setShowMushrooms(false);
    setShowHarvests(false);
    setShowFullyHarvested(false);
    setShowAnalytics(false);
    setShowCompletedBatches(false);
    setShowDropdownMenu(false);
    setShowReportGeneration(false);
  }

  const toggleFullyHarvested = () => {
    setShowFullyHarvested((prev) => {
      if (!prev) {
        setActiveButton("fullyHarvested");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setShowCultures(false);
    setShowSubstrates(false);
    setShowGrowBags(false);
    setShowMushrooms(false);
    setShowHarvests(false);
    setShowInventory(false);
    setShowCompletedBatches(false);
    setShowDropdownMenu(true);
    setShowAnalytics(false);
    setShowReportGeneration(false);
  };

  const toggleAnalyticsDetails = () => {
    setShowAnalytics((prev) => {
      if (!prev) {
        setActiveButton("analytics");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setShowCultures(false);
    setShowSubstrates(false);
    setShowGrowBags(false);
    setShowMushrooms(false);
    setShowHarvests(false);
    setShowInventory(false);
    setShowCompletedBatches(false);
    setShowDropdownMenu(true);
    setShowFullyHarvested(false);
    setShowReportGeneration(false);
  };

  const toggleCompletedBatches = () => {
    setShowCompletedBatches((prev) => {
      if (!prev) {
        setActiveButton("completedBatches");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setShowCultures(false);
    setShowSubstrates(false);
    setShowGrowBags(false);
    setShowMushrooms(false);
    setShowHarvests(false);
    setShowFullyHarvested(false);
    setShowAnalytics(false);
    setShowDropdownMenu(true);
    setShowInventory(false);
    setShowReportGeneration(false);
  };

  const toggleReportGenerationTab = () => {
    setShowReportGeneration((prev) => {
      if (!prev) {
        setActiveButton("reportGeneration");
      } else {
        setActiveButton(false);
      }
      return !prev;
    });
    setShowCultures(false);
    setShowSubstrates(false);
    setShowGrowBags(false);
    setShowMushrooms(false);
    setShowHarvests(false);
    setShowFullyHarvested(false);
    setShowAnalytics(false);
    setShowDropdownMenu(true);
    setShowInventory(false);
    setShowCompletedBatches(false);
  };

  function handleCultureCancelAdd() {
    setAddingNewCulture(false);
  }

  function handleSubstrateCancelAdd() {
    setAddingNewSubstrate(false);
    setShowSubstrateEdit(false);
  }

  function handleGrowBagsCancelAdd() {
    setAddingNewGrowBags(false);
  }

  function formatDate(dateString) {
    let date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  // Updates the culture doc in Pocketbase database by calling "update" method
  // Calls fetchCultures to refresh culture list with updated data
  // The .doc(id) is referencing a specific document in pocketbase

  // Cultures CRUD section
  async function handleCultureChange(id, updatedCulture) {
    if (id) {
      await pb.collection("cultures").update(id, updatedCulture);
    } else {
      if (currentUser) {
        const response = await pb.collection("cultures").create({
          ...updatedCulture,
        });
        setSelectedCultureId(response.id);
      }
    }
    setAddingNewCulture(false);
    await fetchCultures();
  }

  async function handleCultureDelete(id) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this culture?"
    );
    if (confirmed) {
      await pb.collection("cultures").delete(id);
      setCultures(cultures.filter((culture) => culture.id !== id));
    }
  }

  async function handleCultureAdd() {
    setSelectedCultureId(undefined);
    setAddingNewCulture(true);
  }

  // Substrate CRUD section
  async function handleSubstrateChange(id, updatedSubstrate) {
    if (id) {
      await pb.collection("substrate_recipes").update(id, updatedSubstrate);
    } else {
      if (currentUser) {
        const response = await pb
          .collection("substrate_recipes")
          .create({ ...updatedSubstrate });
        setSelectedSubstrateId(response.id);
      }
    }
    setAddingNewSubstrate(false);
    await fetchSubstrates();
  }

  async function handleSubstrateDelete(id) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this substrate recipe?"
    );
    if (confirmed) {
      await pb.collection("substrate_recipes").delete(id);
      setSubstrates(substrates.filter((substrate) => substrate.id !== id));
    }
  }

  async function handleSubstrateAdd() {
    setSelectedSubstrateId(undefined);
    setAddingNewSubstrate(true);
    setShowSubstrateEdit(true);
  }

  // GrowBags CRUD section
  async function handleGrowBagsChange(id, updatedGrowBag, callback) {
    // Check if culture_used exists before accessing its id
    if (updatedGrowBag.culture_used) {
      updatedGrowBag.culture_used = updatedGrowBag.culture_used.id;
    } else {
      updatedGrowBag.culture_used = ""; // or null or any other default value
    }
    if (id) {
      await pb.collection("grow_bags").update(id, updatedGrowBag);
      // Check if the current grow bag is a harvest batch (it has a 'harvest_for_batch' property)
      if (updatedGrowBag.harvest_for_batch) {
        // Fetch the parent growing batch
        const parentGrowingBatchDetails = await pb
          .collection("grow_bags")
          .getOne(updatedGrowBag.harvest_for_batch, {
            expand: "original_number_of_bags",
          });

        const batchesWithSameParent = await pb
          .collection("grow_bags")
          .getFullList({
            filter: `harvest_for_batch = "${parentGrowingBatchDetails.id}"`,
          });

        // Calculate the total number of bags in all the batches with the same parent
        let totalBagsInHarvestBatches = 0;
        batchesWithSameParent.forEach((batch) => {
          totalBagsInHarvestBatches += batch.number_of_bags;
        });

        // Calculate the new number of bags for the parent growing batch
        const parentGrowingBatchNewNumberOfBags =
          parentGrowingBatchDetails.original_number_of_bags -
          totalBagsInHarvestBatches;

        if (parentGrowingBatchNewNumberOfBags < 0) {
          return "You cannot change the number of bags to this number, not enough bags in parent growth batch";
        }

        // Update the number of bags in the parent growing batch
        await pb
          .collection("grow_bags")
          .update(updatedGrowBag.harvest_for_batch, {
            number_of_bags: parentGrowingBatchNewNumberOfBags,
          });
      }

      if (callback) {
        // Revert the culture_used property to its original object form
        const foundCulture = cultures.find((c) => {
          return c.id === updatedGrowBag.culture_used;
        });

        if (foundCulture) {
          callback(foundCulture.id);
        }
      }

      const originalGrowBag = growBags.find((bag) => bag.id === id);
      setOriginalBatchData(originalGrowBag);
    } else {
      const response = await pb.collection("grow_bags").create(updatedGrowBag);
      setSelectedGrowBagsId(response.id);
      setModalAddingText(true);
      setOriginalBatchData(null); // No original data for a newly created grow bag
    }
    await fetchGrowBags();
    setHarvestedOrDriedBatch(false);
    setToBeDeletedId(false);
    setAddedOrUpdatedBatch({ ...updatedGrowBag });
    if (
      (updatedGrowBag.current_status === "Harvested" ||
        updatedGrowBag.current_status === "Dried") &&
      !toBeDeletedId
    ) {
      setHarvestedOrDriedBatch(true);
    } else if (
      updatedGrowBag.current_status === "Incubating" ||
      updatedGrowBag.current_status === "Growing"
    ) {
      setHarvestedOrDriedBatch(false);
    }
    if (updatedGrowBag.batch_complete === true) {
      setModalAddingText(false);
      setConfirmationModalOpen(false);
      setHarvestedOrDriedBatch(false);
      setShowHarvests(false);
      setShowInventory(true);
      setActiveButton("inventory");
      setInventoryTransferSuccessModal(true);
    } else {
      setConfirmationModalOpen(true);
    }
    if (
      updatedGrowBag.current_status === "Discarded" ||
      updatedGrowBag.current_status === "Contaminated"
    )
      setDiscardedBatchModal(true);
  }

  // Product Inventory Transactions CRUD section
  async function handleProductInventoryTransactionChange(
    id,
    productInventoryTransaction
  ) {
    if (id) {
      await pb
        .collection("product_in_inventory_transactions")
        .update(id, productInventoryTransaction);
    } else {
      // Add responses in here then to check
      await pb
        .collection("product_in_inventory_transactions")
        .create(productInventoryTransaction);
    }
    await fetchProductInventoryTransactions();
  }

  // console.log("Added/updated batch", addedOrUpdatedBatch);

  async function handleProductInventoryTransactionAdd(productInventoryData) {
    // Add a response in here then to check
    await pb
      .collection("product_in_inventory_transactions")
      .create(productInventoryData);
    //setSelectedInventoryTransactionId(response.id);
    await fetchProductInventoryTransactions();
  }

  // Inventory Transactions CRUD section
  async function handleInventoryTransactionChange(id, inventoryTransaction) {
    if (id) {
      await pb
        .collection("inventory_transactions")
        .update(id, inventoryTransaction);
    } else {
      // Add responses in here then to check
      await pb
        .collection("inventory_transactions")
        .create(inventoryTransaction);
      //setSelectedInventoryTransactionId(response.id);
    }
    await fetchInventoryTransactions();
  }

  async function handleInventoryTransactionAdd(inventoryData) {
    // Add a response in here then to check
    await pb.collection("inventory_transactions").create(inventoryData);
    //setSelectedInventoryTransactionId(response.id);
    await fetchInventoryTransactions();
  }

  async function submitInventoryData(growBag) {
    growBag.batch_complete = true;
    // Save the updated growBag to the database
    await handleGrowBagsChange(growBag.id, growBag);
    // Split to only take the culture, not the master culture
    const cultureUsed = growBag.culture_used.split(" from ")[0];

    // Create the inventory data object
    // prettier-ignore

    const inventoryData = {
      "transaction_type": "add",
      "add_type": "grown",
      "from_batch_id": growBag.batch_id_number,
      "harvest_batch_relation": growBag.id,
      "transaction_date": new Date(),
      "mushroom_type": growBag.mushroom_type,
      "created_by": growBag.created_by,
      "substrate_recipe": growBag.substrate_used,
      "culture_type": cultureUsed,
      ...(growBag.current_status === "Harvested"
      ? {
        "total_fresh": growBag.fresh_grams_yielded,
        "grade_a_fresh": growBag.grade_a_fresh_grams,
        "grade_b_fresh": growBag.grade_b_fresh_grams,
        "grade_c_fresh": growBag.grade_c_fresh_grams,
      }
      : {
        "total_dried": growBag.dried_grams_yielded,

        "grade_a_dried": growBag.grade_a_dried_grams,
        "grade_b_dried": growBag.grade_b_dried_grams,
        "grade_c_dried": growBag.grade_c_dried_grams,
        "dried_substrate_grams": growBag.dried_substrate_weight_grams,
        "dried_type": growBag.dried_type,
        "total_retention_percentage": ((growBag.dried_grams_yielded / growBag.fresh_grams_yielded)*100),
        "grade_a_retention_percentage": ((growBag.grade_a_dried_grams / growBag.grade_a_fresh_grams)*100),
        "grade_b_retention_percentage": ((growBag.grade_b_dried_grams / growBag.grade_b_fresh_grams)*100),
        "grade_c_retention_percentage": ((growBag.grade_c_dried_grams / growBag.grade_c_fresh_grams)*100),
        "dried_substrate_retention_percentage": ((growBag.dried_substrate_grams / growBag.substrate_weight_grams)*100)
      }),

    };

    await handleInventoryTransactionAdd(inventoryData);

    // Close the modal
    setShowInventoryModal(false);

    // Return a confirmation popup here
  }

  function handleGrowBagsDelete(id, growBag) {
    setToBeDeletedId(id);
    setSelectedBatch(growBag);

    // ensure all other modals are closed
    setDeleteModalOpen(false);
    setFinalDeleteModalOpen(false);
    setConfirmationModalOpen(false);
    setInventoryTransferSuccessModal(false);
    setHarvestedOrDriedBatch(false);
    setAddedOrUpdatedBatch(false);

    if (
      growBag.current_status === "Harvested" ||
      growBag.current_status === "Dried"
    ) {
      setFinalDeleteModalOpen(true);
    } else {
      setDeleteModalOpen(true);
    }
  }

  async function handleConfirmDelete() {
    setDeleteModalOpen(false);
    setFinalDeleteModalOpen(true);
    setAddingNewGrowBags(false);
    setAddedOrUpdatedBatch(false);
  }

  async function handleFinalConfirmDeleteHarvestedOrDried(id, growBag) {
    setToBeDeletedId(id);
    setSelectedBatch(growBag);
    setAddedOrUpdatedBatch(false);

    const toBeDeletedBatch = await pb
      .collection("grow_bags")
      .getOne(toBeDeletedId);

    const parentBatch = await pb
      .collection("grow_bags")
      .getOne(toBeDeletedBatch.harvest_for_batch);

    setFinalDeleteModalOpen(false);
    await pb.collection("grow_bags").update(parentBatch.id, {
      number_of_bags: parseInt(
        parentBatch.number_of_bags + toBeDeletedBatch.number_of_bags
      ),
    });
    await pb.collection("grow_bags").delete(toBeDeletedBatch.id);
    setSelectedBatch(null);
    setToBeDeletedId(null);
    window.location.reload();
  }

  async function handleFinalConfirmDelete() {
    setAddedOrUpdatedBatch(false);
    const toBeDeletedBatch = await pb
      .collection("grow_bags")
      .getOne(toBeDeletedId);
    await pb.collection("grow_bags").delete(toBeDeletedBatch.id);

    setSelectedBatch(null);
    setToBeDeletedId(null);
    window.location.reload();
  }

  async function handleGrowBagsAdd() {
    setSelectedGrowBagsId(undefined);
    setAddingNewGrowBags(true);
    setIsNewGrowBag(true);
  }

  // Section to Add and Delete Mushrooms from Mushroom Detail component
  async function handleMushroomAdd(newMushroomName) {
    const data = { mushroom_name: newMushroomName, created_by: currentUser };
    const response = await pb.collection("mushroom_types").create(data);
    setMushrooms([...mushrooms, response]);
  }

  async function handleMushroomDelete(id) {
    await pb.collection("mushroom_types").delete(id);
    setMushrooms(mushrooms.filter((mushroom) => mushroom.id !== id));
  }

  // Section to Add and Delete Bag Types from Mushroom Detail component
  async function handleContainerTypeAdd(newContainerType, newBagType) {
    const data = {
      container_type: newContainerType,
      bag_type: newBagType,
      created_by: currentUser,
    };
    const response = await pb.collection("container_types").create(data);
    setContainerTypes([...containerTypes, response]);
  }

  async function handleContainerTypeDelete(id) {
    await pb.collection("container_types").delete(id);
    setContainerTypes(
      containerTypes.filter((containerType) => containerType.id !== id)
    );
  }

  // Section to Add and Delete Product Types from Mushroom Detail component
  async function handleProductTypeAdd(newProductType, newProductTypeGrams) {
    const data = {
      name: newProductType,
      g_mushroom_used_per_unit: newProductTypeGrams,
      created_by: currentUser,
    };
    const response = await pb.collection("product_type").create(data);
    setProductTypes([...productTypes, response]);
  }

  async function handleProductTypeDelete(id) {
    await pb.collection("product_type").delete(id);
    setProductTypes(
      productTypes.filter((productType) => productType.id !== id)
    );
  }

  const selectedCulture = cultures.find(
    (culture) => culture.id === selectedCultureId
  );

  const selectedSubstrate = substrates.find(
    (substrate) => substrate.id === selectedSubstrateId
  );

  const selectedGrowBags = growBags.find(
    (growBags) => growBags.id === selectedGrowBagsId
  );

  const handleDropdownChange = (value) => {
    setActiveButton(value);

    switch (value) {
      case "fullyHarvested":
        toggleFullyHarvested();
        break;
      case "completedBatches":
        toggleCompletedBatches();
        break;
      case "mushrooms":
        toggleMushroomDetails();
        break;
      case "analytics":
        toggleAnalyticsDetails();
        break;
      case "reportGeneration":
        toggleReportGenerationTab();
        break;
      default:
        setShowFullyHarvested(false);
        setShowCompletedBatches(false);
        setShowMushrooms(false);
        setShowAnalytics(false);
        setShowReportGeneration(false);
    }
  };

  // Compute the percentage of dried/fresh grams in a batch for display
  const computeRetentionPercentage = (fresh, dried) => {
    if (!isNaN(fresh) && !isNaN(dried) && fresh !== 0) {
      return ((dried / fresh) * 100).toFixed(2);
    } else {
      return "N/A";
    }
  };

  // Key and value are same value -- only have to specify context once
  const cultureContextValue = {
    cultures,
    handleCultureSelect,
    handleCultureChange,
    handleCultureAdd,
    handleCultureDelete,
  };

  const substrateContextValue = {
    substrates,
    handleSubstrateSelect,
    handleSubstrateEdit,
    handleSubstrateChange,
    handleSubstrateAdd,
    handleSubstrateDelete,
    setShowSubstrateEdit,
  };

  const growBagsContextValue = {
    growBags,
    handleGrowBagsSelect,
    handleGrowBagsChange,
    handleGrowBagsAdd,
    handleGrowBagsDelete,
  };

  const mushroomContextValue = {
    mushrooms,
    handleMushroomAdd,
    handleMushroomDelete,
  };

  const containerTypeContextValue = {
    containerTypes,
    handleContainerTypeAdd,
    handleContainerTypeDelete,
  };

  const productTypeContextValue = {
    productTypes,
    handleProductTypeAdd,
    handleProductTypeDelete,
  };

  const inventoryContextValue = {
    inventoryTransactions,
    submitInventoryData,
    handleInventoryTransactionAdd,
    handleInventoryTransactionChange,
  };

  const productInventoryTransactionContextValue = {
    productInventoryTransactions,
    handleProductInventoryTransactionAdd,
    handleProductInventoryTransactionChange,
  };

  return (
    <>
      <h1>Welcome: {pb.authStore.model.name}</h1>
      <h2>The mushroom world awaits you...</h2>
      <div className="section-buttons">
        <button
          className={`display-button${
            activeButton === "cultures" ? " active" : ""
          }`}
          onClick={toggleCultures}
        >
          View Cultures
        </button>
        <button
          className={`display-button${
            activeButton === "substrates" ? " active" : ""
          }`}
          onClick={toggleSubstrates}
        >
          View Substrates
        </button>
        <button
          className={`display-button${
            activeButton === "growBags" ? " active" : ""
          }`}
          onClick={toggleGrowBags}
        >
          View Growth
        </button>
        <button
          className={`display-button${
            activeButton === "harvests" ? " active" : ""
          }`}
          onClick={toggleHarvests}
        >
          View Harvests
        </button>
        <button
          className={`display-button${
            activeButton === "inventory" ? " active" : ""
          }`}
          onClick={toggleInventory}
        >
          View Inventory
        </button>
        <select
          className={`display-dropdown${
            [
              "fullyHarvested",
              "completedBatches",
              "mushrooms",
              "analytics",
              "reportGeneration",
            ].includes(activeButton)
              ? " active"
              : ""
          }`}
          onChange={(e) => handleDropdownChange(e.target.value)}
          value={activeButton}
        >
          {!showDropdownMenu && <option value="">Other Views</option>}
          {/* Blanked out for now until I fully complete these pages */}
          {/* <option value="fullyHarvested">View Fully Harvested Batches</option> */}
          {/* <option value="completedBatches">View Completed Batches</option> */}
          <option value="mushrooms">Mushroom/Product Details</option>
          <option value="analytics">View Analytics</option>
          <option value="reportGeneration">Report Generation</option>
        </select>
        {/* Commented out for now until I get this figured out */}
        {/* <div className="backup-dropdown-container">
          <button
            className="backup-button"
            onClick={async () => {
              const csvData = await fetchDataAndConvertToCSV();
              downloadExcel(csvData, "backup.xlsx");
            }}
          >
            Backup to Excel
          </button>
        </div> */}
      </div>

      {showInventory && (
        <InventoryContext.Provider value={inventoryContextValue}>
          <MushroomContext.Provider value={mushroomContextValue}>
            <ProductTypeContext.Provider value={productTypeContextValue}>
              <ProductInventoryTransactionContext.Provider
                value={productInventoryTransactionContextValue}
              >
                <ViewInventory
                  currentUser={currentUser}
                  inventoryTransactions={inventoryTransactions}
                  growBags={growBags}
                  mushrooms={mushrooms}
                  cultures={cultures}
                  substrates={substrates}
                  productTypes={productTypes}
                  productInventoryTransactions={productInventoryTransactions}
                  containerTypes={containerTypes}
                />
              </ProductInventoryTransactionContext.Provider>
            </ProductTypeContext.Provider>
          </MushroomContext.Provider>
        </InventoryContext.Provider>
      )}

      {showMushrooms && (
        <MushroomContext.Provider value={mushroomContextValue}>
          <ContainerTypeContext.Provider value={containerTypeContextValue}>
            <ProductTypeContext.Provider value={productTypeContextValue}>
              <MushroomList currentUser={currentUser} />
            </ProductTypeContext.Provider>
          </ContainerTypeContext.Provider>
        </MushroomContext.Provider>
      )}

      {showCultures && (
        <CultureContext.Provider value={cultureContextValue}>
          <div className="container">
            <CultureList cultures={cultures} />
            {(selectedCulture || addingNewCulture) && (
              <CultureEdit
                key={selectedCulture?.id || "add"}
                culture={selectedCulture}
                addingNewCulture={addingNewCulture}
                onCultureCancelAdd={handleCultureCancelAdd}
                mushrooms={mushrooms}
                selectedCultureId={selectedCultureId}
                currentUser={currentUser}
              />
            )}
          </div>
        </CultureContext.Provider>
      )}

      {showSubstrates && (
        <SubstrateContext.Provider value={substrateContextValue}>
          <div className="container">
            <SubstrateList substrates={substrates} />
            <div className="substrate-container">
              {showSubstrateView &&
                selectedSubstrate &&
                !addingNewSubstrate && (
                  <SubstrateView
                    substrate={selectedSubstrate}
                    onEdit={handleSubstrateEdit}
                    onExit={handleExitSubstrateView}
                    currentUser={currentUser}
                  />
                )}
            </div>
            <div className="substrate-container">
              {(addingNewSubstrate ||
                (showSubstrateView &&
                  selectedSubstrate &&
                  showSubstrateEdit)) && (
                <SubstrateEdit
                  key={selectedSubstrate?.id || "add"}
                  substrate={selectedSubstrate}
                  addingNewSubstrate={addingNewSubstrate}
                  onSubstrateCancelAdd={handleSubstrateCancelAdd}
                  mushrooms={mushrooms}
                  currentUser={currentUser}
                />
              )}
            </div>
          </div>
        </SubstrateContext.Provider>
      )}

      {showHarvests && (
        <GrowBagsContext.Provider value={growBagsContextValue}>
          <div className="container">
            <HarvestList
              growBags={growBags}
              resetSelectedGrowBagsId={resetSelectedGrowBagsId}
              toggleInventoryModal={toggleInventoryModal}
            />

            {growBags && selectedGrowBagsId && (
              <HarvestedBagsEdit
                key={selectedGrowBags?.id}
                growBags={selectedGrowBags}
                currentUser={currentUser}
              />
            )}
            <InventoryContext.Provider value={inventoryContextValue}>
              <InventoryModal
                show={showInventoryModal}
                onClose={() => setShowInventoryModal(false)}
                growBag={selectedGrowBag}
              />
            </InventoryContext.Provider>
          </div>
        </GrowBagsContext.Provider>
      )}

      {showGrowBags && (
        <GrowBagsContext.Provider value={growBagsContextValue}>
          <div className="container">
            <GrowBagsList
              growBags={growBags}
              activeCultures={activeCultures}
              resetSelectedGrowBagsId={resetSelectedGrowBagsId}
            />
            {growBags && (selectedGrowBags || addingNewGrowBags) && (
              <GrowBagsEdit
                key={selectedGrowBags?.id || "add"}
                growBags={selectedGrowBags}
                addingNewGrowBags={addingNewGrowBags}
                onGrowBagsCancelAdd={handleGrowBagsCancelAdd}
                mushrooms={mushrooms}
                cultures={cultures}
                substrates={substrates}
                containerTypes={containerTypes}
                batchID={batchID}
                isNewGrowBag={isNewGrowBag}
                currentUser={currentUser}
                setSplitTentBatch1={setSplitTentBatch1}
                setSplitTentBatch2={setSplitTentBatch2}
              />
            )}
          </div>
        </GrowBagsContext.Provider>
      )}
      {showFullyHarvested && <FullyHarvestedBatches growBags={growBags} />}
      {showCompletedBatches && <CompletedBatches growBags={growBags} />}
      {showAnalytics && (
        <Analytics
          growBags={growBags}
          cultures={cultures}
          substrates={substrates}
          productInventoryTransactions={productInventoryTransactions}
          mushrooms={mushrooms}
          productTypes={productTypes}
          inventoryTransactions={inventoryTransactions}
          containerTypes={containerTypes}
        />
      )}
      {showReportGeneration && (
        <ReportGeneration
          growBags={growBags}
          cultures={cultures}
          substrates={substrates}
        />
      )}

      {selectedBatch && (
        <Modal
          data={selectedBatch}
          isOpen={deleteModalOpen}
          onRequestClose={() => setDeleteModalOpen(false)}
          contentLabel="Delete Confirmation"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>Warning</h2>
          <p>You are about to permanently delete this batch:</p>
          <ul>
            <li>Batch Id Number: {selectedBatch.batch_id_number}</li>
            <li>Mushroom Type: {selectedBatch.mushroom_type}</li>
            <li>Number of Bags: {selectedBatch.number_of_bags}</li>
            <li>Culture Used: {selectedBatch.culture_used}</li>
            {/* Add more information here */}
          </ul>
          <p>
            Deleting this will also delete all harvest and transaction data
            associated with Batch {selectedBatch.batch_id_number}.
          </p>
          <p>
            For example, Harvest Batch {selectedBatch.batch_id_number}a,{" "}
            {selectedBatch.batch_id_number}b, etc. will also be deleted
          </p>
          <button className="modal-button-delete" onClick={handleConfirmDelete}>
            Delete
          </button>
          <button onClick={() => setDeleteModalOpen(false)}>Cancel</button>
        </Modal>
      )}
      {selectedBatch && (
        <Modal
          isOpen={finalDeleteModalOpen}
          onRequestClose={() => setFinalDeleteModalOpen(false)}
          contentLabel="Final Delete Confirmation"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>Final Delete Confirmation</h2>
          {(selectedBatch.current_status === "Incubating" ||
            selectedBatch.current_status === "Growing") && (
            <>
              <p>
                If any harvest or inventory transactions occurred, it is HIGHLY
                recommended you edit the batch data, rather than delete it.
              </p>
              <p>
                Deleting this will delete everything associated with this batch
                below and it CANNOT be undone.
              </p>
              <ul>
                <li>Batch Id Number: {selectedBatch.batch_id_number}</li>
                <li>Mushroom Type: {selectedBatch.mushroom_type}</li>
                <li>Number of Bags: {selectedBatch.number_of_bags}</li>
                <li>Culture Used: {selectedBatch.culture_used}</li>
              </ul>
              <button
                className="modal-button-delete"
                onClick={handleFinalConfirmDelete}
              >
                Permanently Delete
              </button>
              <button onClick={() => setFinalDeleteModalOpen(false)}>
                Cancel
              </button>
            </>
          )}
          {(selectedBatch.current_status === "Harvested" ||
            selectedBatch.current_status === "Dried") && (
            <>
              <p>
                Once you delete this it CANNOT be undone. It is gone
                forever...poof.
              </p>
              <ul>
                <li>Batch Id Number: {selectedBatch.batch_id_number}</li>
                <li>Mushroom Type: {selectedBatch.mushroom_type}</li>
                <li>Number of Bags: {selectedBatch.number_of_bags}</li>
                <li>Culture Used: {selectedBatch.culture_used}</li>
                <li>
                  Fresh Grams Yielded: {selectedBatch.fresh_grams_yielded}
                </li>
                {selectedBatch.dried_grams_yielded && (
                  <li>
                    Dried Grams Yielded: {selectedBatch.dried_grams_yielded}
                  </li>
                )}
                {selectedBatch.toggle_weigh_substrate && (
                  <li>
                    Dried Substrate Weight:{" "}
                    {selectedBatch.dried_substrate_weight_grams}
                  </li>
                )}
              </ul>
              <button
                className="modal-button-delete"
                onClick={handleFinalConfirmDeleteHarvestedOrDried}
              >
                Permanently Delete
              </button>
              <button onClick={() => setFinalDeleteModalOpen(false)}>
                Cancel
              </button>
            </>
          )}
        </Modal>
      )}
      {addedOrUpdatedBatch &&
        harvestedOrDriedBatch === false &&
        toBeDeletedId === false &&
        discardedBatchModal === false && (
          <Modal
            addedOrUpdatedBatch={addedOrUpdatedBatch}
            isOpen={confirmationModalOpen}
            onRequestClose={() => {
              setConfirmationModalOpen(false);
              setAddingNewGrowBags(false);
              setModalAddingText(false);
              setHarvestedOrDriedBatch(false);
              setAddedOrUpdatedBatch(false);
            }}
            contentLabel="Grow Bags Added Confirmation"
            className="modal"
            overlayClassName="modal-overlay"
          >
            {!splitTentBatch1 && (
              <>
                <h2>
                  {modalAddingText
                    ? "Growth Added Successfully"
                    : "Growth Updated Successfully"}
                </h2>
                <p>
                  You have successfully {modalAddingText ? "added" : "updated"}{" "}
                  growth with the following information:
                </p>
                <ul>
                  <li style={boldIfChanged("batch_id_number")}>
                    Batch ID: {addedOrUpdatedBatch.batch_id_number}
                  </li>
                  <li style={boldIfChanged("container_type")}>
                    Container Type: {addedOrUpdatedBatch.container_type}
                  </li>
                  {addedOrUpdatedBatch.container_type === "Bag" && (
                    <li style={boldIfChanged("bag_type")}>
                      Bag Type: {addedOrUpdatedBatch.bag_type}
                    </li>
                  )}
                  <li>
                    <React.Fragment>
                      <span style={boldIfChanged("number_of_bags")}>
                        {addedOrUpdatedBatch.number_of_bags}
                      </span>{" "}
                      {addedOrUpdatedBatch.container_type}s of{" "}
                      <span style={boldIfChanged("mushroom_type")}>
                        {" "}
                        {addedOrUpdatedBatch.mushroom_type}
                      </span>{" "}
                      --{" "}
                      <span style={boldIfChanged("current_status")}>
                        {addedOrUpdatedBatch.current_status}
                      </span>
                    </React.Fragment>
                  </li>
                  <li style={boldIfChanged("date_incubation_began")}>
                    Inoculation Date:{" "}
                    {formatDate(addedOrUpdatedBatch.date_incubation_began)}
                  </li>
                  {addedOrUpdatedBatch.culture_name && (
                    <li style={boldIfChanged("culture_name")}>
                      Culture Used: {addedOrUpdatedBatch.culture_name}
                    </li>
                  )}
                  <li style={boldIfChanged("substrate_used")}>
                    Substrate Used: {addedOrUpdatedBatch.substrate_used}
                  </li>
                  <li style={boldIfChanged("avg_ml_culture_per_bag")}>
                    Avg mL of Culture per {addedOrUpdatedBatch.container_type}:{" "}
                    {addedOrUpdatedBatch.avg_ml_culture_per_bag} mL
                  </li>
                  {addedOrUpdatedBatch.pre_incubation_note ? (
                    <li style={boldIfChanged("pre_incubation_note")}>
                      Pre-Incubation Note:{" "}
                      {addedOrUpdatedBatch.pre_incubation_note}
                    </li>
                  ) : null}
                  {addedOrUpdatedBatch.current_status === "Growing" ? (
                    <>
                      <li style={boldIfChanged("date_growing_began")}>
                        Growing Began Date:{" "}
                        {formatDate(addedOrUpdatedBatch.date_growing_began)}
                      </li>
                      <li style={boldIfChanged("tent_number")}>
                        Tent Number: {addedOrUpdatedBatch.tent_number}
                      </li>
                      {addedOrUpdatedBatch.pre_growth_note ? (
                        <li style={boldIfChanged("pre_growth_note")}>
                          Pre-Growth Note: {addedOrUpdatedBatch.pre_growth_note}
                        </li>
                      ) : null}
                    </>
                  ) : null}
                </ul>
                <button
                  onClick={() => {
                    setConfirmationModalOpen(false);
                    setModalAddingText(false);
                    setAddedOrUpdatedBatch(false);
                  }}
                >
                  Close
                </button>
              </>
            )}
            {splitTentBatch1 && (
              <>
                <h2>
                  {addedOrUpdatedBatch.mushroom_type} Growth Split Between Tents
                  Successfully
                </h2>
                <p>
                  You have successfully split batch ID:{" "}
                  {addedOrUpdatedBatch.batch_id_number.split("-")[0]} into two
                  batches: {splitTentBatch1.batch_id_number} and{" "}
                  {splitTentBatch2.batch_id_number}
                </p>
                <p>Batch {splitTentBatch1.batch_id_number} Info</p>
                <ul>
                  <li style={boldIfChanged("number_of_bags")}>
                    Number of {splitTentBatch1.container_type}s:{" "}
                    {splitTentBatch1.number_of_bags}
                  </li>
                  <li style={boldIfChanged("tent_number")}>
                    Tent Number: {splitTentBatch1.tent_number}
                  </li>
                  {splitTentBatch1.pre_growth_note && (
                    <li>Pre-growth note: {splitTentBatch1.pre_growth_note}</li>
                  )}
                </ul>
                <p>Batch {splitTentBatch2.batch_id_number} Info</p>
                <ul>
                  <li style={boldIfChanged("number_of_bags")}>
                    Number of {splitTentBatch2.container_type}s:{" "}
                    {splitTentBatch2.number_of_bags}
                  </li>
                  <li style={boldIfChanged("tent_number")}>
                    Tent Number: {splitTentBatch2.tent_number}
                  </li>
                  {splitTentBatch2.pre_growth_note && (
                    <li>Pre-Growth note: {splitTentBatch2.pre_growth_note}</li>
                  )}
                </ul>
                <p>Info Consistent to both batches:</p>
                <ul>
                  <li style={boldIfChanged("container_type")}>
                    Container Type: {addedOrUpdatedBatch.container_type}
                  </li>
                  {addedOrUpdatedBatch.container_type === "Bag" && (
                    <li style={boldIfChanged("bag_type")}>
                      Bag Type: {addedOrUpdatedBatch.bag_type}
                    </li>
                  )}
                  <li style={boldIfChanged("current_status")}>
                    Status: {addedOrUpdatedBatch.current_status}
                  </li>
                  <li style={boldIfChanged("date_incubation_began")}>
                    Inoculation Date:{" "}
                    {formatDate(addedOrUpdatedBatch.date_incubation_began)}
                  </li>
                  <li style={boldIfChanged("culture_name")}>
                    Culture Used: {addedOrUpdatedBatch.culture_name}
                  </li>
                  <li style={boldIfChanged("substrate_used")}>
                    Substrate Used: {addedOrUpdatedBatch.substrate_used}
                  </li>
                  <li style={boldIfChanged("avg_ml_culture_per_bag")}>
                    Avg mL of Culture per {addedOrUpdatedBatch.container_type}:{" "}
                    {addedOrUpdatedBatch.avg_ml_culture_per_bag} mL
                  </li>
                  {addedOrUpdatedBatch.pre_incubation_note ? (
                    <li style={boldIfChanged("pre_incubation_note")}>
                      Pre-Incubation Note:{" "}
                      {addedOrUpdatedBatch.pre_incubation_note}
                    </li>
                  ) : null}
                  {addedOrUpdatedBatch.current_status === "Growing" ? (
                    <>
                      <li style={boldIfChanged("date_growing_began")}>
                        Growing Began Date:{" "}
                        {formatDate(addedOrUpdatedBatch.date_growing_began)}
                      </li>
                    </>
                  ) : null}
                </ul>
                <button
                  onClick={() => {
                    setConfirmationModalOpen(false);
                    setModalAddingText(false);
                    setAddedOrUpdatedBatch(false);
                    handleGrowBagsSelect(undefined);
                    setSplitTentBatch1(false);
                    setSplitTentBatch2(false);
                  }}
                >
                  Close
                </button>
              </>
            )}
          </Modal>
        )}
      {/* Modal for Harvested/Dried Batches */}
      {harvestedOrDriedBatch && (
        <Modal
          addedOrUpdatedBatch={addedOrUpdatedBatch}
          isOpen={confirmationModalOpen}
          onRequestClose={() => {
            setConfirmationModalOpen(false);
            setAddingNewGrowBags(false);
            setModalAddingText(false);
            setHarvestedOrDriedBatch(false);
          }}
          contentLabel="Growth Added Confirmation"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>
            {modalAddingText
              ? "Harvested Batch Added Successfully"
              : "Harvested Batch Updated Successfully"}
          </h2>
          <p>
            You have successfully {modalAddingText ? "added" : "updated"} this
            batch with the following information:
          </p>
          <ul>
            <li style={boldIfChanged("batch_id_number")}>
              Harvest Batch ID: {addedOrUpdatedBatch.batch_id_number}
            </li>
            <li style={boldIfChanged("harvest_date")}>
              Harvest Date: {formatDate(addedOrUpdatedBatch.harvest_date)}
            </li>
            <li>
              <React.Fragment>
                <span style={boldIfChanged("number_of_bags")}>
                  {addedOrUpdatedBatch.number_of_bags}
                </span>{" "}
                {addedOrUpdatedBatch.container_type}s of{" "}
                <span style={boldIfChanged("mushroom_type")}>
                  {" "}
                  {addedOrUpdatedBatch.mushroom_type}
                </span>{" "}
                --{" "}
                <span style={boldIfChanged("current_status")}>
                  {addedOrUpdatedBatch.current_status}
                </span>
              </React.Fragment>
            </li>

            <li style={boldIfChanged("date_incubation_began")}>
              Inoculation Date:{" "}
              {formatDate(addedOrUpdatedBatch.date_incubation_began)}
            </li>
            <li style={boldIfChanged("fresh_grams_yielded")}>
              Fresh Grams Yielded: {addedOrUpdatedBatch.fresh_grams_yielded}g
            </li>
            {addedOrUpdatedBatch.toggle_yield_grades ? (
              <>
                <li style={boldIfChanged("grade_a_fresh_grams")}>
                  Fresh Grade A: {addedOrUpdatedBatch.grade_a_fresh_grams}g
                </li>
                <li style={boldIfChanged("grade_b_fresh_grams")}>
                  Fresh Grade B: {addedOrUpdatedBatch.grade_b_fresh_grams}g
                </li>
                <li style={boldIfChanged("grade_c_fresh_grams")}>
                  Fresh Grade C: {addedOrUpdatedBatch.grade_c_fresh_grams}g
                </li>
              </>
            ) : null}
            {addedOrUpdatedBatch.toggle_weigh_substrate ? (
              <li style={boldIfChanged("substrate_weight_grams")}>
                Substrate Weight: {addedOrUpdatedBatch.substrate_weight_grams}g
              </li>
            ) : null}
            {addedOrUpdatedBatch.post_harvest_note ? (
              <li style={boldIfChanged("post_harvest_note")}>
                Post-Harvest Note: {addedOrUpdatedBatch.post_harvest_note}
              </li>
            ) : null}
            <li style={boldIfChanged("culture_used")}>
              Culture Used: {addedOrUpdatedBatch.culture_used}
            </li>
            <li style={boldIfChanged("substrate_used")}>
              Substrate Used: {addedOrUpdatedBatch.substrate_used}
            </li>
            <li style={boldIfChanged("avg_ml_culture_per_bag")}>
              Avg mL of Culture per {addedOrUpdatedBatch.container_type}:{" "}
              {addedOrUpdatedBatch.avg_ml_culture_per_bag} mL
            </li>
            {addedOrUpdatedBatch.pre_incubation_note ? (
              <li style={boldIfChanged("pre_incubation_note")}>
                Pre-Incubation Note: {addedOrUpdatedBatch.pre_incubation_note}
              </li>
            ) : null}
            {addedOrUpdatedBatch.current_status === "Growing" ? (
              <>
                <li style={boldIfChanged("date_growing_began")}>
                  Growing Began Date:{" "}
                  {formatDate(addedOrUpdatedBatch.date_growing_began)}
                </li>
                <li style={boldIfChanged("tent_number")}>
                  Tent Number: {addedOrUpdatedBatch.tent_number}
                </li>
                {addedOrUpdatedBatch.pre_growth_note ? (
                  <li style={boldIfChanged("pre_growth_note")}>
                    Pre-Growth Note: {addedOrUpdatedBatch.pre_growth_note}
                  </li>
                ) : null}
              </>
            ) : (
              <>
                <li style={boldIfChanged("date_growing_began")}>
                  Growing Began Date:{" "}
                  {formatDate(addedOrUpdatedBatch.date_growing_began)}
                </li>
                <li style={boldIfChanged("tent_number")}>
                  Tent Number: {addedOrUpdatedBatch.tent_number}
                </li>
                {addedOrUpdatedBatch.pre_growth_note ? (
                  <li style={boldIfChanged("pre_growth_note")}>
                    Pre-Growth Note: {addedOrUpdatedBatch.pre_growth_note}
                  </li>
                ) : null}
                {addedOrUpdatedBatch.current_status === "Dried" ? (
                  <>
                    <li style={boldIfChanged("dried_type")}>
                      Dried Type: {addedOrUpdatedBatch.dried_type}
                    </li>
                    <li style={boldIfChanged("dried_type")}>
                      Dried Grams Yielded:{" "}
                      {addedOrUpdatedBatch.dried_grams_yielded}g --{" "}
                      {computeRetentionPercentage(
                        addedOrUpdatedBatch.fresh_grams_yielded,
                        addedOrUpdatedBatch.dried_grams_yielded
                      )}
                      % retention
                    </li>
                    {addedOrUpdatedBatch.dried_substrate_weight_grams ? (
                      <li style={boldIfChanged("dried_substrate_weight_grams")}>
                        Dried Substrate Weight:{" "}
                        {addedOrUpdatedBatch.dried_substrate_weight_grams}g --{" "}
                        {computeRetentionPercentage(
                          addedOrUpdatedBatch.substrate_weight_grams,
                          addedOrUpdatedBatch.dried_substrate_weight_grams
                        )}
                        % retention
                      </li>
                    ) : null}

                    {addedOrUpdatedBatch.toggle_yield_grades ? (
                      <>
                        <li style={boldIfChanged("grade_a_dried_grams")}>
                          Dried Grade A:{" "}
                          {addedOrUpdatedBatch.grade_a_dried_grams}g --{" "}
                          {computeRetentionPercentage(
                            addedOrUpdatedBatch.grade_a_fresh_grams,
                            addedOrUpdatedBatch.grade_a_dried_grams
                          )}
                          % retention
                        </li>
                        <li style={boldIfChanged("grade_b_dried_grams")}>
                          Dried Grade B:{" "}
                          {addedOrUpdatedBatch.grade_b_dried_grams}g --{" "}
                          {computeRetentionPercentage(
                            addedOrUpdatedBatch.grade_b_fresh_grams,
                            addedOrUpdatedBatch.grade_b_dried_grams
                          )}
                          % retention
                        </li>
                        <li style={boldIfChanged("grade_c_dried_grams")}>
                          Dried Grade C:{" "}
                          {addedOrUpdatedBatch.grade_c_dried_grams}g --{" "}
                          {computeRetentionPercentage(
                            addedOrUpdatedBatch.grade_c_fresh_grams,
                            addedOrUpdatedBatch.grade_c_dried_grams
                          )}
                          % retention
                        </li>
                      </>
                    ) : null}
                  </>
                ) : null}

                {addedOrUpdatedBatch.post_dried_note ? (
                  <li style={boldIfChanged("post_dried_note")}>
                    Post-Dried Note: {addedOrUpdatedBatch.post_dried_note}
                  </li>
                ) : null}
              </>
            )}
          </ul>
          {addedOrUpdatedBatch.current_status === "Dried" && (
            <div>
              <h4>
                Would you like to add these Dried{" "}
                {addedOrUpdatedBatch.mushroom_type} to inventory?
              </h4>
              <button
                onClick={() => {
                  submitInventoryData(addedOrUpdatedBatch);
                  setConfirmationModalOpen(false);
                  setModalAddingText(false);
                }}
              >
                Yes - everything looks good!
              </button>
              <button
                onClick={() => {
                  setConfirmationModalOpen(false);
                  setModalAddingText(false);
                }}
              >
                No - I there is a mistake here I need to edit
              </button>
            </div>
          )}
          {addedOrUpdatedBatch.current_status !== "Dried" && (
            <button
              onClick={() => {
                setConfirmationModalOpen(false);
                setModalAddingText(false);
              }}
            >
              Close
            </button>
          )}
        </Modal>
      )}
      {inventoryTransferSuccessModal && (
        <Modal
          addedOrUpdatedBatch={addedOrUpdatedBatch}
          isOpen={inventoryTransferSuccessModal}
          onRequestClose={() => {
            setConfirmationModalOpen(false);
            setAddingNewGrowBags(false);
            setModalAddingText(false);
            setHarvestedOrDriedBatch(false);
            setInventoryTransferSuccessModal(false);
          }}
          contentLabel="Inventory Transfer Success Modal"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>
            Batch Id: {addedOrUpdatedBatch.batch_id_number} Successfully sent to
            Inventory
          </h2>
          <p>The following has been added to your inventory:</p>
          <ul>
            {addedOrUpdatedBatch.current_status === "Harvested" ? (
              <>
                <li style={boldIfChanged("fresh_grams_yielded")}>
                  {addedOrUpdatedBatch.fresh_grams_yielded}g Fresh{" "}
                  {addedOrUpdatedBatch.mushroom_type} Fruiting Bodies
                </li>
                <li>Strain Used: {addedOrUpdatedBatch.culture_used}</li>
                <li>
                  Substrate Recipe Used: {addedOrUpdatedBatch.substrate_used}
                </li>
                {addedOrUpdatedBatch.toggle_yield_grades ? (
                  <>
                    <li style={boldIfChanged("grade_a_fresh_grams")}>
                      {addedOrUpdatedBatch.grade_a_fresh_grams}g Grade A
                    </li>
                    <li style={boldIfChanged("grade_b_fresh_grams")}>
                      {addedOrUpdatedBatch.grade_b_fresh_grams}g Grade B
                    </li>
                    <li style={boldIfChanged("grade_c_fresh_grams")}>
                      {addedOrUpdatedBatch.grade_c_fresh_grams}g Grade C
                    </li>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <li style={boldIfChanged("dried_grams_yielded")}>
                  {addedOrUpdatedBatch.dried_grams_yielded}g{" "}
                  {addedOrUpdatedBatch.dried_type} Dried{" "}
                  {addedOrUpdatedBatch.mushroom_type} Fruiting Bodies
                </li>
                <li>Strain Used: {addedOrUpdatedBatch.culture_used}</li>
                <li>
                  Substrate Recipe Used: {addedOrUpdatedBatch.substrate_used}
                </li>
                <li style={boldIfChanged("dried_type")}>
                  Dried Type: {addedOrUpdatedBatch.dried_type}
                </li>
                {addedOrUpdatedBatch.toggle_yield_grades ? (
                  <>
                    <li style={boldIfChanged("grade_a_dried_grams")}>
                      {addedOrUpdatedBatch.grade_a_dried_grams}g Grade A
                    </li>
                    <li style={boldIfChanged("grade_b_dried_grams")}>
                      {addedOrUpdatedBatch.grade_b_dried_grams}g Grade B
                    </li>
                    <li style={boldIfChanged("grade_c_dried_grams")}>
                      {addedOrUpdatedBatch.grade_c_dried_grams}g Grade C
                    </li>
                  </>
                ) : null}
              </>
            )}
            {addedOrUpdatedBatch.toggle_weigh_substrate ? (
              <>
                <li style={boldIfChanged("dried_substrate_weight_grams")}>
                  {addedOrUpdatedBatch.dried_substrate_weight_grams}g{" "}
                  {addedOrUpdatedBatch.dried_type} Dried{" "}
                  {addedOrUpdatedBatch.mushroom_type} substrate
                </li>
              </>
            ) : null}
          </ul>
          <button
            onClick={() => {
              setConfirmationModalOpen(false);
              setModalAddingText(false);
              setInventoryTransferSuccessModal(false);
            }}
          >
            Close
          </button>
        </Modal>
      )}
      {discardedBatchModal && (
        <Modal
          addedOrUpdatedBatch={addedOrUpdatedBatch}
          isOpen={discardedBatchModal}
          onRequestClose={() => {
            setConfirmationModalOpen(false);
            setAddingNewGrowBags(false);
            setModalAddingText(false);
            setHarvestedOrDriedBatch(false);
            setInventoryTransferSuccessModal(false);
            setDiscardedBatchModal(false);
          }}
          contentLabel="Discard Bags"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>
            You successfully discarded {addedOrUpdatedBatch.number_of_bags}{" "}
            {addedOrUpdatedBatch.number_of_bags === 1
              ? `${addedOrUpdatedBatch.container_type}`
              : `${addedOrUpdatedBatch.container_type}s`}{" "}
            from Batch ID Number:{" "}
            {addedOrUpdatedBatch.batch_id_number.replace(/\D/g, "")}
          </h2>
          <h3>Here are the discarded details:</h3>
          <ul>
            <li>Discard Date: {addedOrUpdatedBatch.discard_date}</li>
            <li>
              New Id of Discarded Batch: "{addedOrUpdatedBatch.batch_id_number}"
            </li>
            <li>
              Reason for Discarded Batch:
              {addedOrUpdatedBatch.current_status === "Contaminated"
                ? " Contamination"
                : " Other"}
            </li>
            {addedOrUpdatedBatch.discard_note && (
              <li>Note: {addedOrUpdatedBatch.discard_note}</li>
            )}
          </ul>
          <button
            onClick={() => {
              setConfirmationModalOpen(false);
              setModalAddingText(false);
              setInventoryTransferSuccessModal(false);
              setDiscardedBatchModal(false);
            }}
          >
            Close
          </button>
        </Modal>
      )}
    </>
  );
}
export default LoggedIn;
