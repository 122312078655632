import { useState, useContext, useEffect } from "react";
import { GrowBagsContext } from "./LoggedIn";
import "css/growbags.css";

export default function GrowBagsEdit({
  growBags,
  addingNewGrowBags,
  onGrowBagsCancelAdd,
  mushrooms,
  selectedGrowBagsId,
  cultures,
  substrates,
  containerTypes,
  batchID,
  isNewGrowBag,
  setSplitTentBatch1,
  setSplitTentBatch2,
  currentUser,
}) {
  const [selectedMushroomType, setSelectedMushroomType] = useState(
    growBags?.mushroom_type || ""
  );

  const [growBagsState, setGrowBagsState] = useState(
    growBags?.current_status || "Incubating"
  );
  const [previousGrowBagsState, setPreviousGrowBagsState] = useState(
    growBags?.current_status || "Incubating"
  );
  const [growBagsBatch1, setGrowBagsBatch1] = useState(0);
  const [batch1TentNumber, setBatch1TentNumber] = useState("");
  const [batch2TentNumber, setBatch2TentNumber] = useState("");
  const [preGrowthNoteBatch1, setPregrowthNoteBatch1] = useState("");
  const [preGrowthNoteBatch2, setPregrowthNoteBatch2] = useState("");
  const [cultureUsedId, setCultureUsedId] = useState(null);
  const [selectedCulture, setSelectedCulture] = useState(null);

  // variable is added so growBags is only updated upon clicking update button
  const [updatedGrowBags, setUpdatedGrowBags] = useState({
    ...(growBags || {
      batch_id_number: isNewGrowBag ? batchID : growBags.batch_id_number,
      mushroom_type: "ENTER MUSHROOM TYPE",
      date_incubation_began: getCurrentDateString(),
      grow_bags_note: "",
      culture_used: "",
      culture_name: "",
      current_status: "Incubating",
      substrate_used: "",
      bag_type: "",
      container_type: "Bag",
      number_of_harvests: isNewGrowBag ? 0 : growBags.number_of_harvests,
      created_by: currentUser,
    }),
    date_incubation_began:
      growBags && growBags.date_incubation_began
        ? growBags.date_incubation_began.slice(0, 10)
        : getCurrentDateString(),
    date_growing_began:
      growBags && growBags.date_growing_began
        ? growBags.date_growing_began.slice(0, 10)
        : isNewGrowBag
        ? getCurrentDateString()
        : "",
    harvest_date:
      growBags && growBags.harvest_date
        ? growBags.harvest_date.slice(0, 10)
        : getCurrentDateString(),
    drying_date:
      growBags && growBags.drying_date ? growBags.drying_date.slice(0, 10) : "",
  });
  const { handleGrowBagsChange, handleGrowBagsSelect, handleGrowBagsDelete } =
    useContext(GrowBagsContext);

  const [batchIdNumError, setBatchIdNumError] = useState("");
  const [cultureSelectError, setCultureSelectError] = useState("");
  const [substrateSelectError, setSubstrateSelectError] = useState("");
  const [bagTypeError, setBagTypeError] = useState("");
  const [numBagsInBatchError, setNumBagsInBatchError] = useState("");
  const [numBagsInBatchChangeError, setNumBagsInBatchChangeError] =
    useState("");
  const [mlCulturePerBagError, setMlCulturePerBagError] = useState("");
  const [multipleTents, setMultipleTents] = useState(false);

  // Filters cultures by not spend and selected mushroom type to display in dropdown
  const filteredCultures = cultures.filter(
    (culture) =>
      culture.mushroom_type === selectedMushroomType && !culture.is_finished
  );

  // Filters substrates by selected mushroom type to display in dropdown
  const filteredSubstrateRecipes = substrates.filter(
    (recipe) => recipe.mushroom_type === selectedMushroomType
  );

  // Toggle if user would like to add a section for specific grades of yield
  const [showYieldGrades, setShowYieldGrades] = useState(
    updatedGrowBags.toggle_yield_grades
  );

  useEffect(() => {
    if (growBags && growBags.culture_used) {
      const culture = cultures.find((c) => c.id === growBags.culture_used);
      if (culture) {
        setSelectedCulture(culture);
        setCultureUsedId(culture.id);
      }
    }
  }, [growBags, cultures]);

  useEffect(() => {
    setPreviousGrowBagsState(growBagsState);
  }, [growBagsState]);

  useEffect(() => {
    if (growBagsState === "Incubating") {
      setUpdatedGrowBags((prev) => ({
        ...prev,
        date_growing_began: "",
        harvest_date: "",
      }));
    } else if (growBagsState === "Growing") {
      setUpdatedGrowBags((prev) => ({ ...prev, harvest_date: "" }));
    }
  }, [growBagsState]);

  useEffect(() => {
    if (growBagsState === "Growing" && previousGrowBagsState !== "Growing") {
      const today = new Date().toISOString().slice(0, 10);
      setUpdatedGrowBags((prev) => ({ ...prev, date_growing_began: today }));
    }
  }, [growBagsState, previousGrowBagsState]);

  function updateSelectedCulture(cultureId) {
    const culture = cultures.find((c) => c.id === cultureId);
    setSelectedCulture(culture);
  }

  function validateForm() {
    let isValid = true;

    if (
      isNaN(parseInt(updatedGrowBags.batch_id_number)) ||
      updatedGrowBags.batch_id_number === 0
    ) {
      setBatchIdNumError("You must enter a batch ID number.");
      isValid = false;
    } else {
      setBatchIdNumError("");
    }

    if (
      updatedGrowBags.avg_ml_culture_per_bag === "" ||
      isNaN(parseInt(updatedGrowBags.avg_ml_culture_per_bag))
    ) {
      setMlCulturePerBagError("Average ml per bag cannot be blank.");
      isValid = false;
    } else {
      setMlCulturePerBagError("");
    }

    if (!updatedGrowBags.culture_used) {
      setCultureSelectError("You must select a culture.");
      isValid = false;
    } else {
      setCultureSelectError("");
    }

    if (updatedGrowBags.substrate_used.trim() === "") {
      setSubstrateSelectError("You must select a substrate.");
      isValid = false;
    } else {
      setSubstrateSelectError("");
    }

    if (
      updatedGrowBags.bag_type.trim() === "" &&
      updatedGrowBags.container_type === "Bag"
    ) {
      setBagTypeError("You must select a bag type.");
      isValid = false;
    } else {
      setBagTypeError("");
    }

    if (
      updatedGrowBags.number_of_bags === "" ||
      isNaN(parseInt(updatedGrowBags.number_of_bags))
    ) {
      setNumBagsInBatchError(
        "You must enter a valid number of bags in the batch."
      );
      isValid = false;
    } else {
      setNumBagsInBatchError("");
    }

    if (
      updatedGrowBags.number_of_harvests > 0 &&
      updatedGrowBags.number_of_bags !== updatedGrowBags.original_number_of_bags
    ) {
      setNumBagsInBatchChangeError(
        "You cannot change number of bags in batch after a harvest."
      );
      isValid = false;
    } else {
      setNumBagsInBatchChangeError("");
    }

    return isValid;
  }

  function handleChange(changes) {
    console.log("Here are the changes:", changes);
    // Submit culture.id rather than the entire object
    if (changes.culture_used) {
      setCultureUsedId(changes.culture_used.id);
      // console.log("HERE IT IS!:", changes.culture_used);
    }
    // If container_type is being updated and it's not "Bag", set bag_type to blank
    if (changes.container_type && changes.container_type !== "Bag") {
      changes.bag_type = "";
    }
    if (addingNewGrowBags && changes.number_of_bags) {
      setUpdatedGrowBags((prev) => ({
        ...prev,
        ...changes,
        original_number_of_bags: changes.number_of_bags,
        // Set default value for avg_ml_culture_per_bag
        avg_ml_culture_per_bag: prev.avg_ml_culture_per_bag || 55,
      }));
      console.log("Updated Grow Bags: ", updatedGrowBags);
    } else {
      setUpdatedGrowBags((prevState) => ({ ...updatedGrowBags, ...changes }));
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Check if number_of_harvests is 0
    if (updatedGrowBags.number_of_harvests === 0) {
      updatedGrowBags.original_number_of_bags = updatedGrowBags.number_of_bags;
    }

    if (multipleTents) {
      const batch1 = {
        ...updatedGrowBags,
        batch_id_number: `${updatedGrowBags.batch_id_number}-1`,
        number_of_bags: growBagsBatch1,
        tent_number: batch1TentNumber,
        pre_growth_note: preGrowthNoteBatch1,
        multiple_tent_growth: true,
      };
      const batch2 = {
        ...updatedGrowBags,
        id: "",
        batch_id_number: `${updatedGrowBags.batch_id_number}-2`,
        number_of_bags: updatedGrowBags.number_of_bags - growBagsBatch1,
        tent_number: batch2TentNumber,
        pre_growth_note: preGrowthNoteBatch2,
        multiple_tent_growth: true,
      };
      handleGrowBagsChange(growBags.id, batch1, updateSelectedCulture);
      handleGrowBagsChange(null, batch2, updateSelectedCulture);
      setSplitTentBatch1(batch1);
      setSplitTentBatch2(batch2);
      handleGrowBagsSelect(undefined);
    } else {
      handleGrowBagsChange(growBags.id, updatedGrowBags, updateSelectedCulture);
    }
    handleGrowBagsSelect(undefined);
  }

  // Sets today's date as default
  function getCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async function handleAdd(e) {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (multipleTents) {
      const batch1 = {
        ...updatedGrowBags,
        batch_id_number: `${updatedGrowBags.batch_id_number}-1`,
        number_of_bags: growBagsBatch1,
        tent_number: batch1TentNumber,
        preGrowthNote: preGrowthNoteBatch1,
        multiple_tent_growth: true,
      };
      const batch2 = {
        ...updatedGrowBags,
        batch_id_number: `${updatedGrowBags.batch_id_number}-2`,
        number_of_bags: updatedGrowBags.number_of_bags - growBagsBatch1,
        tent_number: batch2TentNumber,
        preGrowthNote: preGrowthNoteBatch2,
        multiple_tent_growth: true,
      };
      await handleGrowBagsChange(null, batch1, updateSelectedCulture);
      await handleGrowBagsChange(null, batch2, updateSelectedCulture);
    } else {
      await handleGrowBagsChange(null, updatedGrowBags, updateSelectedCulture);
    }

    setUpdatedGrowBags({
      date_incubation_began: "",
    });
    // Add this so it closes after hitting add
    handleGrowBagsSelect(undefined);
  }

  async function handleCancel() {
    if (addingNewGrowBags) {
      onGrowBagsCancelAdd();
    } else {
      handleGrowBagsSelect(undefined);
    }
  }

  return (
    <div key={selectedGrowBagsId || "add"} className="growbags-edit">
      <div className="growbags-edit__remove-growbags-container">
        <button className="growbags-close-btn" onClick={handleCancel}>
          &times;
        </button>
      </div>
      <form onSubmit={addingNewGrowBags ? handleAdd : handleSubmit}>
        <div className="growbags-edit__details--grid">
          <br />
          <label htmlFor="batchID" className="growbags-edit__label">
            Batch ID Number:
          </label>
          <p className="batchIdNumber">{updatedGrowBags.batch_id_number}</p>
          {/* <input
            type="text"
            name="batchID"
            id="batchID"
            value={updatedGrowBags.batch_id_number}
            onChange={(e) => handleChange({ batch_id_number: e.target.value })}
          /> */}
          {batchIdNumError && (
            <p className="error-message">{batchIdNumError}</p>
          )}
          <br />
          <label htmlFor="mushroomType" className="growbags-edit__label">
            Mushroom Type
          </label>
          <select
            name="mushroomType"
            id="mushroomType"
            value={selectedMushroomType}
            onChange={(e) => {
              setSelectedMushroomType(e.target.value);
              handleChange({ mushroom_type: e.target.value });
            }}
          >
            <option value={selectedMushroomType.mushroom_name}>
              Select a Mushroom Type
            </option>
            {mushrooms.map((mushroom) => (
              <option
                key={mushroom.mushroom_name}
                value={mushroom.mushroom_name}
              >
                {mushroom.mushroom_name}
              </option>
            ))}
          </select>
          <br />
          {selectedMushroomType && (
            <div>
              <label htmlFor="growBagsState" className="growbags-edit__label">
                Grow Bags State:
              </label>
              <select
                name="growBagsState"
                id="growBagsState"
                value={updatedGrowBags.current_status}
                onChange={(e) => {
                  setGrowBagsState(e.target.value);
                  handleChange({ current_status: e.target.value });
                }}
              >
                <option value="Incubating">Incubating</option>
                <option value="Growing">Growing</option>
              </select>
              <br />
              <label htmlFor="cultureUsed" className="growbags-edit__label">
                Culture:
              </label>
              <select
                name="cultureUsed"
                id="cultureUsed"
                value={cultureUsedId}
                onChange={(e) => {
                  const selectedCultureId = e.target.value;
                  const selectedCulture = filteredCultures.find(
                    (culture) => culture.id === selectedCultureId
                  );
                  setSelectedCulture(selectedCulture);
                  console.log("Culture", selectedCulture);
                  setCultureUsedId(selectedCultureId);
                  console.log("Culture ID", selectedCultureId);
                  console.log(
                    "Culture Name Used",
                    selectedCulture.culture_name
                  );
                  handleChange({
                    culture_used: selectedCulture,
                    culture_name: selectedCulture.culture_name,
                  });
                }}
              >
                <option value="">
                  {addingNewGrowBags && !selectedCulture
                    ? "Select a Culture"
                    : selectedCulture?.culture_name}
                </option>
                {filteredCultures
                  .sort((a, b) => a.culture_name.localeCompare(b.culture_name))
                  .map((culture) => (
                    <option key={culture.id} value={culture.id}>
                      {culture.culture_name}
                    </option>
                  ))}
              </select>
              {cultureSelectError && (
                <p className="error-message">{cultureSelectError}</p>
              )}
              <br />
              <label htmlFor="substrateUsed" className="growbags-edit__label">
                Substrate Recipe:
              </label>
              <select
                name="substrateUsed"
                id="substrateUsed"
                value={updatedGrowBags.substrate_used}
                onChange={(e) =>
                  handleChange({ substrate_used: e.target.value })
                }
              >
                <option value="">Select a Substrate Recipe</option>
                {filteredSubstrateRecipes.map((recipe) => (
                  <option key={recipe.id} value={recipe.recipe_name}>
                    {recipe.recipe_name}
                  </option>
                ))}
              </select>
              {substrateSelectError && (
                <p className="error-message">{substrateSelectError}</p>
              )}
              <br />
              <label htmlFor="bagType" className="growbags-edit__label">
                Container Type:
              </label>
              <select
                name="containerType"
                id="containerType"
                value={updatedGrowBags.container_type}
                onChange={(e) =>
                  handleChange({ container_type: e.target.value })
                }
              >
                <option value="Bag">Bag</option>
                {containerTypes.map((container) =>
                  container.container_type === "Bag" ? null : (
                    <option key={container.id} value={container.container_type}>
                      {container.container_type}
                    </option>
                  )
                )}
              </select>
              <br />
              {updatedGrowBags.container_type === "Bag" && (
                <>
                  <label htmlFor="bagType" className="growbags-edit__label">
                    Bag Type:
                  </label>
                  <select
                    name="bagType"
                    id="bagType"
                    value={updatedGrowBags.bag_type}
                    onChange={(e) => handleChange({ bag_type: e.target.value })}
                  >
                    <option value="">Select Bag Type</option>
                    {containerTypes
                      .filter((bag) => bag.bag_type)
                      .map((bag) => (
                        <option key={bag.id} value={bag.bag_type}>
                          {bag.bag_type}
                        </option>
                      ))}
                  </select>
                </>
              )}
              {bagTypeError && updatedGrowBags.container_type === "Bag" && (
                <p className="error-message">{bagTypeError}</p>
              )}
              <br />
              <label htmlFor="numberOfBags" className="growbags-edit__label">
                Number of {updatedGrowBags.container_type}s in Batch:
              </label>
              <input
                type="number"
                name="numberOfBags"
                id="numberOfBags"
                min="1"
                value={updatedGrowBags.number_of_bags || ""}
                onChange={(e) =>
                  handleChange({ number_of_bags: parseInt(e.target.value, 10) })
                }
              />
              {numBagsInBatchError && (
                <p className="error-message">{numBagsInBatchError}</p>
              )}
              {numBagsInBatchChangeError && (
                <p className="error-message">{numBagsInBatchChangeError}</p>
              )}
              <br />
              <label
                htmlFor="avgMlCulturePerBag"
                className="growbags-edit__label"
              >
                Average ml Culture per {updatedGrowBags.container_type}:
              </label>
              <input
                type="number"
                name="avgMlCulturePerBag"
                id="avgMlCulturePerBag"
                min="0"
                step="0.1"
                placeholder="55" // placeholder is set to 55
                value={updatedGrowBags.avg_ml_culture_per_bag || ""} // value can be an empty string
                onChange={(e) => {
                  const value = e.target.value
                    ? parseFloat(e.target.value)
                    : "";
                  handleChange({ avg_ml_culture_per_bag: value });
                }}
              />
              {mlCulturePerBagError && (
                <p className="error-message">{mlCulturePerBagError}</p>
              )}
              <br />
              <label htmlFor="inoculationDate" className="growbags-edit__label">
                Inoculation Date:
              </label>
              <input
                type="date"
                name="inoculationDate"
                id="inoculationDate"
                value={updatedGrowBags.date_incubation_began}
                onChange={(e) =>
                  handleChange({ date_incubation_began: e.target.value })
                }
              />
              <br />
              <label htmlFor="growBagsNote" className="growbags-edit__label">
                Pre-Incubation Note:
              </label>
              <textarea
                name="preIncubationNote"
                id="preIncubationNote"
                className="growbags-edit__textarea"
                value={updatedGrowBags.pre_incubation_note}
                onChange={(e) =>
                  handleChange({ pre_incubation_note: e.target.value })
                }
              />
              <br />
              <br />
              {(growBagsState === "Growing" ||
                growBagsState === "Harvested" ||
                growBagsState === "Dried") && (
                <>
                  <label
                    htmlFor="dateGrowingBegan"
                    className="growbags-edit__label"
                  >
                    Date Growing Began:
                  </label>
                  <input
                    type="date"
                    name="dateGrowingBegan"
                    id="dateGrowingBegan"
                    value={updatedGrowBags.date_growing_began || ""}
                    onChange={(e) =>
                      handleChange({ date_growing_began: e.target.value })
                    }
                  />
                  <br />
                  {!updatedGrowBags.multiple_tent_growth && (
                    <div className="multipleTents-text">
                      <label className="growbags-edit__label">
                        Sending to multiple tents?
                      </label>
                      <div className="switch">
                        <input
                          type="checkbox"
                          id="multipleTents"
                          checked={multipleTents}
                          onChange={(e) => {
                            setMultipleTents(e.target.checked);
                          }}
                        />
                        <span
                          onClick={(e) => {
                            setMultipleTents((prevState) => !prevState);
                          }}
                          className="slider round"
                        ></span>
                      </div>
                    </div>
                  )}
                  <br />
                  {!multipleTents && (
                    <>
                      <label
                        htmlFor="tentNumber"
                        className="growbags-edit__label"
                      >
                        Tent Number:
                      </label>
                      <input
                        type="number"
                        name="tentNumber"
                        id="tentNumber"
                        min="1"
                        value={updatedGrowBags.tent_number || ""}
                        onChange={(e) =>
                          handleChange({
                            tent_number: parseInt(e.target.value, 10),
                          })
                        }
                      />
                      <br />
                      <label
                        htmlFor="preGrowthNote"
                        className="growbags-edit__label"
                      >
                        Pre-Growth Note:
                      </label>
                      <textarea
                        name="preGrowthNote"
                        id="preGrowthNote"
                        className="growbags-edit__textarea"
                        value={updatedGrowBags.pre_growth_note || ""}
                        onChange={(e) =>
                          handleChange({ pre_growth_note: e.target.value })
                        }
                      />
                    </>
                  )}
                  {multipleTents && (
                    <>
                      <p className="error-message">
                        WHEN YOU SPLIT THE BATCH, THIS ACTION CANNOT BE UNDONE!
                      </p>
                      <label htmlFor="number-of-bags">
                        Number of {updatedGrowBags.container_type}s in First
                        Batch:
                        <select
                          id="number-of-bags"
                          value={growBagsBatch1}
                          onChange={(e) => {
                            setGrowBagsBatch1(Number(e.target.value));
                          }}
                        >
                          {Array.from(
                            { length: updatedGrowBags.number_of_bags + 1 },
                            (_, i) => (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            )
                          )}
                        </select>
                      </label>
                      <br />
                      <label
                        htmlFor="tentNumber"
                        className="growbags-edit__label"
                      >
                        Tent Number for First Batch:
                      </label>
                      <input
                        type="number"
                        name="tentNumber1"
                        id="tentNumber1"
                        min="1"
                        value={batch1TentNumber}
                        onChange={(e) =>
                          setBatch1TentNumber(parseInt(e.target.value, 10))
                        }
                      />
                      <br />
                      <label
                        htmlFor="preGrowthNote"
                        className="growbags-edit__label"
                      >
                        Pre-Growth Note for First Batch:
                      </label>
                      <textarea
                        name="preGrowthNote"
                        id="preGrowthNote"
                        className="growbags-edit__textarea"
                        value={preGrowthNoteBatch1}
                        onChange={(e) => setPregrowthNoteBatch1(e.target.value)}
                      />
                      <br />
                      <br />
                      <label htmlFor="number-of-bags">
                        Number of {updatedGrowBags.container_type}s in Second
                        Batch:
                        <input
                          id="number-of-bags"
                          type="number"
                          value={
                            updatedGrowBags.number_of_bags - growBagsBatch1
                          }
                          readOnly
                        />
                      </label>
                      <br />
                      <p>This number is automatically calculated</p>
                      <p>
                        number of bags in the original batch - the number
                        selected from the "Number of Bags in First Batch"
                        dropdown
                      </p>

                      <br />
                      <label
                        htmlFor="tentNumber"
                        className="growbags-edit__label"
                      >
                        Tent Number for Second Batch:
                      </label>
                      <br />
                      <input
                        type="number"
                        name="tentNumber"
                        id="tentNumber"
                        min="1"
                        value={batch2TentNumber}
                        onChange={(e) =>
                          setBatch2TentNumber(parseInt(e.target.value, 10))
                        }
                      />
                      <br />
                      <label
                        htmlFor="preGrowthNote"
                        className="growbags-edit__label"
                      >
                        Pre-Growth Note for Second Batch:
                      </label>
                      <textarea
                        name="preGrowthNote"
                        id="preGrowthNote"
                        className="growbags-edit__textarea"
                        value={preGrowthNoteBatch2}
                        onChange={(e) => setPregrowthNoteBatch2(e.target.value)}
                      />
                    </>
                  )}
                  <br />
                </>
              )}
              {(growBagsState === "Harvested" || growBagsState === "Dried") && (
                <>
                  <label htmlFor="harvestDate" className="growbags-edit__label">
                    Harvest Date:
                  </label>
                  <input
                    type="date"
                    name="harvestDate"
                    id="harvestDate"
                    value={updatedGrowBags.harvest_date || ""}
                    onChange={(e) =>
                      handleChange({ harvest_date: e.target.value })
                    }
                  />
                  <br />
                  <label
                    htmlFor="freshGramsYielded"
                    className="growbags-edit__label"
                  >
                    Fresh Grams Yielded:
                  </label>
                  <input
                    type="number"
                    name="freshGramsYielded"
                    id="freshGramsYielded"
                    min="0"
                    step="0.1"
                    value={updatedGrowBags.fresh_grams_yielded || ""}
                    onChange={(e) =>
                      handleChange({
                        fresh_grams_yielded: parseFloat(e.target.value),
                      })
                    }
                  />
                  <br />
                  <div className="toggle-container">
                    <label
                      htmlFor="toggleYieldGrades"
                      className="growbags-edit__label"
                    >
                      Enter A, B, C grades of yield?
                    </label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={updatedGrowBags.toggle_yield_grades || false}
                        id="toggleYieldGrades"
                        name="toggleYieldGrades"
                        value={updatedGrowBags.toggle_yield_grades}
                        onChange={() => {
                          const newValue = !showYieldGrades;
                          setShowYieldGrades(newValue);
                          handleChange({ toggle_yield_grades: newValue });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  {showYieldGrades &&
                    (growBagsState === "Harvested" ||
                      growBagsState === "Dried") && (
                      <div className="yield-grade-input-container">
                        <label
                          htmlFor="freshAGradeYield"
                          className="growbags-edit__label"
                        >
                          Grams of A Grade Fresh Yield:
                        </label>
                        <input
                          type="number"
                          name="freshAGradeYield"
                          id="freshAGradeYield"
                          min="0"
                          step="0.1"
                          value={updatedGrowBags.grade_a_fresh_grams || ""}
                          onChange={(e) =>
                            handleChange({
                              grade_a_fresh_grams: parseFloat(e.target.value),
                            })
                          }
                        />
                        <label
                          htmlFor="freshBGradeYield"
                          className="growbags-edit__label"
                        >
                          Grams of B Grade Fresh Yield:
                        </label>
                        <input
                          type="number"
                          name="freshBGradeYield"
                          id="freshBGradeYield"
                          min="0"
                          step="0.1"
                          value={updatedGrowBags.grade_b_fresh_grams || ""}
                          onChange={(e) =>
                            handleChange({
                              grade_b_fresh_grams: parseFloat(e.target.value),
                            })
                          }
                        />
                        <label
                          htmlFor="freshCGradeYield"
                          className="growbags-edit__label"
                        >
                          Grams of C Grade Fresh Yield:
                        </label>
                        <input
                          type="number"
                          name="freshCGradeYield"
                          id="freshCGradeYield"
                          min="0"
                          step="0.1"
                          value={updatedGrowBags.grade_c_fresh_grams || ""}
                          onChange={(e) =>
                            handleChange({
                              grade_c_fresh_grams: parseFloat(e.target.value),
                            })
                          }
                        />
                      </div>
                    )}
                  <label
                    htmlFor="postHarvestNote"
                    className="growbags-edit__label"
                  >
                    Post-Harvest Note:
                  </label>
                  <textarea
                    name="postHarvestNote"
                    id="postHarvestNote"
                    className="growbags-edit__textarea"
                    value={updatedGrowBags.post_harvest_note || ""}
                    onChange={(e) =>
                      handleChange({ post_harvest_note: e.target.value })
                    }
                  />
                  <br />
                </>
              )}
              {growBagsState === "Dried" && (
                <>
                  <label htmlFor="dryingDate" className="growbags-edit__label">
                    Dried Date:
                  </label>
                  <input
                    type="date"
                    name="dryingDate"
                    id="dryingDate"
                    value={updatedGrowBags.dried_date || ""}
                    onChange={(e) =>
                      handleChange({ dried_date: e.target.value })
                    }
                  />
                  <br />
                  <label
                    htmlFor="driedGramsYielded"
                    className="growbags-edit__label"
                  >
                    Dried Grams Yielded:
                  </label>
                  <input
                    type="number"
                    name="driedGramsYielded"
                    id="driedGramsYielded"
                    min="0"
                    step="0.1"
                    value={updatedGrowBags.dried_grams_yielded || ""}
                    onChange={(e) =>
                      handleChange({
                        dried_grams_yielded: parseFloat(e.target.value),
                      })
                    }
                  />
                  <br />
                  {showYieldGrades && growBagsState === "Dried" && (
                    <div className="yield-grade-input-container">
                      <label
                        htmlFor="driedAGradeYield"
                        className="growbags-edit__label"
                      >
                        Grams of Dried A Grade Yield:
                      </label>
                      <input
                        type="number"
                        name="driedAGradeYield"
                        id="driedAGradeYield"
                        min="0"
                        step="0.1"
                        value={updatedGrowBags.grade_a_dried_grams || ""}
                        onChange={(e) =>
                          handleChange({
                            grade_a_dried_grams: parseFloat(e.target.value),
                          })
                        }
                      />
                      <label
                        htmlFor="driedBGradeYield"
                        className="growbags-edit__label"
                      >
                        Grams of Dried B Grade Yield:
                      </label>
                      <input
                        type="number"
                        name="driedBGradeYield"
                        id="driedBGradeYield"
                        min="0"
                        step="0.1"
                        value={updatedGrowBags.grade_b_dried_grams || ""}
                        onChange={(e) =>
                          handleChange({
                            grade_b_dried_grams: parseFloat(e.target.value),
                          })
                        }
                      />
                      <label
                        htmlFor="driedCGradeYield"
                        className="growbags-edit__label"
                      >
                        Grams of Dried C Grade Yield:
                      </label>
                      <input
                        type="number"
                        name="driedCGradeYield"
                        id="driedCGradeYield"
                        min="0"
                        step="0.1"
                        value={updatedGrowBags.grade_c_dried_grams || ""}
                        onChange={(e) =>
                          handleChange({
                            grade_c_dried_grams: parseFloat(e.target.value),
                          })
                        }
                      />
                    </div>
                  )}
                  <label
                    htmlFor="postDryingNote"
                    className="growbags-edit__label"
                  >
                    Post-Drying Note:
                  </label>
                  <textarea
                    name="postDryingNote"
                    id="postDryingNote"
                    className="growbags-edit__textarea"
                    value={updatedGrowBags.post_dried_note || ""}
                    onChange={(e) =>
                      handleChange({ post_dried_note: e.target.value })
                    }
                  />
                  <br />
                </>
              )}
              <button type="submit">
                {addingNewGrowBags ? "Add" : "Update"}
              </button>
            </div>
          )}
        </div>
      </form>
      {!addingNewGrowBags && (
        <button
          type="delete"
          onClick={() => handleGrowBagsDelete(growBags.id, growBags)}
        >
          Delete
        </button>
      )}
      <br />
    </div>
  );
}
