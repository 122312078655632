import React, { useState } from "react";
import "css/report-generation.css";
import ReportOutput from "./ReportOutput";

export default function ReportGeneration(props) {
  const { growBags, cultures, substrates } = props;

  // Helper function to get default date ranges
  const getDefaultDateRange = () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 1); // Subtract one month

    // Convert to YYYY-MM-DD format
    const format = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    return {
      start: format(startDate),
      end: format(endDate),
    };
  };

  const [selectedOptions, setSelectedOptions] = useState({
    cultures: false,
    selectAllCulturesBoolean: true,
    substrates: false,
    growthSection: false,
    harvestSection: false,
    notes: false,
    cultureDateRange: getDefaultDateRange(),
    growthDateRange: getDefaultDateRange(),
    harvestDateRange: getDefaultDateRange(),
    batchId: true,
    harvestBatchId: true,
    mushroomType: true,
    cultureUsed: true,
    mLCultureUsed: true,
    growthSubstrateUsed: true,
    containerType: true,
    numberOfContainers: true,
    numberOfContainersDiscarded: true,
    tentNumber: true,
    dateIncubationBegan: true,
    dateGrowingBegan: true,
    numberOfHarvests: true,
    analyticData: true,
    mushroomImage: true,
    harvestMushroomType: true,
    harvestCultureUsed: true,
    harvestSubstrateUsed: true,
    harvestContainerType: true,
    containersHarvested: true,
    harvestDate: true,
    totalFreshFruitingBodyYield: true,
    freshAGradeYield: false,
    freshBGradeYield: false,
    freshCGradeYield: false,
    totalDriedFruitingBodyYield: true,
    driedType: false,
    driedAGradeYield: false,
    driedBGradeYield: false,
    driedCGradeYield: false,
    totalFreshSubstrateYield: true,
    totalDriedSubstrateYield: true,
    preIncubationNote: true,
    preGrowthNote: true,
    postHarvestNote: true,
    postDriedNote: true,
    discardNote: true,
  });
  const [showReportOutput, setShowReportOutput] = useState(false);

  const handleOptionChange = (option, value) => {
    setSelectedOptions((prev) => {
      let updatedOptions = { ...prev, [option]: value };

      // If growthSection is false, reset its dependent values
      if (option === "growthSection" && !value) {
        const growthDependents = [
          "batchId",
          "mushroomType",
          "cultureUsed",
          "mLCultureUsed",
          "growthSubstrateUsed",
          "numberOfContainers",
          "numberOfContainersDiscarded",
          "tentNumber",
          "dateIncubationBegan",
          "dateGrowingBegan",
          "numberOfHarvests",
          "containerType",
        ];
        growthDependents.forEach((dependent) => {
          updatedOptions[dependent] = false;
        });
      }

      // If harvestSection is false, reset its dependent values
      if (option === "harvestSection" && !value) {
        const harvestDependents = [
          "harvestBatchId",
          "harvestMushroomType",
          "harvestCultureUsed",
          "harvestDate",
          "harvestSubstrateUsed",
          "harvestContainerType",
          "containersHarvested",
          "analyticData",
          "mushroomImage",
          "totalFreshFruitingBodyYield",
          "totalDriedFruitingBodyYield",
          "totalFreshSubstrateYield",
          "totalDriedSubstrateYield",
        ];
        harvestDependents.forEach((dependent) => {
          updatedOptions[dependent] = false;
        });
      }

      // If totalFreshFruitingBodyYield is false, reset its dependent values
      if (option === "totalFreshFruitingBodyYield" && !value) {
        const freshYieldDependents = [
          "freshAGradeYield",
          "freshBGradeYield",
          "freshCGradeYield",
        ];
        freshYieldDependents.forEach((dependent) => {
          updatedOptions[dependent] = false;
        });
      }

      // If totalDriedFruitingBodyYield is false, reset its dependent values
      if (option === "totalDriedFruitingBodyYield" && !value) {
        const driedYieldDependents = [
          "driedAGradeYield",
          "driedBGradeYield",
          "driedCGradeYield",
        ];
        driedYieldDependents.forEach((dependent) => {
          updatedOptions[dependent] = false;
        });
      }

      // If notes is false, reset its dependent values
      if (option === "notes" && !value) {
        const notesDependents = [
          "preIncubationNote",
          "preGrowthNote",
          "postHarvestNote",
          "postDriedNote",
          "discardNote",
        ];
        notesDependents.forEach((dependent) => {
          updatedOptions[dependent] = false;
        });
      }

      return updatedOptions;
    });
  };

  return (
    <div>
      <h2>Report Generation</h2>
      <button onClick={() => setShowReportOutput(true)}>Generate Report</button>

      {/* leave off here with the report generation button and making sure this is good */}
      <div className="reportContainer">
        <section>
          <h3>Select Report Components</h3>
          {/* Cultures Section */}

          <div>
            <label>
              <input
                type="checkbox"
                className="growthReportCheckbox"
                checked={selectedOptions.cultures}
                onChange={() =>
                  setSelectedOptions((prev) => ({
                    ...prev,
                    cultures: !prev.cultures,
                  }))
                }
              />
              Cultures
            </label>
            {selectedOptions.cultures && (
              <div>
                {/* Date range selection for cultures */}
                <div className="growthHarvestNotesSubOptions">
                  <label>
                    Show All Cultures
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.selectAllCulturesBoolean}
                      onChange={() =>
                        setSelectedOptions((prev) => ({
                          ...prev,
                          selectAllCulturesBoolean:
                            !prev.selectAllCulturesBoolean,
                        }))
                      }
                    />
                  </label>
                  <br />
                  {!selectedOptions.selectAllCulturesBoolean && (
                    <>
                      <label>
                        Culture Creation Start Date:
                        <input
                          type="date"
                          value={selectedOptions.cultureDateRange.start}
                          onChange={(e) =>
                            setSelectedOptions((prev) => ({
                              ...prev,
                              cultureDateRange: {
                                ...prev.cultureDateRange,
                                start: e.target.value,
                              },
                            }))
                          }
                        />
                      </label>
                      <br />
                      <label>
                        Culture Creation End Date:
                        <input
                          type="date"
                          value={selectedOptions.cultureDateRange.end}
                          onChange={(e) =>
                            setSelectedOptions((prev) => ({
                              ...prev,
                              cultureDateRange: {
                                ...prev.cultureDateRange,
                                end: e.target.value,
                              },
                            }))
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* Substrates Section */}
          <div>
            <label>
              <input
                type="checkbox"
                className="growthReportCheckbox"
                checked={selectedOptions.substrates}
                onChange={() =>
                  setSelectedOptions((prev) => ({
                    ...prev,
                    substrates: !prev.substrates,
                  }))
                }
              />
              Substrates
            </label>
          </div>
          {/* Growth Section */}
          <div>
            <label>
              <input
                type="checkbox"
                className="growthReportCheckbox"
                checked={selectedOptions.growthSection}
                onChange={() =>
                  handleOptionChange(
                    "growthSection",
                    !selectedOptions.growthSection
                  )
                }
              />
              Growth
            </label>
            {selectedOptions.growthSection && (
              <div>
                {/* Additional checkboxes for growth/harvest details */}
                <div className="growthHarvestNotesSubOptions">
                  <label>
                    Incubation Began Start Date:
                    <input
                      type="date"
                      value={selectedOptions.growthDateRange.start}
                      onChange={(e) =>
                        setSelectedOptions((prev) => ({
                          ...prev,
                          growthDateRange: {
                            ...prev.growthDateRange,
                            start: e.target.value,
                          },
                        }))
                      }
                    />
                  </label>
                  <br />
                  <label>
                    Incubation Began End Date:
                    <input
                      type="date"
                      value={selectedOptions.growthDateRange.end}
                      onChange={(e) =>
                        setSelectedOptions((prev) => ({
                          ...prev,
                          growthDateRange: {
                            ...prev.growthDateRange,
                            end: e.target.value,
                          },
                        }))
                      }
                    />
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.batchId}
                      onChange={() =>
                        handleOptionChange("batchId", !selectedOptions.batchId)
                      }
                    />
                    Batch Id number
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.mushroomType}
                      onChange={() =>
                        handleOptionChange(
                          "mushroomType",
                          !selectedOptions.mushroomType
                        )
                      }
                    />
                    Mushroom type
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.cultureUsed}
                      onChange={() =>
                        handleOptionChange(
                          "cultureUsed",
                          !selectedOptions.cultureUsed
                        )
                      }
                    />
                    Culture Used
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.mLCultureUsed}
                      onChange={() =>
                        handleOptionChange(
                          "mLCultureUsed",
                          !selectedOptions.mLCultureUsed
                        )
                      }
                    />
                    Average mL Culture Used Per Container
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.growthSubstrateUsed}
                      onChange={() =>
                        handleOptionChange(
                          "growthSubstrateUsed",
                          !selectedOptions.growthSubstrateUsed
                        )
                      }
                    />
                    Substrate Used
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.containerType}
                      onChange={() =>
                        handleOptionChange(
                          "containerType",
                          !selectedOptions.containerType
                        )
                      }
                    />
                    Container Type
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.numberOfContainers}
                      onChange={() =>
                        handleOptionChange(
                          "numberOfContainers",
                          !selectedOptions.numberOfContainers
                        )
                      }
                    />
                    Number of Containers
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.numberOfContainersDiscarded}
                      onChange={() =>
                        handleOptionChange(
                          "numberOfContainersDiscarded",
                          !selectedOptions.numberOfContainersDiscarded
                        )
                      }
                    />
                    Number of Containers Discarded
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.tentNumber}
                      onChange={() =>
                        handleOptionChange(
                          "tentNumber",
                          !selectedOptions.tentNumber
                        )
                      }
                    />
                    Tent Number
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.dateIncubationBegan}
                      onChange={() =>
                        handleOptionChange(
                          "dateIncubationBegan",
                          !selectedOptions.dateIncubationBegan
                        )
                      }
                    />
                    Date Incubation Began
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.dateGrowingBegan}
                      onChange={() =>
                        handleOptionChange(
                          "dateGrowingBegan",
                          !selectedOptions.dateGrowingBegan
                        )
                      }
                    />
                    Date Growing Began
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.numberOfHarvests}
                      onChange={() =>
                        handleOptionChange(
                          "numberOfHarvests",
                          !selectedOptions.numberOfHarvests
                        )
                      }
                    />
                    Number of Harvests
                  </label>
                </div>
              </div>
            )}
          </div>
          {/* Harvest Section */}
          <div>
            <label>
              <input
                type="checkbox"
                className="growthReportCheckbox"
                checked={selectedOptions.harvestSection}
                onChange={() =>
                  handleOptionChange(
                    "harvestSection",
                    !selectedOptions.harvestSection
                  )
                }
              />
              Harvest
            </label>
            {selectedOptions.harvestSection && (
              <div>
                {/* Additional checkboxes for growth/harvest details */}
                <div className="growthHarvestNotesSubOptions">
                  <label>
                    Harvest Start Date:
                    <input
                      type="date"
                      value={selectedOptions.harvestDateRange.start}
                      onChange={(e) =>
                        setSelectedOptions((prev) => ({
                          ...prev,
                          harvestDateRange: {
                            ...prev.harvestDateRange,
                            start: e.target.value,
                          },
                        }))
                      }
                    />
                  </label>
                  <br />
                  <label>
                    Harvest End Date:
                    <input
                      type="date"
                      value={selectedOptions.harvestDateRange.end}
                      onChange={(e) =>
                        setSelectedOptions((prev) => ({
                          ...prev,
                          harvestDateRange: {
                            ...prev.harvestDateRange,
                            end: e.target.value,
                          },
                        }))
                      }
                    />
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.harvestBatchId}
                      onChange={() =>
                        handleOptionChange(
                          "harvestBatchId",
                          !selectedOptions.harvestBatchId
                        )
                      }
                    />
                    Harvest Batch Id number
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.harvestDate}
                      onChange={() =>
                        handleOptionChange(
                          "harvestDate",
                          !selectedOptions.harvestDate
                        )
                      }
                    />
                    Harvest Date
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.harvestMushroomType}
                      onChange={() =>
                        handleOptionChange(
                          "harvestMushroomType",
                          !selectedOptions.harvestMushroomType
                        )
                      }
                    />
                    Mushroom type
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.harvestCultureUsed}
                      onChange={() =>
                        handleOptionChange(
                          "harvestCultureUsed",
                          !selectedOptions.harvestCultureUsed
                        )
                      }
                    />
                    Culture Used
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.harvestSubstrateUsed}
                      onChange={() =>
                        handleOptionChange(
                          "harvestSubstrateUsed",
                          !selectedOptions.harvestSubstrateUsed
                        )
                      }
                    />
                    Substrate Used
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.harvestContainerType}
                      onChange={() =>
                        handleOptionChange(
                          "harvestContainerType",
                          !selectedOptions.harvestContainerType
                        )
                      }
                    />
                    Container Type
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.containersHarvested}
                      onChange={() =>
                        handleOptionChange(
                          "containersHarvested",
                          !selectedOptions.containersHarvested
                        )
                      }
                    />
                    Number of Containers Harvested
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.totalFreshFruitingBodyYield}
                      onChange={() =>
                        handleOptionChange(
                          "totalFreshFruitingBodyYield",
                          !selectedOptions.totalFreshFruitingBodyYield
                        )
                      }
                    />
                    Total Fresh Fruiting Body Grams of Yield
                  </label>
                  <br />
                  {selectedOptions.totalFreshFruitingBodyYield && (
                    <div className="gradeYieldOptions">
                      <label>
                        <input
                          type="checkbox"
                          className="growthReportCheckbox"
                          checked={selectedOptions.freshAGradeYield}
                          onChange={() =>
                            handleOptionChange(
                              "freshAGradeYield",
                              !selectedOptions.freshAGradeYield
                            )
                          }
                        />
                        Fresh A Grade Yield (if applicable)
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          className="growthReportCheckbox"
                          checked={selectedOptions.freshBGradeYield}
                          onChange={() =>
                            handleOptionChange(
                              "freshBGradeYield",
                              !selectedOptions.freshBGradeYield
                            )
                          }
                        />
                        Fresh B Grade Yield (if applicable)
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          className="growthReportCheckbox"
                          checked={selectedOptions.freshCGradeYield}
                          onChange={() =>
                            handleOptionChange(
                              "freshCGradeYield",
                              !selectedOptions.freshCGradeYield
                            )
                          }
                        />
                        Fresh C Grade Yield (if applicable)
                      </label>
                    </div>
                  )}
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.totalDriedFruitingBodyYield}
                      onChange={() =>
                        handleOptionChange(
                          "totalDriedFruitingBodyYield",
                          !selectedOptions.totalDriedFruitingBodyYield
                        )
                      }
                    />
                    Total Dried Fruiting Body Grams of Yield
                  </label>
                  <br />
                  {selectedOptions.totalDriedFruitingBodyYield && (
                    <div className="gradeYieldOptions">
                      <label>
                        <input
                          type="checkbox"
                          className="growthReportCheckbox"
                          checked={selectedOptions.driedType}
                          onChange={() =>
                            handleOptionChange(
                              "driedType",
                              !selectedOptions.driedType
                            )
                          }
                        />
                        Dried Type
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          className="growthReportCheckbox"
                          checked={selectedOptions.driedAGradeYield}
                          onChange={() =>
                            handleOptionChange(
                              "driedAGradeYield",
                              !selectedOptions.driedAGradeYield
                            )
                          }
                        />
                        Dried A Grade Yield (if applicable)
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          className="growthReportCheckbox"
                          checked={selectedOptions.driedBGradeYield}
                          onChange={() =>
                            handleOptionChange(
                              "driedBGradeYield",
                              !selectedOptions.driedBGradeYield
                            )
                          }
                        />
                        Dried B Grade Yield (if applicable)
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          className="growthReportCheckbox"
                          checked={selectedOptions.driedCGradeYield}
                          onChange={() =>
                            handleOptionChange(
                              "driedCGradeYield",
                              !selectedOptions.driedCGradeYield
                            )
                          }
                        />
                        Dried C Grade Yield (if applicable)
                      </label>
                    </div>
                  )}
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.totalFreshSubstrateYield}
                      onChange={() =>
                        handleOptionChange(
                          "totalFreshSubstrateYield",
                          !selectedOptions.totalFreshSubstrateYield
                        )
                      }
                    />
                    Fresh Substrate Grams of Yield
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.totalDriedSubstrateYield}
                      onChange={() =>
                        handleOptionChange(
                          "totalDriedSubstrateYield",
                          !selectedOptions.totalDriedSubstrateYield
                        )
                      }
                    />
                    Dried Substrate Grams of Yield
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.analyticData}
                      onChange={() =>
                        handleOptionChange(
                          "analyticData",
                          !selectedOptions.analyticData
                        )
                      }
                    />
                    Analytic Data (if applicable)
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="growthReportCheckbox"
                      checked={selectedOptions.mushroomImage}
                      onChange={() =>
                        handleOptionChange(
                          "mushroomImage",
                          !selectedOptions.mushroomImage
                        )
                      }
                    />
                    Image (if applicable)
                  </label>
                </div>
              </div>
            )}
          </div>

          {/* Notes Section */}
          <div>
            <label>
              <input
                type="checkbox"
                className="growthReportCheckbox"
                checked={selectedOptions.notes}
                onChange={() =>
                  handleOptionChange("notes", !selectedOptions.notes)
                }
              />
              Notes
            </label>
            {selectedOptions.notes && (
              <div>
                {/* Date range selection for cultures */}
                {selectedOptions.notes && (
                  <div className="growthHarvestNotesSubOptions">
                    <label>
                      <input
                        type="checkbox"
                        className="growthReportCheckbox"
                        checked={selectedOptions.preIncubationNote}
                        onChange={() =>
                          handleOptionChange(
                            "preIncubationNote",
                            !selectedOptions.preIncubationNote
                          )
                        }
                      />
                      Pre-Incubation Note
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        className="growthReportCheckbox"
                        checked={selectedOptions.preGrowthNote}
                        onChange={() =>
                          handleOptionChange(
                            "preGrowthNote",
                            !selectedOptions.preGrowthNote
                          )
                        }
                      />
                      Pre-Growth Note
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        className="growthReportCheckbox"
                        checked={selectedOptions.postHarvestNote}
                        onChange={() =>
                          handleOptionChange(
                            "postHarvestNote",
                            !selectedOptions.postHarvestNote
                          )
                        }
                      />
                      Post-Harvest Note
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        className="growthReportCheckbox"
                        checked={selectedOptions.postDriedNote}
                        onChange={() =>
                          handleOptionChange(
                            "postDriedNote",
                            !selectedOptions.postDriedNote
                          )
                        }
                      />
                      Post-Dried Note
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        className="growthReportCheckbox"
                        checked={selectedOptions.discardNote}
                        onChange={() =>
                          handleOptionChange(
                            "discardNote",
                            !selectedOptions.discardNote
                          )
                        }
                      />
                      Discard Note
                    </label>
                    <br />
                    <br />
                  </div>
                )}
              </div>
            )}
          </div>
        </section>

        {showReportOutput && (
          <ReportOutput
            className="report-output"
            selectedOptions={selectedOptions}
            growBags={growBags}
            cultures={cultures}
            substrates={substrates}
          />
        )}
      </div>
    </div>
  );
}
