import React, { useState, useEffect } from "react";
import "css/analytics.css";

export default function Analytics(props) {
  const {
    growBags,
    // cultures,
    // mushrooms,
    // inventoryTransactions,
    // productInventoryTransactions,
  } = props;

  // Adjusting the oneYearAgo and tomorrow dates to use UTC
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getUTCFullYear() - 1);
  const oneYearAgoString = oneYearAgo.toISOString().split("T")[0];

  // Changed "tomorrow" to "today" and removed addition of a day
  const today = new Date();
  const todayString = today.toISOString().split("T")[0];

  const [startDateString, setStartDateString] = useState(oneYearAgoString);
  const [endDateString, setEndDateString] = useState(todayString);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // Update the startDate and endDate whenever the input strings change
  useEffect(() => {
    const newStartDate = new Date(startDateString);
    setStartDate(
      new Date(
        Date.UTC(
          newStartDate.getFullYear(),
          newStartDate.getMonth(),
          newStartDate.getDate()
        )
      )
    );

    const newEndDate = new Date(endDateString);
    setEndDate(
      new Date(
        Date.UTC(
          newEndDate.getFullYear(),
          newEndDate.getMonth(),
          newEndDate.getDate(),
          23,
          59,
          59,
          999
        )
      )
    );
  }, [startDateString, endDateString]);

  function getProductionNumbers(growBags, monthStart, monthEnd) {
    return growBags.reduce((sum, growBag) => {
      if (
        isNumeric(growBag.batch_id_number) &&
        isDateInRange(growBag.date_incubation_began, monthStart, monthEnd) &&
        growBag.container_type === "Bag"
      ) {
        return sum + growBag.original_number_of_bags;
      }
      return sum;
    }, 0);
  }

  function getHarvestNumbers(growBags, monthStart, monthEnd) {
    return growBags.reduce((sum, growBag) => {
      if (
        isAlphaNumeric(growBag.batch_id_number) &&
        isDateInRange(growBag.harvest_date, monthStart, monthEnd) &&
        growBag.container_type === "Bag"
      ) {
        return sum + growBag.number_of_bags;
      }
      return sum;
    }, 0);
  }

  function getFreshYieldNumbers(growBags, monthStart, monthEnd) {
    return growBags.reduce((sum, growBag) => {
      const freshYield = String(growBag.fresh_grams_yielded).trim();

      if (
        isAlphaNumeric(growBag.batch_id_number) &&
        isDateInRange(growBag.harvest_date, monthStart, monthEnd) &&
        !isNaN(Number(freshYield))
      ) {
        return sum + Number(freshYield);
      }
      return sum;
    }, 0);
  }

  function getDriedYieldNumbers(growBags, monthStart, monthEnd) {
    return growBags.reduce((sum, growBag) => {
      const driedYield = String(growBag.dried_grams_yielded).trim();
      if (
        isAlphaNumeric(growBag.batch_id_number) &&
        isDateInRange(growBag.dried_date, monthStart, monthEnd) &&
        !isNaN(Number(driedYield))
      ) {
        return sum + Number(driedYield);
      }
      return sum;
    }, 0);
  }

  function getDriedSubstrateYieldNumbers(growBags, monthStart, monthEnd) {
    return growBags.reduce((sum, growBag) => {
      const driedSubstrateYield = String(
        growBag.dried_substrate_weight_grams
      ).trim();
      if (
        isAlphaNumeric(growBag.batch_id_number) &&
        isDateInRange(growBag.dried_date, monthStart, monthEnd) &&
        !isNaN(Number(driedSubstrateYield))
      ) {
        return sum + Number(driedSubstrateYield);
      }
      return sum;
    }, 0);
  }

  function getContaminationNumbers(growBags, monthStart, monthEnd) {
    return growBags.reduce((sum, growBag) => {
      if (
        growBag.batch_id_number.startsWith("discarded") &&
        isDateInRange(growBag.discard_date, monthStart, monthEnd)
      ) {
        return sum + growBag.num_bags_contaminated;
      }
      return sum;
    }, 0);
  }

  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function isAlphaNumeric(str) {
    if (typeof str != "string") return false;
    return str.match(/^[0-9]+[a-z]+$/i) !== null;
  }

  function isDateInRange(date, startDate, endDate) {
    const dateObj = new Date(date);
    return dateObj >= startDate && dateObj <= endDate;
  }

  function generateRows(growBags, startDate, endDate) {
    let start = new Date(
      Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), 1)
    );
    let end = new Date(
      Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, 0)
    );

    let rows = [];

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let dt = start; dt <= end; dt.setMonth(dt.getMonth() + 1)) {
      let monthStart = new Date(
        Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth(), 1)
      );
      let monthEnd = new Date(
        Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth() + 1, 0)
      );

      let productionNumbers = getProductionNumbers(
        growBags,
        monthStart,
        monthEnd
      );
      let harvestNumbers = getHarvestNumbers(growBags, monthStart, monthEnd);

      let freshYieldNumbers = getFreshYieldNumbers(
        growBags,
        monthStart,
        monthEnd
      );

      let driedYieldNumbers = getDriedYieldNumbers(
        growBags,
        monthStart,
        monthEnd
      );

      let driedSubstrateYieldNumbers = getDriedSubstrateYieldNumbers(
        growBags,
        monthStart,
        monthEnd
      );

      let contaminationNumbers = getContaminationNumbers(
        growBags,
        monthStart,
        monthEnd
      );

      let formattedMonth = `${monthNames[dt.getMonth()]} ${dt.getFullYear()}`;

      rows.push({
        month: formattedMonth,
        production: productionNumbers,
        harvest: harvestNumbers,
        freshYield: freshYieldNumbers,
        driedYield: driedYieldNumbers,
        driedSubstrateYield: driedSubstrateYieldNumbers,
        contamination: contaminationNumbers,
      });
    }

    return rows;
  }

  const rows = generateRows(growBags, startDate, endDate).reverse();

  return (
    <>
      <h2>Bag Production Analytics</h2>
      <h5>(Analytics for Non-bags will be coming in the future)</h5>
      <div className="date-selection">
        <div>
          <label>Start Date:</label>
          <input
            type="date"
            value={startDateString}
            onChange={(e) => setStartDateString(e.target.value)}
          />
        </div>

        <div className="individual-date-selection">
          <label>End Date:</label>
          <input
            type="date"
            value={endDateString}
            onChange={(e) => setEndDateString(e.target.value)}
          />
        </div>
      </div>

      <div>
        <table className="analytics-table">
          <thead>
            <tr>
              <th>Month</th>
              <th>Number of Bags Produced</th>
              <th>Number of Bags Harvested</th>
              <th>Grams of Fresh Yield Produced</th>
              <th>Grams of Dried Fruiting Body Yield Produced</th>
              <th>Grams of Dried Substrate (Myceliated Grain) Produced</th>
              <th>Number of Bags Discarded</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row.month}</td>
                <td>{row.production}</td>
                <td>{row.harvest}</td>
                <td>{row.freshYield}g</td>
                <td>{row.driedYield}g</td>
                <td>{row.driedSubstrateYield}g</td>
                <td>{row.contamination}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
