import { useContext, useState } from "react";
import { GrowBagsContext } from "./LoggedIn";

import "css/discard-modal.css";

function DiscardBagsModal({ closeModal, growBag, resetSelectedGrowBagsId }) {
  const [isContaminated, setIsContaminated] = useState(true);
  const [discardNote, setDiscardNote] = useState("");
  const [discardDate, setDiscardDate] = useState(getCurrentDateString());
  const [discardedBags, setDiscardedBags] = useState(0);
  const [discardedBagsError, setDiscardedBagsError] = useState("");

  const handleContaminationToggle = () => {
    setIsContaminated(!isContaminated);
  };

  const handleNoteChange = (event) => {
    setDiscardNote(event.target.value);
  };

  console.log(growBag);
  const { handleGrowBagsChange } = useContext(GrowBagsContext);

  // Sets today's date as default
  function getCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async function handleDiscardBagsSubmit(growBag) {
    // Validate the form inputs and get the values for bags, freshWeight, gradeA, gradeB, and gradeC

    // Error handling
    if (discardedBags === 0) {
      setDiscardedBagsError("Number of bags to discard cannot be 0");
      return;
    } else {
      setDiscardedBagsError("");
    }

    // Create a new database entry for the harvested bags with the new information
    const newBatch = {
      ...growBag,
      id: "",
      current_status: isContaminated ? "Contaminated" : "Discarded",
      discard_for_batch: growBag.id,
      batch_id_number: "discarded - " + growBag.batch_id_number,
      num_bags_contaminated: discardedBags,
      number_of_bags: discardedBags,
      discard_date: discardDate,
      discard_note: discardNote,
    };

    // Update the original batch with the reduced number of bags
    const updatedOriginalBatch = {
      ...growBag,
      number_of_bags: growBag.number_of_bags - discardedBags,
      num_bags_contaminated: growBag.num_bags_contaminated + discardedBags,
    };
    await handleGrowBagsChange(growBag.id, updatedOriginalBatch);

    await handleGrowBagsChange(newBatch.id, newBatch);

    // Close the harvest modal
    closeModal();
    resetSelectedGrowBagsId();
  }

  return (
    <div className="discard-modal" onClick={(e) => e.stopPropagation()}>
      <div
        className="discard-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="header-2-smaller-font">
          Discarding {growBag.mushroom_type} {growBag.container_type}s from
          Batch Id#: {growBag.batch_id_number}
        </h2>
        <button
          className="discard-modal-close-btn"
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          &times;
        </button>

        <label htmlFor="number-of-bags">
          Number of {growBag.container_type}s to discard:
          <select
            id="number-of-bags"
            value={discardedBags}
            onChange={(e) => {
              setDiscardedBags(Number(e.target.value));
            }}
          >
            {Array.from({ length: growBag.number_of_bags + 1 }, (_, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
        </label>
        <br />
        <label htmlFor="discard-date">
          Discard Date:
          <input
            type="date"
            value={discardDate}
            onChange={(e) => setDiscardDate(e.target.value)}
          />
        </label>

        <div className="toggle-contamination-container">
          <label
            htmlFor="toggleContamination"
            className="toggle-contamination-label"
          >
            Discarding {growBag.container_type}s due to contamination?
          </label>
          <label className="switch">
            <input
              type="checkbox"
              id="toggleContamination"
              checked={isContaminated}
              onChange={handleContaminationToggle}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <br />
        <label htmlFor="note">
          {isContaminated ? "Enter Contamination Note:" : "Enter Discard Note:"}
          <br />
          <textarea
            id="discardNote"
            className="discard-note-textarea"
            value={discardNote}
            onChange={handleNoteChange}
          ></textarea>
        </label>

        {discardedBagsError && (
          <div className="error-message">{discardedBagsError}</div>
        )}
        <button onClick={() => handleDiscardBagsSubmit(growBag)}>
          Confirm
        </button>
      </div>
    </div>
  );
}

export default DiscardBagsModal;
