import { useContext } from "react";
import { SubstrateContext } from "./LoggedIn";

export default function Substrate(props) {
  const { handleSubstrateSelect } = useContext(SubstrateContext);
  const { id, recipe_name } = props;

  return (
    <div
      className="substrate"
      onClick={() => handleSubstrateSelect(id)}
      style={{ cursor: "pointer" }}
    >
      {recipe_name}
      <hr />
    </div>
  );
}
