import { useContext } from "react";
import { CultureContext } from "./LoggedIn";
import "css/cultures.css";

export default function Culture(props) {
  const { handleCultureSelect } = useContext(CultureContext);
  const { id, culture_name, date_started } = props;

  const date = new Date(date_started);
  const formattedDate = date.toISOString().split("T")[0];

  return (
    <div className="culture-item" onClick={() => handleCultureSelect(id)}>
      <h4 className="culture-name">
        {culture_name} --- Created: {formattedDate}
      </h4>
    </div>
  );
}
