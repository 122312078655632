import { useState } from "react";
import GrowBags from "./GrowBags";
// import { GrowBagsContext } from "./LoggedIn";
import "css/harvest.css";
import "css/growbags.css";

export default function HarvestList({
  growBags,
  resetSelectedGrowBagsId,
  toggleInventoryModal,
}) {
  //   const { handleGrowBagsAdd } = useContext(GrowBagsContext);
  // State variables to keep track of mushroom type and grow bags state
  const [selectedMushroomType, setSelectedMushroomType] = useState("All");
  const [selectedGrowBagsState, setSelectedGrowBagsState] = useState("All");
  const [selectedGroupBy, setSelectedGroupBy] = useState("mushroom_type");
  const [selectedSortBy, setSelectedSortBy] = useState("harvest_date");
  const [selectedProperties, setSelectedProperties] = useState(
    new Set([
      "number_of_bags",
      "harvest_date",
      "current_status",
      "fresh_grams_yielded",
      "dried_grams_yielded",
    ])
  );
  const [sortDirection, setSortDirection] = useState(-1);
  const propertiesForDropdown = new Set([
    "batch_id_number",
    "number_of_bags",
    "date_incubation_began",
    "harvest_date",
    "fresh_grams_yielded",
    "dried_grams_yielded",
    "grade_a_fresh_grams",
    "grade_a_dried_grams",
  ]);

  // Filters grow bags based on selected mushroom type and grow bags state
  const filteredGrowBags = growBags.filter(
    (growBags) =>
      (selectedMushroomType === "All" ||
        growBags.mushroom_type === selectedMushroomType) &&
      (selectedGrowBagsState === "All" ||
        growBags.current_status === selectedGrowBagsState)
  );

  //Gets unique mushroom types from the growBags array
  const mushroomTypes = Array.from(
    new Set(growBags.map((growBags) => growBags.mushroom_type))
  );

  const tentNumbers = Array.from(
    new Set(
      growBags.map((growBags) =>
        growBags.current_status === "Incubating" || growBags.tent_number === 0
          ? "Incubation Tent"
          : `Tent ${growBags.tent_number}`
      )
    )
  ).sort((a, b) =>
    a === "Incubation Tent" ? -1 : b === "Incubation Tent" ? 1 : 0
  );

  function handleMushroomTypeChange(e) {
    setSelectedMushroomType(e.target.value);
  }

  function handleGrowBagsStateChange(e) {
    setSelectedGrowBagsState(e.target.value);
  }

  function handlePropertyChange(e) {
    const { name, checked } = e.target;
    setSelectedProperties((prevSelectedProperties) => {
      const newSelectedProperties = new Set(prevSelectedProperties);
      if (checked) {
        newSelectedProperties.add(name);
      } else {
        newSelectedProperties.delete(name);
      }
      return newSelectedProperties;
    });
  }

  function handleGroupByChange(e) {
    setSelectedGroupBy(e.target.value);
  }

  function handleSortByChange(e) {
    setSelectedSortBy(e.target.value);
  }
  function handleSortDirectionChange() {
    setSortDirection((prevSortDirection) => (prevSortDirection === 1 ? -1 : 1));
  }

  function capitalizeFirstLetterOfEachWord(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <div className="growbags-list">
      <div className="growbags-container-harvest-list">
        <div className="harvest-dropdown-container">
          <div className="harvest-dropdown-list">
            <label htmlFor="mushroomTypeFilter">Filter by Mushroom Type:</label>
            <select
              className="select-width"
              id="mushroomTypeFilter"
              value={selectedMushroomType}
              onChange={handleMushroomTypeChange}
            >
              <option value="All">All</option>
              {mushroomTypes.map((mushroomType) => (
                <option key={mushroomType} value={mushroomType}>
                  {mushroomType}
                </option>
              ))}
            </select>
            <label htmlFor="growBagsStateFilter">
              Filter by Grow Bag State:
            </label>
            <select
              className="select-width"
              id="growBagsStateFilter"
              value={selectedGrowBagsState}
              onChange={handleGrowBagsStateChange}
            >
              <option value="All">All</option>
              <option value="Harvested">Harvested</option>
              <option value="Dried">Dried</option>
            </select>
            <label htmlFor="growBagsStateGroupBy">Group By:</label>
            <select
              name="groupByFilter"
              id="groupByFilter"
              value={selectedGroupBy}
              onChange={handleGroupByChange}
              className="select-width"
            >
              <option value="mushroom_type">Mushroom Type</option>
              <option value="current_status">Harvest State</option>
            </select>
            <span className="sort-label">Sort By:</span>
            <span
              className={`sort-direction ${
                sortDirection === 1 ? "ascending" : "descending"
              }`}
              onClick={handleSortDirectionChange}
            >
              {sortDirection === 1 ? "Ascending \u25BC" : "Descending \u25B2"}
            </span>
            <select
              name="sortByFilter"
              id="sortByFilter"
              value={selectedSortBy}
              onChange={handleSortByChange}
              className="select-width"
            >
              {Array.from(propertiesForDropdown).map((property) => (
                <option key={property} value={property}>
                  {capitalizeFirstLetterOfEachWord(property.replace(/_/g, " "))}
                </option>
              ))}
            </select>
          </div>
          <div className="properties-selection-section">
            <h3 className="properties-header">Select properties to display:</h3>
            <div className="checkbox-container">
              <div className="property-wrapper">
                {[
                  "culture_used",
                  "substrate_used",
                  "avg_ml_culture_per_bag",
                  "harvest_date",
                  "post_harvest_note",
                  "fresh_grams_yielded",
                  "dried_grams_yielded",
                  "dried_date",
                  "post_dried_note",
                  "dried_type",
                  "substrate_weight_grams",
                  "grade_a_fresh_grams",
                  "grade_b_fresh_grams",
                  "grade_c_fresh_grams",
                  "grade_a_dried_grams",
                  "grade_b_dried_grams",
                  "grade_c_dried_grams",
                  "date_incubation_began",
                ].map((property) => (
                  <div
                    key={property}
                    className={`property-box ${
                      selectedProperties.has(property) ? "selected" : ""
                    }`}
                    onClick={() =>
                      handlePropertyChange({
                        target: {
                          name: property,
                          checked: !selectedProperties.has(property),
                        },
                      })
                    }
                  >
                    {property.replace(/_/g, " ")}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          {(() => {
            const groupByOptions = {
              mushroom_type: () => mushroomTypes,
              tent_number: () => tentNumbers,
              current_status: () => ["Harvested", "Dried"],
            };

            const getGroupByValue = {
              mushroom_type: (growBag) => growBag.mushroom_type,
              current_status: (growBag) => growBag.current_status,
            };

            return groupByOptions[selectedGroupBy]().map((groupValue) => {
              if (
                (selectedGroupBy === "mushroom_type" &&
                  selectedMushroomType !== "All" &&
                  groupValue !== selectedMushroomType) ||
                (selectedGroupBy === "current_status" &&
                  selectedGrowBagsState !== "All" &&
                  groupValue !== selectedGrowBagsState)
              ) {
                return null;
              }

              const filteredHarvestsByGroup = filteredGrowBags.filter(
                (growBag) =>
                  getGroupByValue[selectedGroupBy](growBag) === groupValue
              );

              return (
                <div key={groupValue}>
                  <h2 className="group-by-header">{groupValue}</h2>
                  {filteredHarvestsByGroup
                    .sort((a, b) => {
                      let comparison = 0;
                      if (selectedSortBy === "batch_id_number") {
                        // Extract numbers and letters from the batch_id_number
                        let numberA = Number(
                          a[selectedSortBy].match(/\d+/g).join("")
                        );
                        let lettersA = (
                          a[selectedSortBy].match(/[a-zA-Z]+/g) || []
                        ).join("");
                        let numberB = Number(
                          b[selectedSortBy].match(/\d+/g).join("")
                        );
                        let lettersB = (
                          b[selectedSortBy].match(/[a-zA-Z]+/g) || []
                        ).join("");

                        // Compare the numbers first
                        if (parseInt(numberA) < parseInt(numberB)) {
                          comparison = -1;
                        } else if (parseInt(numberA) > parseInt(numberB)) {
                          comparison = 1;
                        }

                        // If the numbers are equal OR if comparison is still 0, compare the letters
                        if (comparison === 0) {
                          if (lettersA < lettersB) {
                            comparison = -1;
                          } else if (lettersA > lettersB) {
                            comparison = 1;
                          }
                        }
                        return comparison * sortDirection;
                      } else {
                        if (a[selectedSortBy] < b[selectedSortBy]) {
                          comparison = -1;
                        }
                        if (a[selectedSortBy] > b[selectedSortBy]) {
                          comparison = 1;
                        }
                        return comparison * sortDirection;
                      }
                    })
                    .map(
                      (growBag) =>
                        (growBag.current_status === "Harvested" ||
                          growBag.current_status === "Dried") &&
                        growBag.batch_complete === false && (
                          <GrowBags
                            key={growBag.id}
                            {...growBag}
                            selectedProperties={Array.from(selectedProperties)}
                            resetSelectedGrowBagsId={resetSelectedGrowBagsId}
                            toggleInventoryModal={toggleInventoryModal}
                          />
                        )
                    )}
                </div>
              );
            });
          })()}
        </div>
      </div>
    </div>
  );
}
