import React from "react";
import "css/report-output.css";

export default function ReportGrowthOutput(props) {
  const {
    filteredGrowBags,
    formatDate,
    selectedOptions,
    calculateDiscardedBags,
    formatHeader,
    calculateDaysDifference,
  } = props;

  //Creating the headers for the Growth Table - based on what the user checks in the checkbox
  const growthMapping = {
    batchId: "batch_id_number",
    dateIncubationBegan: "date_incubation_began",
    dateGrowingBegan: "date_growing_began",
    mushroomType: "mushroom_type",
    cultureUsed: "culture_used",
    mLCultureUsed: "avg_ml_culture_per_bag",
    growthSubstrateUsed: "substrate_used",
    containerType: "container_type",
    numberOfContainers: "original_number_of_bags",
    numberOfContainersDiscarded: "num_bags_contaminated",
    tentNumber: "tent_number",
    numberOfHarvests: "number_of_harvests",
    // ... add other mappings as needed
  };

  // Calculate the metrics for the Growth section
  const calculateGrowthMetrics = () => {
    const metrics = {
      startDate: formatDate(selectedOptions.growthDateRange.start),
      endDate: formatDate(selectedOptions.growthDateRange.end),
      mushroomTypes: {},
    };

    filteredGrowBags.forEach((growBag) => {
      const type = growBag.mushroom_type;
      const cultureName =
        growBag.expand && growBag.expand.culture_used
          ? growBag.expand.culture_used.culture_name
          : "";
      if (!metrics.mushroomTypes[type]) {
        metrics.mushroomTypes[type] = {
          batches: 0,
          totalContainers: {},
          discardedContainers: {},
          culturesUsed: {},
          substratesUsed: {},
        };
      }
      metrics.mushroomTypes[type].batches += 1;
      metrics.mushroomTypes[type].totalContainers[growBag.container_type] =
        (metrics.mushroomTypes[type].totalContainers[growBag.container_type] ||
          0) + growBag.original_number_of_bags;
      metrics.mushroomTypes[type].discardedContainers[growBag.container_type] =
        (metrics.mushroomTypes[type].discardedContainers[
          growBag.container_type
        ] || 0) + calculateDiscardedBags(growBag.batch_id_number);
      metrics.mushroomTypes[type].culturesUsed[cultureName] =
        (metrics.mushroomTypes[type].culturesUsed[growBag.culture_used] || 0) +
        1;
      metrics.mushroomTypes[type].substratesUsed[growBag.substrate_used] =
        (metrics.mushroomTypes[type].substratesUsed[growBag.substrate_used] ||
          0) + 1;
    });

    return metrics;
  };

  const growthMetrics = calculateGrowthMetrics();

  // Render the metrics in a styled paragraph
  const renderGrowthMetrics = () => {
    return (
      <div className="metrics-section">
        <p>
          Over the period from {growthMetrics.startDate} to{" "}
          {growthMetrics.endDate}, here are some relevant growth metrics:
        </p>
        {Object.entries(growthMetrics.mushroomTypes).map(([type, data]) => (
          <div key={type}>
            <p>For the {type} mushroom:</p>
            <ul>
              <li>You grew {data.batches} total batches.</li>
              <li>
                You grew a total of{" "}
                {Object.entries(data.totalContainers)
                  .map(([containerType, count]) => `${count} ${containerType}s`)
                  .join(" and ")}
                .
              </li>
              <li>
                You discarded a total of{" "}
                {Object.entries(data.discardedContainers)
                  .map(([containerType, count]) => `${count} ${containerType}s`)
                  .join(" and ")}
                .
              </li>
              <li>
                You used the following culture strains:
                <ul className="indented-list">
                  {Object.entries(data.culturesUsed).map(([culture, count]) => (
                    <li key={culture}>
                      {culture} x{count}
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                You used the following substrate recipes:
                <ul className="indented-list">
                  {Object.entries(data.substratesUsed).map(
                    ([substrate, count]) => (
                      <li key={substrate}>
                        {substrate} x{count}
                      </li>
                    )
                  )}
                </ul>
              </li>
            </ul>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="report-table-container">
      <h3>Growth</h3>
      {renderGrowthMetrics()}
      <table>
        <thead>
          <tr>
            {Object.keys(growthMapping).map((key) => {
              if (selectedOptions[key]) {
                return <th key={key}>{formatHeader(key)}</th>;
              }
              return null;
            })}
          </tr>
        </thead>
        <tbody>
          {filteredGrowBags.map((growBag) => (
            <tr key={growBag.id}>
              {Object.entries(growthMapping).map(([key, property]) => {
                let value = growBag[property];
                if (selectedOptions[key]) {
                  if (property === "date_growing_began") {
                    if (!growBag[property]) {
                      value = "Still Incubating";
                    } else {
                      const daysIncubating = calculateDaysDifference(
                        growBag.date_incubation_began,
                        growBag[property]
                      );
                      value = (
                        <div>
                          {formatDate(growBag[property])}
                          <div className="days-indentation">
                            <br />- {daysIncubating} days incubating
                          </div>
                        </div>
                      );
                    }
                  } else if (
                    ["dateIncubationBegan", "dateGrowingBegan"].includes(key)
                  ) {
                    value = formatDate(value);
                  } else if (property === "num_bags_contaminated") {
                    value = calculateDiscardedBags(growBag.batch_id_number);
                  } else if (
                    property === "culture_used" &&
                    growBag.expand.culture_used
                  ) {
                    value = growBag.expand.culture_used.culture_name;
                  }
                  return <td key={property}>{value}</td>;
                }
                return null;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
