import React, { useContext } from "react";
import { SubstrateContext } from "./LoggedIn";
import "css/substrates.css";

export default function SubstrateView({ substrate, onExit }) {
  const { handleSubstrateEdit } = useContext(SubstrateContext);

  return (
    <div className="substrate-view">
      <button className="substrate-close-btn" onClick={onExit}>
        &times;
      </button>
      <h3>{substrate.recipe_name}</h3>
      <p>Mushroom Type: {substrate.mushroom_type}</p>
      <p>Ingredients:</p>
      <ul>
        {substrate.ingredient_list_and_grams.map((ingredientObj, index) => (
          <li key={index}>
            {ingredientObj.ingredient}: {ingredientObj.grams} grams
          </li>
        ))}
      </ul>
      <p>Water Required: {substrate.ml_water_required} ml</p>
      <p>Note: {substrate.substrate_note}</p>
      <button
        className="substrate-edit-ingredient-btn"
        onClick={() => handleSubstrateEdit(substrate.id)}
      >
        Edit
      </button>
    </div>
  );
}
