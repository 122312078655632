import { useState, useContext } from "react";
import { MushroomContext } from "./LoggedIn";
import ContainerTypeList from "./ContainerTypeList";
import AddRemoveProduct from "./ProductTypeList";
import "css/mushroom-details.css";

export default function MushroomList() {
  const { mushrooms, handleMushroomDelete, handleMushroomAdd } = useContext(
    MushroomContext
  );

  const [newMushroomName, setNewMushroomName] = useState("");
  const [showMushroomInput, setShowMushroomInput] = useState(false);

  function handleAddClick() {
    handleMushroomAdd(newMushroomName);
    setNewMushroomName("");
    setShowMushroomInput(false);
  }

  return (
    <div className="mushroom-list">
      <h2>Mushrooms to Choose From</h2>
      <ul className="mushroom-management__mushroom-list">
        {mushrooms.map((mushroom) => (
          <li key={mushroom.id}>
            {mushroom.mushroom_name}
            <button
              className="x-remove-btn"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to delete this item?")
                ) {
                  handleMushroomDelete(mushroom.id);
                }
              }}
            >
              &times;
            </button>
          </li>
        ))}
      </ul>
      {showMushroomInput ? (
        <div className="mushroom-details-list__add-item">
          <button
            className="close-btn"
            onClick={() => setShowMushroomInput(false)}
          >
            &times;
          </button>
          <input
            type="text"
            placeholder="Enter mushroom name"
            value={newMushroomName}
            onChange={(e) => setNewMushroomName(e.target.value)}
          />
          <button className="add-item-btn" onClick={handleAddClick}>
            Add Mushroom to List
          </button>
        </div>
      ) : (
        <button
          className="mushroom-details-list-add-btn"
          onClick={() => setShowMushroomInput(true)}
        >
          Add Mushroom
        </button>
      )}

      <ContainerTypeList />
      <AddRemoveProduct />
    </div>
  );
}
