import { useState, useContext } from "react";
import { CultureContext } from "./LoggedIn";
import "css/cultures.css";

export default function CultureEdit({
  culture,
  addingNewCulture,
  onCultureCancelAdd,
  mushrooms,
  selectedCultureId,
  currentUser,
}) {
  const [selectedMushroomType, setSelectedMushroomType] = useState(
    culture?.mushroom_type || ""
  );

  const [isFinished, setIsFinished] = useState(culture?.is_finished || false);

  // variable is added so culture is only updated upon clicking update button
  const [updatedCulture, setUpdatedCulture] = useState({
    ...(culture || {
      mushroom_type: "ENTER MUSHROOM TYPE",
      date_started: getCurrentDateString(),
      culture_name: "",
      master_culture_name: "",
      ph_level: 0,
      incubation_time_before_first_use: "",
      flask_condition: "", //This will be a dropdown menu with two options
      light_conditions: "",
      incubation_temp: "",
      nutrient_concentration: [],
      culture_note: "",
      date_culture_spent: "",
      created_by: currentUser,
    }),
    nutrient_concentration: (culture?.nutrient_concentration || []).map(
      (nutrient) => ({ ...nutrient })
    ),
    date_started: culture
      ? culture.date_started.slice(0, 10)
      : getCurrentDateString(),
    date_culture_spent: culture ? culture.date_culture_spent.slice(0, 10) : "",
  });
  const { handleCultureChange, handleCultureSelect, handleCultureDelete } =
    useContext(CultureContext);

  const [trackPhLevel, setTrackPhLevel] = useState(
    culture?.ph_level && culture?.ph_level > 0 ? true : false
  );
  const [trackIncubationTime, setTrackIncubationTime] = useState(
    culture?.incubation_time_before_first_use ? true : false
  );
  const [trackIncubationTemp, setTrackIncubationTemp] = useState(
    culture?.incubation_temp ? true : false
  );
  const [trackFlaskCondition, setTrackFlaskCondition] = useState(
    culture?.flask_condition ? true : false
  );
  const [trackLightConditions, setTrackLightConditions] = useState(
    culture?.light_conditions ? true : false
  );
  const [trackNutrientConcentration, setTrackNutrientConcentration] = useState(
    culture?.nutrient_concentration &&
      culture?.nutrient_concentration.length > 0
      ? true
      : false
  );

  const [cultureNameError, setCultureNameError] = useState("");
  const [masterCultureNameError, setMasterCultureNameError] = useState("");
  const [mushroomTypeError, setMushroomTypeError] = useState("");
  const [cultureCreationDateError, setCultureCreationDateError] = useState("");
  const [cultureSpentDateError, setCultureSpentDateError] = useState("");
  const [phLevelError, setPhLevelError] = useState("");

  function validateForm() {
    let isValid = true;

    if (
      updatedCulture.culture_name.trim() === "" ||
      updatedCulture.culture_name === "ENTER CULTURE NAME"
    ) {
      setCultureNameError("You must enter a culture name.");
      isValid = false;
    } else {
      setCultureNameError(false);
    }

    if (
      updatedCulture.master_culture_name.trim() === "" ||
      updatedCulture.master_culture_name === "ENTER MASTER CULTURE NAME"
    ) {
      setMasterCultureNameError(
        "You must enter a master culture name...or put N/A"
      );
      isValid = false;
    } else {
      setMasterCultureNameError(false);
    }

    if (
      selectedMushroomType.trim() === "" ||
      selectedMushroomType === "ENTER MUSHROOM TYPE" ||
      selectedMushroomType === "Select a Mushroom Type"
    ) {
      setMushroomTypeError("Please Select a mushroom type.");
      isValid = false;
    } else {
      setMushroomTypeError(false);
    }

    if (updatedCulture.date_started.trim() === "") {
      setCultureCreationDateError("You must enter a culture creation date.");
      isValid = false;
    } else {
      setCultureCreationDateError("");
    }

    if (isFinished && updatedCulture.date_culture_spent.trim() === "") {
      setCultureSpentDateError(
        "You must enter a date when the culture was spent."
      );
      isValid = false;
    } else {
      setCultureSpentDateError("");
    }

    const phValue = parseFloat(updatedCulture.ph_level);
    if (isNaN(phValue) || phValue < 0 || phValue > 15) {
      setPhLevelError("pH Level must be between 0 and 15.");
      isValid = false;
    } else {
      setPhLevelError("");
    }

    return isValid;
  }

  // Nutrient Concentration Handlers
  function addNutrientConcentration() {
    setUpdatedCulture({
      ...updatedCulture,
      nutrient_concentration: [
        ...updatedCulture.nutrient_concentration,
        { ingredient: "", grams: "" },
      ],
    });
  }

  function updateNutrientConcentration(index, updatedNutrient) {
    const updatedNutrients = updatedCulture.nutrient_concentration.map(
      (nutrient, idx) => (idx === index ? updatedNutrient : nutrient)
    );
    setUpdatedCulture({
      ...updatedCulture,
      nutrient_concentration: updatedNutrients,
    });
  }

  function removeNutrientConcentration(e, index) {
    const updatedNutrients = updatedCulture.nutrient_concentration.filter(
      (_, idx) => idx !== index
    );
    setUpdatedCulture({
      ...updatedCulture,
      nutrient_concentration: updatedNutrients,
    });
  }

  function handleChange(changes) {
    setUpdatedCulture({ ...updatedCulture, ...changes });
  }

  // Will be called when user clicks the "update" button
  // Updated to ensure time zone differences do not affect the display date
  function handleSubmit(e) {
    e.preventDefault();

    // Run Input Box Validation
    if (!validateForm()) {
      return;
    }

    if (!trackFlaskCondition) {
      updatedCulture.flask_condition = null;
    }

    handleCultureChange(culture.id, updatedCulture);
    handleCultureSelect(undefined);
  }

  // Sets today's date as default
  function getCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async function handleAdd(e) {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (!trackFlaskCondition) {
      updatedCulture.flask_condition = null;
    }

    await handleCultureChange(null, updatedCulture);
    setUpdatedCulture({
      date_started: "",
      date_culture_spent: "",
    });
    // Add this so it closes after hitting add
    handleCultureSelect(undefined);
  }

  async function handleCancel() {
    if (addingNewCulture) {
      onCultureCancelAdd();
    } else {
      handleCultureSelect(undefined);
    }
  }

  // Will be called when user clicks the "add" button

  return (
    <div key={selectedCultureId || "add"} className="culture-edit">
      <form onSubmit={addingNewCulture ? handleAdd : handleSubmit}>
        <div className="culture-edit__details--grid">
          <div className="culture-edit__remove-culture-container">
            <button className="culture-close-btn" onClick={handleCancel}>
              &times;
            </button>
          </div>
          <label htmlFor="cultureName" className="culture-edit__label">
            Culture Name
          </label>
          <input
            type="text"
            name="cultureName"
            id="cultureName"
            placeholder="ENTER CULTURE NAME"
            value={updatedCulture.culture_name}
            onChange={(e) => handleChange({ culture_name: e.target.value })}
          />
          {cultureNameError && (
            <p className="error-message">{cultureNameError}</p>
          )}
          <br />
          <label htmlFor="mushroomType" className="culture-edit__label">
            Mushroom Type
          </label>
          <select
            name="mushroomType"
            id="mushroomType"
            value={selectedMushroomType}
            onChange={(e) => {
              setSelectedMushroomType(e.target.value);
              handleChange({ mushroom_type: e.target.value });
            }}
          >
            <option value={selectedMushroomType.mushroom_name}>
              Select a Mushroom Type
            </option>
            {mushrooms.map((mushroom) => (
              <option
                key={mushroom.mushroom_name}
                value={mushroom.mushroom_name}
              >
                {mushroom.mushroom_name}
              </option>
            ))}
          </select>
          {mushroomTypeError && (
            <p className="error-message">{mushroomTypeError}</p>
          )}
          <br />
          <label htmlFor="masterCulture" className="culture-edit__label">
            Master Culture/Culture Origin
          </label>
          <input
            type="text"
            name="masterCulture"
            id="masterCulture"
            placeholder="ENTER MASTER CULTURE NAME"
            value={updatedCulture.master_culture_name}
            onChange={(e) =>
              handleChange({ master_culture_name: e.target.value })
            }
          />
          {masterCultureNameError && (
            <p className="error-message">{masterCultureNameError}</p>
          )}
          <br />
          <label htmlFor="cultureCreated" className="culture-edit__label">
            Culture Creation Date:
          </label>
          <input
            type="date"
            name="cultureCreated"
            id="cultureCreated"
            value={updatedCulture.date_started}
            onChange={(e) => handleChange({ date_started: e.target.value })}
          />
          {cultureCreationDateError && (
            <p className="error-message">{cultureCreationDateError}</p>
          )}

          {/* 2. UI Components */}
          <div className="toggle-container">
            <label>Add Nutrient Concentration?</label>
            <div className="switch">
              <input
                type="checkbox"
                checked={trackNutrientConcentration}
                onChange={() => setTrackNutrientConcentration((prev) => !prev)}
              />
              <span
                className="slider round"
                onClick={() => setTrackNutrientConcentration((prev) => !prev)}
              ></span>
            </div>
          </div>
          {trackNutrientConcentration && (
            <div className="culture-edit__nutrients">
              <button
                className="add-culture-ingredient-button"
                type="button"
                onClick={addNutrientConcentration}
              >
                Add Nutrient
              </button>
              {updatedCulture.nutrient_concentration.map((nutrient, index) => (
                <div key={index} className="culture-edit__nutrient">
                  <div className="culture-nutrient-container">
                    <label
                      htmlFor={`nutrient-${index}`}
                      className="culture-nutrient-label"
                    >
                      Nutrient {index + 1}:
                    </label>
                    <input
                      type="text"
                      id={`nutrient-${index}`}
                      value={nutrient.ingredient}
                      className="culture-edit__ingredient-input-box"
                      onChange={(e) =>
                        updateNutrientConcentration(index, {
                          ...nutrient,
                          ingredient: e.target.value,
                        })
                      }
                    />
                  </div>

                  <label
                    htmlFor={`grams-${index}`}
                    className="culture-grams-label"
                  >
                    Grams:{" "}
                  </label>
                  <input
                    type="number"
                    id={`grams-${index}`}
                    value={nutrient.grams}
                    onChange={(e) =>
                      updateNutrientConcentration(index, {
                        ...nutrient,
                        grams: e.target.value,
                      })
                    }
                  />
                  <button
                    type="button"
                    onClick={(e) => removeNutrientConcentration(e, index)}
                    className="remove-nutrient-btn"
                  ></button>
                </div>
              ))}
            </div>
          )}

          <br />
          <div className="toggle-container">
            <label>Track pH Level?</label>
            <div className="switch">
              <input
                type="checkbox"
                checked={trackPhLevel}
                id="trackPhLevel"
                name="trackPhLevel"
                value={trackPhLevel}
                onChange={() => setTrackPhLevel((prev) => !prev)}
              />
              <span
                className="slider round"
                onClick={() => setTrackPhLevel((prev) => !prev)}
              ></span>
            </div>
          </div>
          {trackPhLevel && (
            <div className="culture-edit__label-indented">
              <label htmlFor="phLevel">
                pH Level - Numbers only (Best = 5.5)
              </label>
              <input
                type="number"
                name="phLevel"
                id="phLevel"
                placeholder="Enter pH Level"
                value={updatedCulture.ph_level}
                onChange={(e) => handleChange({ ph_level: e.target.value })}
              />
            </div>
          )}
          {phLevelError && <p className="error-message">{phLevelError}</p>}

          <br />

          <div className="toggle-container">
            <label>Track Incubation Time?</label>
            <div className="switch">
              <input
                type="checkbox"
                checked={trackIncubationTime}
                id="trackIncubationTime"
                name="trackIncubationTime"
                value={trackIncubationTime}
                onChange={() => setTrackIncubationTime((prev) => !prev)}
              />
              <span
                className="slider round"
                onClick={() => setTrackIncubationTime((prev) => !prev)}
              ></span>
            </div>
          </div>

          {trackIncubationTime && (
            <div className="culture-edit__label-indented">
              <label htmlFor="incubationTime" className="culture-edit__label">
                Incubation Time Before First Use (days) - Numbers only (Best =
                21)
              </label>
              <input
                type="number"
                name="incubationTime"
                id="incubationTime"
                placeholder="Enter Incubation Time"
                value={updatedCulture.incubation_time_before_first_use}
                onChange={(e) =>
                  handleChange({
                    incubation_time_before_first_use: e.target.value,
                  })
                }
              />
            </div>
          )}
          <br />
          <div className="toggle-container">
            <label>Track Incubation Temperature?</label>
            <div className="switch">
              <input
                type="checkbox"
                checked={trackIncubationTemp}
                id="trackIncubationTemp"
                name="trackIncubationTemp"
                value={trackIncubationTemp}
                onChange={() => setTrackIncubationTemp((prev) => !prev)}
              />
              <span
                className="slider round"
                onClick={() => setTrackIncubationTemp((prev) => !prev)}
              ></span>
            </div>
          </div>
          {trackIncubationTemp && (
            <div className="culture-edit__label-indented">
              <label htmlFor="incubationTemp" className="culture-edit__label">
                Incubation Temperature (°C) - Numbers Only (Best = 25)
              </label>
              <input
                type="number"
                name="incubationTemp"
                id="incubationTemp"
                placeholder="Enter Incubation Temperature"
                value={updatedCulture.incubation_temp}
                onChange={(e) =>
                  handleChange({ incubation_temp: e.target.value })
                }
              />
            </div>
          )}
          <br />
          <div className="toggle-container">
            <label>Track Flask Condition?</label>
            <div className="switch">
              <input
                type="checkbox"
                checked={trackFlaskCondition}
                id="trackFlaskCondition"
                name="trackFlaskCondition"
                value={trackFlaskCondition}
                onChange={() => setTrackFlaskCondition((prev) => !prev)}
              />
              <span
                className="slider round"
                onClick={() => {
                  if (!trackFlaskCondition && !updatedCulture.flask_condition) {
                    handleChange({ flask_condition: "Static" });
                  }
                  setTrackFlaskCondition((prev) => !prev);
                }}
              ></span>
            </div>
          </div>
          {trackFlaskCondition && (
            <div className="culture-edit__label-indented">
              <label htmlFor="flaskCondition" className="culture-edit__label">
                Flask Condition
              </label>
              <select
                name="flaskCondition"
                id="flaskCondition"
                value={updatedCulture.flask_condition}
                onChange={(e) =>
                  handleChange({ flask_condition: e.target.value })
                }
              >
                <option value="Static">Static</option>
                <option value="Shaken">Shaken</option>
              </select>
            </div>
          )}
          <br />
          <div className="toggle-container">
            <label>Track Light Conditions?</label>
            <div className="switch">
              <input
                type="checkbox"
                checked={trackLightConditions}
                id="trackLightConditions"
                name="trackLightConditions"
                value={trackLightConditions}
                onChange={() => setTrackLightConditions((prev) => !prev)}
              />
              <span
                className="slider round"
                onClick={() => setTrackLightConditions((prev) => !prev)}
              ></span>
            </div>
          </div>
          {trackLightConditions && (
            <div className="culture-edit__label-indented">
              <label htmlFor="lightConditions" className="culture-edit__label">
                Light Conditions
              </label>
              <input
                type="text"
                name="lightConditions"
                id="lightConditions"
                placeholder="Enter Light Conditions"
                value={updatedCulture.light_conditions}
                onChange={(e) =>
                  handleChange({ light_conditions: e.target.value })
                }
              />
            </div>
          )}

          <br />

          <label htmlFor="cultureSpent" className="culture-edit__label">
            Culture Spent:
          </label>
          <select
            name="cultureSpent"
            id="cultureSpent"
            value={isFinished ? "Yes" : "No"}
            onChange={(e) => {
              const spent = e.target.value === "Yes";
              setIsFinished(spent);
              handleChange({
                is_finished: true,
                date_culture_spent: spent
                  ? updatedCulture.date_culture_spent
                  : "",
              });
              if (!spent) {
                handleChange({
                  date_culture_spent: "",
                  is_finished: false,
                });
              }
            }}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
          <br />
          {isFinished && (
            <>
              <label htmlFor="cultureSpentDate" className="culture-edit__label">
                Date Culture Spent:
              </label>
              <input
                type="date"
                name="cultureSpentDate"
                id="cultureSpentDate"
                value={updatedCulture.date_culture_spent}
                onChange={(e) =>
                  handleChange({ date_culture_spent: e.target.value })
                }
              />
              {cultureSpentDateError && (
                <p className="error-message">{cultureSpentDateError}</p>
              )}
              <br />
            </>
          )}
          <label htmlFor="cultureNote" className="culture-edit__label">
            Culture Note:
          </label>
          <textarea
            name="cultureNote"
            id="cultureNote"
            value={updatedCulture.culture_note}
            onChange={(e) => handleChange({ culture_note: e.target.value })}
          />
        </div>
        <button className="culture-button" type="submit">
          {addingNewCulture ? "Add" : "Update"}
        </button>
        {!addingNewCulture && (
          <button
            className="culture-button"
            type="delete"
            onClick={() => handleCultureDelete(culture.id)}
          >
            Delete
          </button>
        )}
      </form>
    </div>
  );
}
