import { useState, useContext } from "react";
import { ProductTypeContext } from "./LoggedIn";
import "css/mushroom-details.css";

export default function AddRemoveProduct() {
  const { productTypes, handleProductTypeDelete, handleProductTypeAdd } =
    useContext(ProductTypeContext);

  const [newProductTypeName, setNewProductTypeName] = useState("");
  const [showProductTypeInput, setShowProductTypeInput] = useState(false);
  const [newProductTypeGrams, setNewProductTypeGrams] = useState("");
  const [productError, setProductError] = useState("");

  function handleAddClick() {
    if (isNaN(newProductTypeGrams)) {
      setProductError("Please input a number only for grams used per product");
    } else {
      handleProductTypeAdd(newProductTypeName, newProductTypeGrams);
      setNewProductTypeName("");
      setShowProductTypeInput(false);
      setNewProductTypeGrams("");
      setProductError("");
    }
  }

  return (
    <div className="bag-type-list">
      <h2>Product Types</h2>
      <ul className="bag-type-management__bag-type-list">
        {productTypes.map((productType) => (
          <li key={productType.id}>
            {productType.name} -- {productType.g_mushroom_used_per_unit}g
            mushroom used per unit
            <button
              className="x-remove-btn"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to delete this item?")
                ) {
                  handleProductTypeDelete(productType.id);
                }
              }}
            >
              &times;
            </button>
          </li>
        ))}
      </ul>
      {showProductTypeInput ? (
        <div className="mushroom-details-list__add-item">
          <button
            className="close-btn"
            onClick={() => setShowProductTypeInput(false)}
          >
            &times;
          </button>
          <h4>Enter Product Type Name</h4>
          <input
            type="text"
            placeholder="Enter product type name"
            value={newProductTypeName}
            onChange={(e) => setNewProductTypeName(e.target.value)}
          />
          <h4>Enter grams of mushroom used per product</h4>
          <input
            type="text"
            placeholder="Enter grams of mushroom used per product"
            value={newProductTypeGrams}
            onChange={(e) => {
              setProductError(""); // Clear the error when user types
              setNewProductTypeGrams(e.target.value);
            }}
          />
          {productError && <div className="error">{productError}</div>}
          <button className="add-item-btn" onClick={handleAddClick}>
            Add Product to List
          </button>
        </div>
      ) : (
        <button
          className="mushroom-details-list-add-btn"
          onClick={() => setShowProductTypeInput(true)}
        >
          Add Product Type
        </button>
      )}
    </div>
  );
}
