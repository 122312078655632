//import { useState } from "react";
import "css/fully-harvested.css";

export default function FullyHarvestedBatches({ growBags }) {
  // const [selectedMushroomType, setSelectedMushroomType] = useState("All");

  const fullyHarvestedBatches = growBags.filter(
    (growBag) => growBag.number_of_bags === 0
  );

  // const filteredGrowBags = growBags.filter(
  //   (growBag) =>
  //     selectedMushroomType === "All" ||
  //     growBag.mushroom_type === selectedMushroomType
  // );

  function getHarvestData(batchIdNumber) {
    const batchHarvests = growBags
      .filter(
        (growBag) =>
          growBag.batch_id_number.slice(0, -1) === batchIdNumber &&
          growBag.batch_id_number !== batchIdNumber &&
          /[a-zA-Z]$/.test(growBag.batch_id_number)
      )
      .reverse();

    const harvestData = batchHarvests.map((harvest, index) => {
      const incubationDate = new Date(harvest.date_incubation_began);
      const harvestDate = new Date(harvest.harvest_date);
      const daysBetween = Math.floor(
        (harvestDate - incubationDate) / (1000 * 60 * 60 * 24)
      );

      return `Harvest ${
        index + 1
      }: ${harvestDate.toLocaleDateString()} - ${daysBetween} days from incubation`;
    });

    return `# of harvests: ${batchHarvests.length} -- ${harvestData.join(
      ", "
    )}`;
  }

  return (
    <div className="fully-harvested">
      <h2>Fully Harvested Batches</h2>
      {fullyHarvestedBatches.map((growBag) => (
        <div key={growBag.id} className="batch-container">
          <div className="batch-info">
            Batch ID#: {growBag.batch_id_number} -{" "}
            {growBag.original_number_of_bags} bags of {growBag.mushroom_type} -
            Tent {growBag.tent_number} - Incubation Date:{" "}
            {new Date(growBag.date_incubation_began).toLocaleDateString()} -{" "}
            Growing Started Date:{" "}
            {new Date(growBag.date_growing_began).toLocaleDateString()}
          </div>
          <div className="harvest-info">
            {getHarvestData(growBag.batch_id_number)}
          </div>
          {growBag.pre_incubation_note && (
            <div className="harvest-info">
              Pre-Incubation Note: {growBag.pre_incubation_note}
            </div>
          )}
          {growBag.pre_growth_note && (
            <div className="harvest-info">
              Pre-Growth Note: {growBag.pre_growth_note}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
