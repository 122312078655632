import { useState, useContext } from "react";
import { GrowBagsContext } from "./LoggedIn";
import "css/harvest.css";

export default function HarvestedBagsEdit({ growBags, selectedGrowBagsId }) {
  // variable is added so growBags is only updated upon clicking update button
  const [updatedGrowBags, setUpdatedGrowBags] = useState({
    ...growBags,
    container_type:
      growBags && growBags.container_type ? growBags.container_type : "Bag",
    date_incubation_began:
      growBags && growBags.date_incubation_began
        ? growBags.date_incubation_began.slice(0, 10)
        : getCurrentDateString(),
    date_growing_began:
      growBags && growBags.date_growing_began
        ? growBags.date_growing_began.slice(0, 10)
        : "",
    harvest_date:
      growBags && growBags.harvest_date
        ? growBags.harvest_date.slice(0, 10)
        : "",
    dried_date:
      growBags && growBags.dried_date ? growBags.dried_date.slice(0, 10) : "",
  });

  const growBagState = updatedGrowBags.current_status;

  const { handleGrowBagsChange, handleGrowBagsSelect, handleGrowBagsDelete } =
    useContext(GrowBagsContext);

  const [batchIdNumError, setBatchIdNumError] = useState("");
  const [numBagsInBatchError, setNumBagsInBatchError] = useState("");

  // Toggle if user would like to add a section for specific grades of yield
  const [showYieldGrades, setShowYieldGrades] = useState(
    updatedGrowBags.toggle_yield_grades
  );

  // Toggle if user would like to weigh substrate
  const [weighSubstrate, setWeighSubstrate] = useState(
    updatedGrowBags.toggle_weigh_substrate
  );

  function validateForm() {
    let isValid = true;

    if (
      isNaN(parseInt(updatedGrowBags.batch_id_number)) ||
      updatedGrowBags.batch_id_number === 0
    ) {
      setBatchIdNumError("You must enter a batch ID number.");
      isValid = false;
    } else {
      setBatchIdNumError("");
    }

    if (
      updatedGrowBags.number_of_bags === "" ||
      isNaN(parseInt(updatedGrowBags.number_of_bags))
    ) {
      setNumBagsInBatchError(
        `You must enter a valid number of ${updatedGrowBags.container_type}s in the batch.`
      );
      isValid = false;
    } else {
      setNumBagsInBatchError("");
    }

    return isValid;
  }

  function handleChange(changes) {
    setUpdatedGrowBags((prevState) => ({ ...updatedGrowBags, ...changes }));
  }

  // Will be called when user clicks the "update" button
  // This was changed for the harvested section
  async function handleSubmit(e) {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const result = await handleGrowBagsChange(growBags.id, {
      ...updatedGrowBags,
    });

    if (typeof result === "string") {
      // if result is an error message
      setNumBagsInBatchError(result);
      return;
    }

    handleGrowBagsSelect(undefined);
  }

  // Sets today's date as default
  function getCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async function handleCancel() {
    handleGrowBagsSelect(undefined);
  }

  return (
    <div key={selectedGrowBagsId} className="harvested-bags-edit">
      <div className="growbags-edit__remove-growbags-container">
        <button className="growbags-close-btn" onClick={handleCancel}>
          &times;
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="growbags-edit__details--grid">
          <br />
          <label htmlFor="batchID" className="growbags-edit__label">
            Batch ID Number:
          </label>
          <p className="batchIdNumber">{updatedGrowBags.batch_id_number}</p>
          {batchIdNumError && (
            <p className="error-message">{batchIdNumError}</p>
          )}
          <br />
          <div>
            <label htmlFor="numberOfBags" className="growbags-edit__label">
              Number of Harvested {updatedGrowBags.container_type}s:
            </label>
            <input
              type="number"
              name="numberOfBags"
              id="numberOfBags"
              min="1"
              value={updatedGrowBags.number_of_bags || ""}
              onChange={(e) =>
                handleChange({ number_of_bags: parseInt(e.target.value, 10) })
              }
            />
            {numBagsInBatchError && (
              <p className="error-message">{numBagsInBatchError}</p>
            )}
            <br />
            {(growBagState === "Harvested" || growBagState === "Dried") && (
              <>
                <label htmlFor="tentNumber" className="growbags-edit__label">
                  Tent Number {updatedGrowBags.container_type}s Grew In:
                </label>
                <input
                  type="number"
                  name="tentNumber"
                  id="tentNumber"
                  min="1"
                  value={updatedGrowBags.tent_number || ""}
                  onChange={(e) =>
                    handleChange({
                      tent_number: parseInt(e.target.value, 10),
                    })
                  }
                />
                <br />
              </>
            )}
            {(growBagState === "Harvested" || growBagState === "Dried") && (
              <>
                <label htmlFor="harvestDate" className="growbags-edit__label">
                  Harvest Date:
                </label>
                <input
                  type="date"
                  name="harvestDate"
                  id="harvestDate"
                  value={updatedGrowBags.harvest_date || ""}
                  onChange={(e) =>
                    handleChange({ harvest_date: e.target.value })
                  }
                />
                <br />
                <div className="toggle-container">
                  <label
                    htmlFor="toggleWeighSubstrate"
                    className="growbags-edit__label"
                  >
                    Weigh Substrate?
                  </label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={updatedGrowBags.toggle_weigh_substrate || false}
                      id="toggleWeighSubstrate"
                      name="toggleWeighSubstrate"
                      value={updatedGrowBags.toggle_weigh_substrate}
                      onChange={() => {
                        const newValue = !weighSubstrate;
                        setWeighSubstrate(newValue);
                        handleChange({ toggle_weigh_substrate: newValue });
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <br />
                {weighSubstrate && (
                  <div className="substrate-weight-input">
                    <label
                      htmlFor="substrateWeightGrams"
                      className="growbags-edit__label"
                    >
                      Substrate Weight in grams
                    </label>
                    <input
                      type="number"
                      name="substrateWeightGrams"
                      id="substrateWeightGrams"
                      min="0"
                      step="0.1"
                      value={updatedGrowBags.substrate_weight_grams || ""}
                      onChange={(e) => {
                        handleChange({
                          substrate_weight_grams: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </div>
                )}
                <div className="toggle-container">
                  <label
                    htmlFor="toggleYieldGrades"
                    className="growbags-edit__label"
                  >
                    Enter A, B, C grades of yield?
                  </label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={updatedGrowBags.toggle_yield_grades || false}
                      id="toggleYieldGrades"
                      name="toggleYieldGrades"
                      value={updatedGrowBags.toggle_yield_grades}
                      onChange={() => {
                        const newValue = !showYieldGrades;
                        setShowYieldGrades(newValue);
                        handleChange({ toggle_yield_grades: newValue });
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {showYieldGrades &&
                  (growBagState === "Harvested" ||
                    growBagState === "Dried") && (
                    <div className="yield-grade-input-container">
                      <label
                        htmlFor="freshAGradeYield"
                        className="growbags-edit__label"
                      >
                        Grams of A Grade Fresh Fruiting Body Yield:
                      </label>
                      <input
                        type="number"
                        name="freshAGradeYield"
                        id="freshAGradeYield"
                        min="0"
                        step="0.1"
                        value={updatedGrowBags.grade_a_fresh_grams || ""}
                        onChange={(e) => {
                          const newValue = parseFloat(e.target.value);
                          handleChange({
                            grade_a_fresh_grams: parseFloat(e.target.value),
                            fresh_grams_yielded:
                              newValue +
                              updatedGrowBags.grade_b_fresh_grams +
                              updatedGrowBags.grade_c_fresh_grams,
                          });
                        }}
                      />
                      <label
                        htmlFor="freshBGradeYield"
                        className="growbags-edit__label"
                      >
                        Grams of B Grade Fresh Fruiting Body Yield:
                      </label>
                      <input
                        type="number"
                        name="freshBGradeYield"
                        id="freshBGradeYield"
                        min="0"
                        step="0.1"
                        value={updatedGrowBags.grade_b_fresh_grams || ""}
                        onChange={(e) => {
                          const newValue = parseFloat(e.target.value);
                          handleChange({
                            grade_b_fresh_grams: parseFloat(e.target.value),
                            fresh_grams_yielded:
                              updatedGrowBags.grade_a_fresh_grams +
                              newValue +
                              updatedGrowBags.grade_c_fresh_grams,
                          });
                        }}
                      />
                      <label
                        htmlFor="freshCGradeYield"
                        className="growbags-edit__label"
                      >
                        Grams of C Grade Fresh Fruiting Body Yield:
                      </label>
                      <input
                        type="number"
                        name="freshCGradeYield"
                        id="freshCGradeYield"
                        min="0"
                        step="0.1"
                        value={updatedGrowBags.grade_c_fresh_grams || ""}
                        onChange={(e) => {
                          const newValue = parseFloat(e.target.value);
                          handleChange({
                            grade_c_fresh_grams: parseFloat(e.target.value),
                            fresh_grams_yielded:
                              updatedGrowBags.grade_a_fresh_grams +
                              updatedGrowBags.grade_b_fresh_grams +
                              newValue,
                          });
                        }}
                      />
                    </div>
                  )}
                <div className="total-yielded-section">
                  {updatedGrowBags.toggle_yield_grades && (
                    <p
                      className="edit-batch-clickable-addition-button"
                      onClick={(e) =>
                        handleChange({
                          fresh_grams_yielded:
                            updatedGrowBags.grade_a_fresh_grams +
                            updatedGrowBags.grade_b_fresh_grams +
                            updatedGrowBags.grade_c_fresh_grams,
                        })
                      }
                    >
                      Click to add fresh a,b,& c weights together
                    </p>
                  )}

                  <label
                    htmlFor="freshGramsYielded"
                    className="growbags-edit__label"
                  >
                    Total Fresh Grams Yielded:
                  </label>
                  <input
                    type="number"
                    name="freshGramsYielded"
                    id="freshGramsYielded"
                    min="0"
                    step="0.1"
                    value={updatedGrowBags.fresh_grams_yielded || ""}
                    onChange={(e) =>
                      handleChange({
                        fresh_grams_yielded: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>

                <br />
                <label
                  htmlFor="postHarvestNote"
                  className="growbags-edit__label"
                >
                  Post-Harvest Note:
                </label>
                <textarea
                  name="postHarvestNote"
                  id="postHarvestNote"
                  className="growbags-edit__textarea"
                  value={updatedGrowBags.post_harvest_note || ""}
                  onChange={(e) =>
                    handleChange({ post_harvest_note: e.target.value })
                  }
                />
                <br />
              </>
            )}
            {growBagState === "Dried" && (
              <>
                <label htmlFor="driedDate" className="growbags-edit__label">
                  Dried Date:
                </label>
                <input
                  type="date"
                  name="driedDate"
                  id="driedDate"
                  value={updatedGrowBags.dried_date || ""}
                  onChange={(e) => handleChange({ dried_date: e.target.value })}
                />
                <br />
                <label htmlFor="driedType" className="growbags-edit__label">
                  Dried Type:
                </label>
                <br />
                <select
                  className="harvested-dropdown-menu"
                  name="driedType"
                  id="driedType"
                  value={updatedGrowBags.dried_type}
                  onChange={(e) => handleChange({ dried_type: e.target.value })}
                >
                  <option value="Dehydrated">Dehydrated</option>
                  <option value="Freeze">Freeze</option>
                </select>
                <br />
                <p className="retention-percentage-header">
                  Retention Percentage:
                </p>
                {showYieldGrades && growBagState === "Dried" && (
                  <div className="yield-grade-input-container">
                    <label
                      htmlFor="driedAGradeYield"
                      className="growbags-edit__label"
                    >
                      Grams of Dried A Grade Yield:
                    </label>
                    <input
                      type="number"
                      name="driedAGradeYield"
                      id="driedAGradeYield"
                      min="0"
                      step="0.1"
                      value={updatedGrowBags.grade_a_dried_grams || ""}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        handleChange({
                          grade_a_dried_grams: parseFloat(e.target.value),
                          dried_grams_yielded:
                            newValue +
                            updatedGrowBags.grade_b_dried_grams +
                            updatedGrowBags.grade_c_dried_grams,
                        });
                      }}
                    />
                    <p className="retention-percentage-number">
                      {(
                        (updatedGrowBags.grade_a_dried_grams /
                          updatedGrowBags.grade_a_fresh_grams) *
                        100
                      ).toFixed(2)}
                      %
                    </p>
                    <label
                      htmlFor="driedBGradeYield"
                      className="growbags-edit__label"
                    >
                      Grams of Dried B Grade Yield:
                    </label>
                    <input
                      type="number"
                      name="driedBGradeYield"
                      id="driedBGradeYield"
                      min="0"
                      step="0.1"
                      value={updatedGrowBags.grade_b_dried_grams || ""}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        handleChange({
                          grade_b_dried_grams: parseFloat(e.target.value),
                          dried_grams_yielded:
                            updatedGrowBags.grade_a_dried_grams +
                            newValue +
                            updatedGrowBags.grade_c_dried_grams,
                        });
                      }}
                    />
                    <p className="retention-percentage-number">
                      {(
                        (updatedGrowBags.grade_b_dried_grams /
                          updatedGrowBags.grade_b_fresh_grams) *
                        100
                      ).toFixed(2)}
                      %
                    </p>
                    <label
                      htmlFor="driedCGradeYield"
                      className="growbags-edit__label"
                    >
                      Grams of Dried C Grade Yield:
                    </label>
                    <input
                      type="number"
                      name="driedCGradeYield"
                      id="driedCGradeYield"
                      min="0"
                      step="0.1"
                      value={updatedGrowBags.grade_c_dried_grams || ""}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        handleChange({
                          grade_c_dried_grams: parseFloat(e.target.value),
                          dried_grams_yielded:
                            updatedGrowBags.grade_a_dried_grams +
                            updatedGrowBags.grade_b_dried_grams +
                            newValue,
                        });
                      }}
                    />
                    <p className="retention-percentage-number">
                      {(
                        (updatedGrowBags.grade_c_dried_grams /
                          updatedGrowBags.grade_c_fresh_grams) *
                        100
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                )}
                <div className="total-yielded-section">
                  {updatedGrowBags.toggle_yield_grades && (
                    <p
                      className="edit-batch-clickable-addition-button"
                      onClick={(e) =>
                        handleChange({
                          dried_grams_yielded:
                            updatedGrowBags.grade_a_dried_grams +
                            updatedGrowBags.grade_b_dried_grams +
                            updatedGrowBags.grade_c_dried_grams,
                        })
                      }
                    >
                      Click to add dried a,b,& c weights together
                    </p>
                  )}
                  <label
                    htmlFor="driedGramsYielded"
                    className="growbags-edit__label"
                  >
                    Total Dried Grams Yielded:
                  </label>
                  <div>
                    <input
                      type="number"
                      name="driedGramsYielded"
                      id="driedGramsYielded"
                      min="0"
                      step="0.1"
                      className="total-dried-yield-input"
                      value={updatedGrowBags.dried_grams_yielded || ""}
                      onChange={(e) =>
                        handleChange({
                          dried_grams_yielded: parseFloat(e.target.value),
                        })
                      }
                    />
                  </div>

                  <p className="total-retention-percentage-number">
                    {(
                      (updatedGrowBags.dried_grams_yielded /
                        updatedGrowBags.fresh_grams_yielded) *
                      100
                    ).toFixed(2)}
                    %
                  </p>
                </div>

                <br />
                <label
                  htmlFor="postDryingNote"
                  className="growbags-edit__label"
                >
                  Post-Drying Note:
                </label>
                <textarea
                  name="postDryingNote"
                  id="postDryingNote"
                  className="growbags-edit__textarea"
                  value={updatedGrowBags.post_dried_note || ""}
                  onChange={(e) =>
                    handleChange({ post_dried_note: e.target.value })
                  }
                />
                <br />
              </>
            )}
            <button type="submit">Update</button>
          </div>
        </div>
      </form>
      <button
        type="delete"
        onClick={() => handleGrowBagsDelete(growBags.id, growBags)}
      >
        Delete
      </button>
      <br />
    </div>
  );
}
