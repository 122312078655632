import { useState, useContext } from "react";
import Culture from "./Culture";
import { CultureContext } from "./LoggedIn";
import "css/cultures.css";

// Leaving off -- add the ability to sort by ascending/descending

export default function CultureList({ cultures }) {
  const { handleCultureAdd } = useContext(CultureContext);
  const [selectedMushroomType, setSelectedMushroomType] = useState("All");
  const [selectedCultureState, setSelectedCultureState] = useState("Active");
  const [sortOption, setSortOption] = useState("Alphabetical");

  const mushroomTypes = Array.from(
    new Set(cultures.map((culture) => culture.mushroom_type))
  );

  const filteredCultures = cultures
    .filter(
      (culture) =>
        (selectedMushroomType === "All" ||
          culture.mushroom_type === selectedMushroomType) &&
        (selectedCultureState === "All" ||
          (selectedCultureState === "Active" && !culture.is_finished) ||
          (selectedCultureState === "Spent" && culture.is_finished))
    )
    .sort((a, b) => {
      if (sortOption === "Alphabetical") {
        return a.culture_name.localeCompare(b.culture_name);
      } else if (sortOption === "Date Started") {
        const dateA = new Date(a.date_started);
        const dateB = new Date(b.date_started);
        return dateA - dateB; // earliest first, if you want latest first, return dateB - dateA;
      } else {
        return 0;
      }
    });

  const groupedCultures = {};

  filteredCultures.forEach((culture) => {
    if (!groupedCultures[culture.mushroom_type]) {
      groupedCultures[culture.mushroom_type] = [];
    }
    groupedCultures[culture.mushroom_type].push(culture);
  });

  function handleDropdownChange(e, setState) {
    setState(e.target.value);
  }

  return (
    <div className="culture-list">
      <div className="culture-list__view-culture-btn-container culture-list-container">
        <button
          className="culture-list__add-culture-btn-container"
          onClick={handleCultureAdd}
        >
          Add Culture
        </button>
        <br /> <br />
        <label htmlFor="mushroomTypeFilter">Filter by Mushroom Type:</label>
        <select
          name="mushroomTypeFilter"
          id="mushroomTypeFilter"
          value={selectedMushroomType}
          onChange={(e) => handleDropdownChange(e, setSelectedMushroomType)}
        >
          <option value="All">All</option>
          {mushroomTypes.map((mushroomType, index) => (
            <option key={index} value={mushroomType}>
              {mushroomType}
            </option>
          ))}
        </select>
        <br />
        <label htmlFor="cultureStateFilter">Filter by Culture State:</label>
        <select
          name="cultureStateFilter"
          id="cultureStateFilter"
          value={selectedCultureState}
          onChange={(e) => handleDropdownChange(e, setSelectedCultureState)}
        >
          <option value="All">All</option>
          <option value="Active">Active Cultures</option>
          <option value="Spent">Spent Cultures</option>
        </select>
        <br />
        <label htmlFor="sortOption">Sort by:</label>
        <select
          name="sortOption"
          id="sortOption"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <option value="Alphabetical">Alphabetical</option>
          <option value="Date Started">Creation Date</option>
        </select>
      </div>
      <div>
        {Object.keys(groupedCultures).map((mushroomType) => (
          <div key={mushroomType}>
            <h3 className="mushroom-type">{mushroomType}</h3>
            <div>
              {groupedCultures[mushroomType].map((culture) => (
                <Culture key={culture.id} {...culture} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
