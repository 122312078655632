import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { UserContext } from "./Auth";
import useLogout from "./hooks/useLogout";

export default function SignUp() {
  const { pb, setIsSigningUp } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [emailSent, setEmailSent] = useState(false);
  const [signedUpEmail, setSignedUpEmail] = useState("");
  const [signedUpName, setSignedUpName] = useState("");
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [registrationError, setRegistrationError] = useState("");
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [marketingEmails, setMarketingEmails] = useState(true);

  const logout = useLogout();

  function toggleMarketingEmails() {
    setMarketingEmails(!marketingEmails);
  }

  async function onSignUp(data) {
    setRegistrationError("");
    setPasswordMismatchError(false);
    if (data.password !== data.passwordConfirm) {
      setPasswordMismatchError(true);
      return;
    }

    const userData = {
      name: data.name,
      email: data.email,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      marketingEmails,
    };

    try {
      const record = await pb.collection("users").create(userData);
      if (record) {
        const response = await pb
          .collection("users")
          .requestVerification(data.email);
        if (response) setEmailSent(true);
        setSignedUpEmail(data.email);
        setSignedUpName(data.name);
      }
    } catch (error) {
      console.error("Error during user registration:", error);
      setRegistrationError(
        "This email is already in use. Please use a different email."
      );
    }
  }

  if (emailSent) {
    return (
      <div className="email-sent-container">
        <div className="logo-container">
          <img src="/favicon.png" alt="Mushroom Merchants Logo" />
        </div>
        <h1 className="signup-header centered-header">Signup Successful!</h1>
        <h2 className="centered-text">Thanks for signing up {signedUpName}!</h2>
        <h3 className="centered-text">
          An email was sent to {signedUpEmail} so you can verify your account
          and get started!
        </h3>
        <button
          className="return-to-login-screen-btn"
          onClick={() => setIsSigningUp(false)}
        >
          Return to Login Screen
        </button>
        <button
          className="back-to-homepage"
          onClick={() => setIsSigningUp(false)}
        >
          <span>Back to Homepage</span>
        </button>
      </div>
    );
  }

  async function requestVerification() {
    if (pb.authStore.model) {
      const email = pb.authStore.model.email;
      const response = await pb.collection("users").requestVerification(email);

      if (response) setShowVerificationModal(true);
    }
  }

  async function handleReturnToHomepage() {
    window.location.href = "/";
    setShowVerificationModal(false);
    setIsSigningUp(false);
    setEmailSent(false);
    logout();
  }

  return (
    <div>
      {pb.authStore.model && (
        <div className="logged-in-container">
          <div className="logo-container">
            <img src="/favicon.png" alt="Mushroom Merchants Logo" />
          </div>
          <h1 className="signup-header centered-header adjusted-margin-header">
            Mushroom Merchants Inventory Database
          </h1>
          <div className="logged-in-wrapper">
            <p className="centered-text">Hi there, {pb.authStore.model.name}</p>
            <p className="centered-text">
              We are super excited to get you started with the Mushroom
              Merchants Database
            </p>
            <p className="centered-text">
              We sent a verification email to {pb.authStore.model.email}
            </p>
            <p className="centered-text">
              If you didn't get the email, click the button below, and we'll
              resend it.
            </p>
            <p className="centered-text">
              Once your email is verified, you are good to go!
            </p>
            <p className="centered-text">The mushroom world awaits you...</p>
            <div className="login-button-container adjusted-margin-button">
              <button
                className="verification-button"
                type="verify"
                onClick={requestVerification}
              >
                Send Verification Email
              </button>
            </div>
          </div>
        </div>
      )}
      {!pb.authStore.model && (
        <div className="sign-up-container">
          <div className="signup-form-container">
            <div className="logo-container">
              <img src="/favicon.png" alt="Mushroom Merchants Logo" />
            </div>
            <h1 className="signup-header centered-header">
              Mushroom Merchants Inventory Database Sign Up Page
            </h1>
            <form onSubmit={handleSubmit(onSignUp)}>
              <input
                type="text"
                placeholder="Unique Name"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <p className="error-message">{errors.name.message}</p>
              )}

              <input
                type="text"
                placeholder="Unique Email"
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && (
                <p className="error-message">{errors.email.message}</p>
              )}

              <input
                type="password"
                placeholder="Password"
                {...register("password", { required: "Password is required" })}
              />
              {errors.password && (
                <p className="error-message">{errors.password.message}</p>
              )}

              <input
                type="password"
                placeholder="Confirm Password"
                {...register("passwordConfirm", {
                  required: "Password confirmation is required",
                })}
              />
              {errors.passwordConfirm && (
                <p className="error-message">
                  {errors.passwordConfirm.message}
                </p>
              )}

              <div className="marketing-emails-switch">
                <label htmlFor="marketing-emails">
                  Receive Mushroom Merchants emails?
                </label>
                <div
                  className={`switch ${
                    marketingEmails ? "switch-on" : "switch-off"
                  }`}
                  onClick={toggleMarketingEmails}
                >
                  {marketingEmails && (
                    <span className="switch-label yes">Yes</span>
                  )}
                  {!marketingEmails && (
                    <span className="switch-label no">No</span>
                  )}
                  <div className="switch-handle"></div>
                </div>
              </div>
              <p className="marketing-message">
                No spam, we promise, just relevant content, products, and
                updates from your Mushroom Merchants Team
              </p>
              <button type="submit">Sign Up</button>
            </form>
            {passwordMismatchError && (
              <p className="error-message">Passwords do not match</p>
            )}

            {registrationError && (
              <p className="error-message">{registrationError}</p>
            )}
          </div>

          <button
            className="back-to-homepage"
            onClick={() => setIsSigningUp(false)}
          >
            <span>Back to Homepage</span>
          </button>
        </div>
      )}

      {showVerificationModal && (
        <div className="verification-modal">
          <div className="verification-modal-content">
            <img
              className="logo-modal"
              src="/favicon.png"
              alt="Mushroom Merchants Logo"
            />
            <h3>Verification Email Successfully Sent!</h3>
            <p>
              Please go to {pb.authStore.model.email} and verify your email.
            </p>
            <button
              className="return-to-homepage-btn"
              onClick={handleReturnToHomepage}
            >
              Return to Home Page
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
