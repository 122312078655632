import { useContext } from "react";
import "css/inventory-modal.css";
import { GrowBagsContext, InventoryContext } from "./LoggedIn";

export default function InventoryModal({ show, onClose, growBag }) {
  const { handleGrowBagsChange } = useContext(GrowBagsContext);
  const { handleInventoryTransactionAdd } = useContext(InventoryContext);

  if (!show) {
    return null;
  }

  const retentionPercentage = growBag.dried_grams_yielded
    ? (
        (growBag.dried_grams_yielded / growBag.fresh_grams_yielded) *
        100
      ).toFixed(2)
    : null;

  const gramsToPounds = (grams) => (grams * 0.00220462).toFixed(2);

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "N/A";
    }
    return date.toLocaleDateString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  async function submitInventoryData(growBag) {
    growBag.batch_complete = true;
    // Save the updated growBag to the database
    await handleGrowBagsChange(growBag.id, growBag);
    const cultureUsed = growBag.culture_used.split(" from ")[0];

    // Create the inventory data object
    // prettier-ignore
    const inventoryData = {
      "transaction_type": "add",
      "add_type": "grown",
      "from_batch_id": growBag.batch_id_number,
      "harvest_batch_relation": growBag.id,
      "transaction_date": new Date(),
      "mushroom_type": growBag.mushroom_type,
      "created_by": growBag.created_by,
      "substrate_recipe": growBag.substrate_used,
      "culture_type": cultureUsed,
      ...(growBag.current_status === "Harvested"
      ? {
        "total_fresh": growBag.fresh_grams_yielded,
        "grade_a_fresh": growBag.grade_a_fresh_grams,
        "grade_b_fresh": growBag.grade_b_fresh_grams,
        "grade_c_fresh": growBag.grade_c_fresh_grams,
      }
      : {
        "total_dried": growBag.dried_grams_yielded,
        "grade_a_dried": growBag.grade_a_dried_grams,
        "grade_b_dried": growBag.grade_b_dried_grams,
        "grade_c_dried": growBag.grade_c_dried_grams,
        "dried_type": growBag.dried_type,
        "total_retention_percentage": ((growBag.dried_grams_yielded / growBag.fresh_grams_yielded)*100),
        "grade_a_retention_percentage": ((growBag.grade_a_dried_grams / growBag.grade_a_fresh_grams)*100),
        "grade_b_retention_percentage": ((growBag.grade_b_dried_grams / growBag.grade_b_fresh_grams)*100),
        "grade_c_retention_percentage": ((growBag.grade_c_dried_grams / growBag.grade_c_fresh_grams)*100),
      }),

    };

    await handleInventoryTransactionAdd(inventoryData);

    // Close the modal
    onClose();

    // Return a confirmation popup here
  }

  return (
    <div className="inventory-modal" onClick={onClose}>
      <div
        className="inventory-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="logo-container">
          <img
            src="favicon.png"
            alt="Mushroom Merchants Logo"
            className="logo"
          />
        </div>

        <h2 className="inventory-modal-header">
          You are about to store these mushrooms in inventory.
        </h2>
        <p className="inventory-modal-subheader">
          Please review everything carefully as once you put this inventory, the
          contents of this batch will no longer be editable.
        </p>

        {/* Display growBag content here */}
        <div className="inventory-modal-data">
          <p>
            <strong>Batch ID Number:</strong> {growBag.batch_id_number}
          </p>
          <p>
            <strong>Mushroom Type:</strong> {growBag.mushroom_type}
          </p>
          <p>
            <strong>Bag Type:</strong> {growBag.bag_type}
          </p>
          <p>
            <strong>Tent Number Bags Grew In:</strong> {growBag.tent_number}
          </p>
          <p>
            <strong>Substrate Used:</strong> {growBag.substrate_used}
          </p>
          <p>
            <strong>Culture Used:</strong> {growBag.culture_used}
          </p>
          <p>
            <strong>Date Incubation Began:</strong>{" "}
            {formatDate(growBag.date_incubation_began)}
          </p>
          <p>
            <strong>Date Growing Began:</strong>{" "}
            {formatDate(growBag.date_growing_began)}
          </p>
          <p>
            <strong>Harvest Date:</strong> {formatDate(growBag.harvest_date)}
          </p>
          {growBag.current_status !== "Dried" && (
            <>
              <p>
                <strong>Fresh Grams Yielded:</strong>{" "}
                {growBag.fresh_grams_yielded} grams (
                {gramsToPounds(growBag.fresh_grams_yielded)} lbs)
              </p>
              <p>
                <strong>Grade A Fresh Grams:</strong>{" "}
                {growBag.grade_a_fresh_grams} grams (
                {gramsToPounds(growBag.grade_a_fresh_grams)} lbs)
              </p>
              <p>
                <strong>Grade B Fresh Grams:</strong>{" "}
                {growBag.grade_b_fresh_grams} grams (
                {gramsToPounds(growBag.grade_b_fresh_grams)} lbs)
              </p>
              <p>
                <strong>Grade C Fresh Grams:</strong>{" "}
                {growBag.grade_c_fresh_grams} grams (
                {gramsToPounds(growBag.grade_c_fresh_grams)} lbs)
              </p>
            </>
          )}
          {growBag.post_harvest_note && (
            <p>
              <strong>Post-Harvest Note:</strong> {growBag.post_harvest_note}
            </p>
          )}
          {growBag.current_status === "Dried" && (
            <>
              <p>
                <strong>Dried Date:</strong> {formatDate(growBag.dried_date)}
              </p>
              <p>
                <strong>Dried Type:</strong> {growBag.dried_type}
              </p>
              <p>
                <strong>Dried Grams Yielded:</strong>{" "}
                {growBag.dried_grams_yielded} (
                {gramsToPounds(growBag.dried_grams_yielded)} lbs)
              </p>
              <p>
                <strong>Retention Percentage:</strong> {retentionPercentage}%
              </p>
              <p>
                <strong>Grade A Dried Grams:</strong>{" "}
                {growBag.grade_a_dried_grams} (
                {gramsToPounds(growBag.grade_a_dried_grams)} lbs)
              </p>
              <p>
                <strong>Grade B Dried Grams:</strong>{" "}
                {growBag.grade_b_dried_grams} (
                {gramsToPounds(growBag.grade_b_dried_grams)} lbs)
              </p>
              <p>
                <strong>Grade C Dried Grams:</strong>{" "}
                {growBag.grade_c_dried_grams} (
                {gramsToPounds(growBag.grade_c_dried_grams)} lbs)
              </p>
              {growBag.post_dried_note && (
                <p>
                  <strong>Post Dried Note:</strong> {growBag.post_dried_note}
                </p>
              )}
            </>
          )}{" "}
        </div>

        <p className="inventory-modal-warning">
          If anything looks incorrect, please exit this screen and correctly
          input the values.
        </p>

        <button
          className="submit-button"
          onClick={() => submitInventoryData(growBag)}
        >
          Submit
        </button>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}
