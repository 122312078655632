import React from "react";
import pb from "lib/pocketbase";
import "css/report-output.css";

export default function ReportHarvestOutput(props) {
  const {
    filteredHarvestBags,
    formatDate,
    selectedOptions,
    formatHeader,
    calculateDaysDifference,
  } = props;

  //Creating the headers for the Harvest Table - based on what the user checks in the checkbox
  const harvestMapping = {
    harvestBatchId: "batch_id_number",
    harvestDate: "harvest_date",
    harvestMushroomType: "mushroom_type",
    harvestCultureUsed: "culture_used",
    harvestSubstrateUsed: "substrate_used",
    harvestContainerType: "container_type",
    containersHarvested: "number_of_bags",
    totalFreshFruitingBodyYield: "fresh_grams_yielded",
    freshAGradeYield: "grade_a_fresh_grams",
    freshBGradeYield: "grade_b_fresh_grams",
    freshCGradeYield: "grade_c_fresh_grams",
    totalDriedFruitingBodyYield: "dried_grams_yielded",
    driedType: "dried_type",
    driedAGradeYield: "grade_a_dried_grams",
    driedBGradeYield: "grade_b_dried_grams",
    driedCGradeYield: "grade_c_dried_grams",
    totalFreshSubstrateYield: "substrate_weight_grams",
    totalDriedSubstrateYield: "dried_substrate_weight_grams",
    analyticData: "analytics_data",
    mushroomImage: "mushroom_image",
  };

  // Sort the filteredHarvestBags by batch_id_number in the desired order
  filteredHarvestBags.sort((a, b) => {
    const [aMain, aSub] = a.batch_id_number.split("-");
    const [bMain, bSub] = b.batch_id_number.split("-");

    // First, compare the main batch id numbers
    if (parseInt(aMain) !== parseInt(bMain)) {
      return parseInt(bMain) - parseInt(aMain); // For descending order
    }

    // If main batch id numbers are the same, compare the sub batch id numbers
    if (aSub && bSub) {
      const aSubNum = parseInt(aSub);
      const bSubNum = parseInt(bSub);

      if (aSubNum === bSubNum) {
        return aSub.localeCompare(bSub); // Compare alphabetically if numbers are the same
      }
      return bSubNum - aSubNum; // For descending order
    }

    // If one of them doesn't have a sub batch id, place the one with the sub batch id first
    if (aSub) return -1;
    if (bSub) return 1;

    return 0; // If both don't have sub batch ids, they are equal
  });

  //The section here is to display the metrics for the Harvest report
  const calculateHarvestMetrics = () => {
    const metrics = {
      startDate: formatDate(selectedOptions.harvestDateRange.start),
      endDate: formatDate(selectedOptions.harvestDateRange.end),
      mushroomTypes: {},
    };

    filteredHarvestBags.forEach((growBag) => {
      const type = growBag.mushroom_type;
      const daysFromIncubation = calculateDaysDifference(
        growBag.date_incubation_began,
        growBag.harvest_date
      );
      const daysFromGrowth = calculateDaysDifference(
        growBag.date_growing_began,
        growBag.harvest_date
      );

      // Initialize metrics for mushroom type if not already done
      if (!metrics.mushroomTypes[type]) {
        metrics.mushroomTypes[type] = {
          batches: 0,
          totalContainers: {},
          totalGramsPerContainer: {},
          totalFreshFruitingBody: 0,
          totalDriedFruitingBody: 0,
          totalFreshSubstrate: 0,
          totalDriedSubstrate: 0,
          totalDaysFromIncubation: 0,
          totalDaysFromGrowth: 0,
          containers: {},
          totalAnalyticsBatches: 0, //Initializing for each mushroom type
        };
      }

      // Accumulate metrics for mushroom type
      metrics.mushroomTypes[type].batches += 1;
      metrics.mushroomTypes[type].totalDaysFromIncubation += daysFromIncubation;
      metrics.mushroomTypes[type].totalDaysFromGrowth += daysFromGrowth;
      metrics.mushroomTypes[type].totalFreshFruitingBody +=
        growBag.fresh_grams_yielded;
      metrics.mushroomTypes[type].totalDriedFruitingBody += Number(
        growBag.dried_grams_yielded
      );
      metrics.mushroomTypes[type].totalFreshSubstrate +=
        growBag.substrate_weight_grams;
      metrics.mushroomTypes[type].totalDriedSubstrate +=
        growBag.dried_substrate_weight_grams;

      // Update totalContainers for the mushroom type
      if (
        !metrics.mushroomTypes[type].totalContainers[growBag.container_type]
      ) {
        metrics.mushroomTypes[type].totalContainers[growBag.container_type] = 0;
      }
      metrics.mushroomTypes[type].totalContainers[growBag.container_type] +=
        growBag.number_of_bags || 0;

      // Initialize and accumulate metrics for container type
      if (!metrics.mushroomTypes[type].containers[growBag.container_type]) {
        metrics.mushroomTypes[type].containers[growBag.container_type] = {
          totalDaysFromIncubation: 0,
          totalDaysFromGrowth: 0,
          count: 0,
        };
      }
      metrics.mushroomTypes[type].containers[
        growBag.container_type
      ].totalDaysFromIncubation += daysFromIncubation;
      metrics.mushroomTypes[type].containers[
        growBag.container_type
      ].totalDaysFromGrowth += daysFromGrowth;
      metrics.mushroomTypes[type].containers[growBag.container_type].count += 1;

      // Accumulate other metrics related to grams per container
      if (
        !metrics.mushroomTypes[type].totalGramsPerContainer[
          growBag.container_type
        ]
      ) {
        metrics.mushroomTypes[type].totalGramsPerContainer[
          growBag.container_type
        ] = {
          freshFruitingBody: 0,
          driedFruitingBody: 0,
          freshSubstrate: 0,
          driedSubstrate: 0,
        };
      }
      metrics.mushroomTypes[type].totalGramsPerContainer[
        growBag.container_type
      ].freshFruitingBody += growBag.fresh_grams_yielded;
      metrics.mushroomTypes[type].totalGramsPerContainer[
        growBag.container_type
      ].driedFruitingBody += Number(growBag.dried_grams_yielded);
      metrics.mushroomTypes[type].totalGramsPerContainer[
        growBag.container_type
      ].freshSubstrate += growBag.substrate_weight_grams;
      metrics.mushroomTypes[type].totalGramsPerContainer[
        growBag.container_type
      ].driedSubstrate += Number(growBag.dried_substrate_weight_grams);

      // Check for analytics data
      if (growBag.analytics_data) {
        metrics.mushroomTypes[type].totalAnalyticsBatches += 1;
      }
    });

    return metrics;
  };

  const renderHarvestMetrics = () => {
    const metrics = calculateHarvestMetrics();

    return (
      <>
        <p>
          Over the period from {metrics.startDate} to {metrics.endDate}, here
          are some relevant harvest metrics:
        </p>

        {Object.entries(metrics.mushroomTypes).map(([type, data]) => {
          const averageDaysFromIncubation = (
            data.totalDaysFromIncubation / data.batches
          ).toFixed(0);
          const averageDaysFromGrowth = (
            data.totalDaysFromGrowth / data.batches
          ).toFixed(0);

          return (
            <div className="metrics-section" key={type}>
              <h4>For the {type} mushroom:</h4>
              <ul>
                <li>You harvested from {data.batches} batches.</li>
                <li>
                  You harvested a total of{" "}
                  {Object.entries(data.totalContainers)
                    .map(
                      ([containerType, count]) => `${count} ${containerType}s`
                    )
                    .join(" and ")}
                  .
                </li>
                <li>
                  You had a {averageDaysFromIncubation} day average from
                  incubation to harvest during this time period.
                </li>
                <li>
                  You had a {averageDaysFromGrowth} day average from growth to
                  harvest during this time period.
                </li>
                <li>
                  Total fresh fruiting body harvested:{" "}
                  {data.totalFreshFruitingBody}g
                </li>
                <li>
                  Total dried fruiting body harvested:{" "}
                  {Number(data.totalDriedFruitingBody)}g
                </li>
                <li>
                  Total fruiting body retention percentage:{" "}
                  {(
                    (Number(data.totalDriedFruitingBody) /
                      data.totalFreshFruitingBody) *
                    100
                  ).toFixed(2)}
                  %
                </li>
                <li>
                  Total fresh substrate yield: {data.totalFreshSubstrate}g
                </li>
                <li>
                  Total dried substrate yield: {data.totalDriedSubstrate}g
                </li>
                <li>
                  Total substrate yield retention percentage:{" "}
                  {(
                    (data.totalDriedSubstrate / data.totalFreshSubstrate) *
                    100
                  ).toFixed(2)}
                  %
                </li>
                <li>
                  Total batches harvested that had analytics:{" "}
                  {
                    filteredHarvestBags.filter(
                      (growBag) =>
                        growBag.mushroom_type === type && growBag.analytics_data
                    ).length
                  }
                </li>
              </ul>

              {/* Analytics section for the mushroom type */}
              {filteredHarvestBags.filter(
                (growBag) =>
                  growBag.mushroom_type === type && growBag.analytics_data
              ).length > 0 && (
                <>
                  <h5 className="header-for-analytics">
                    {type} Mushroom - Analytics Details:
                  </h5>
                  {filteredHarvestBags.map((growBag) => {
                    if (
                      growBag.analytics_data &&
                      growBag.mushroom_type === type
                    ) {
                      return (
                        <div key={growBag.id}>
                          <p>
                            Batch Id {growBag.batch_id_number} - Harvested{" "}
                            {formatDate(growBag.harvest_date)} - Lab:{" "}
                            {growBag.analytics_data.source}
                          </p>
                          <p>Container Type: {growBag.container_type}</p>
                          {Object.entries(growBag.analytics_data).map(
                            ([key, value]) => {
                              if (key !== "source" && value !== "BLD") {
                                return (
                                  <div className="analytics-display" key={key}>
                                    {key}: {value}mg/g
                                  </div>
                                );
                              } else if (key !== "source" && value === "BLD") {
                                return (
                                  <div className="analytics-display" key={key}>
                                    {key}: {value}
                                  </div>
                                );
                              }
                              return null;
                            }
                          )}{" "}
                        </div>
                      );
                    }
                    return null;
                  })}
                </>
              )}

              {Object.keys(data.containers).length > 1 && (
                <>
                  <h4>Totals for each {type} container type:</h4>
                  {Object.entries(data.containers).map(
                    ([containerType, containerData]) => (
                      <div key={containerType}>
                        <p>For the {containerType}s:</p>
                        <ul>
                          <li>
                            You harvested from {containerData.count}{" "}
                            {containerType}s.
                          </li>
                          <li>
                            You had a{" "}
                            {(
                              containerData.totalDaysFromIncubation /
                              containerData.count
                            ).toFixed(0)}{" "}
                            day average from incubation to harvest for the{" "}
                            {containerType}s
                          </li>
                          <li>
                            You had a{" "}
                            {(
                              containerData.totalDaysFromGrowth /
                              containerData.count
                            ).toFixed(0)}{" "}
                            day average from growth to harvest for the{" "}
                            {containerType}s
                          </li>
                          <li>
                            Total fresh fruiting body harvested from the{" "}
                            {containerType}s:{" "}
                            {
                              data.totalGramsPerContainer[containerType]
                                .freshFruitingBody
                            }
                            g
                          </li>
                          <li>
                            Total dried fruiting body harvested from the{" "}
                            {containerType}s:{" "}
                            {Number(
                              data.totalGramsPerContainer[containerType]
                                .driedFruitingBody
                            )}
                            g
                          </li>
                          <li>
                            Total fruiting body retention percentage from the{" "}
                            {containerType}s:{" "}
                            {(
                              (Number(
                                data.totalGramsPerContainer[containerType]
                                  .driedFruitingBody
                              ) /
                                data.totalGramsPerContainer[containerType]
                                  .freshFruitingBody) *
                              100
                            ).toFixed(2)}
                            %
                          </li>
                          <li>
                            Total fresh substrate yield for the {containerType}
                            s:{" "}
                            {
                              data.totalGramsPerContainer[containerType]
                                .freshSubstrate
                            }
                            g
                          </li>
                          <li>
                            Total dried substrate yield for the {containerType}
                            s:{" "}
                            {Number(
                              data.totalGramsPerContainer[containerType]
                                .driedSubstrate
                            )}
                            g
                          </li>
                          <li>
                            Total substrate yield retention percentage for the{" "}
                            {containerType}s:{" "}
                            {(
                              (Number(
                                data.totalGramsPerContainer[containerType]
                                  .driedSubstrate
                              ) /
                                data.totalGramsPerContainer[containerType]
                                  .freshSubstrate) *
                              100
                            ).toFixed(2)}
                            %
                          </li>
                          <li>
                            Total {containerType} batches harvested that had
                            analytics:{" "}
                            {
                              filteredHarvestBags.filter(
                                (growBag) =>
                                  growBag.mushroom_type === type &&
                                  growBag.container_type === containerType &&
                                  growBag.analytics_data
                              ).length
                            }
                          </li>
                        </ul>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          );
        })}
      </>
    );
  };

  //Two constants here are for the analytics display
  const formatAnalyticsData = (analyticsData) => {
    if (!analyticsData) return null;

    const labSource = analyticsData.source;
    const compounds = Object.entries(analyticsData).filter(
      ([key]) => key !== "source"
    );

    return (
      <div>
        <h6>Analytics from {labSource}</h6>
        {compounds.map(([compound, value]) => (
          <p key={compound}>
            {compound}: {value === "BLD" ? "BLD" : `${value} mg/g`}
            <br />
          </p>
        ))}
      </div>
    );
  };

  const displayAnalyticFile = (fileUrl) => {
    if (!fileUrl) return null;

    const handleFileClick = () => {
      window.open(fileUrl, "_blank");
    };

    return (
      <div onClick={handleFileClick} style={{ cursor: "pointer" }}>
        📄 {/* This is a placeholder icon. Replace with your desired icon. */}
      </div>
    );
  };

  ///
  // OUTLIERS COMMENTED OUT FOR NOW UNTIL I GET A BETTER GRASP ON WHAT I WANT THE OUTLIERS TO BE
  ///

  // // These are the outliers for the harvest section of the report
  // const calculateOutliers = (metrics) => {
  //   let yields = [];
  //   filteredHarvestBags.forEach((growBag) => {
  //     const yieldPerContainer =
  //       growBag.fresh_grams_yielded / growBag.number_of_bags;
  //     yields.push(yieldPerContainer);
  //   });

  //   const averageYield =
  //     yields.reduce((acc, curr) => acc + curr, 0) / yields.length;
  //   const variance =
  //     yields.reduce((acc, curr) => acc + Math.pow(curr - averageYield, 2), 0) /
  //     yields.length;
  //   const standardDeviation = Math.sqrt(variance);

  //   const upperLimit = averageYield + 3 * standardDeviation;
  //   const lowerLimit = averageYield - 3 * standardDeviation;

  //   const outliers = filteredHarvestBags.filter((growBag) => {
  //     const yieldPerContainer =
  //       growBag.fresh_grams_yielded / growBag.number_of_bags;
  //     return yieldPerContainer > upperLimit || yieldPerContainer < lowerLimit;
  //   });

  //   return { outliers, averageYield };
  // };

  // const renderOutliers = () => {
  //   const { outliers, averageYield } = calculateOutliers();

  //   if (outliers.length === 0) {
  //     return null;
  //   }
  //   return (
  //     <div className="outliers-section">
  //       <p>Outlier batches:</p>
  //       <ul>
  //         {outliers.map((growBag) => (
  //           <li key={growBag.id}>
  //             Batch #{growBag.batch_id_number} produced a significantly{" "}
  //             {growBag.fresh_grams_yielded / growBag.number_of_bags >
  //             averageYield
  //               ? "more"
  //               : "less"}{" "}
  //             average yield per container (
  //             {(growBag.fresh_grams_yielded / growBag.number_of_bags).toFixed(
  //               0
  //             )}
  //             g) than the average batch ({averageYield.toFixed(0)}g) from this
  //             time frame. Please look into this further.
  //           </li>
  //         ))}
  //       </ul>
  //     </div>
  //   );
  // };

  return (
    <div className="report-table-container">
      <h3>Harvest</h3>
      {renderHarvestMetrics()}
      {/* OUTLIERS COMMENTED OUT FOR NOW UNTIL I GET A BETTER GRASP ON WHAT I WANT OUTLIERS TO BE */}
      {/* {renderOutliers()} */}
      <table>
        <thead>
          <tr>
            {Object.keys(harvestMapping).map((key) => {
              if (selectedOptions[key]) {
                return <th key={key}>{formatHeader(key)}</th>;
              }
              return null;
            })}
          </tr>
        </thead>
        <tbody>
          {filteredHarvestBags.map((growBag) => (
            <tr key={growBag.id}>
              {Object.entries(harvestMapping).map(([key, property]) => {
                if (property === "mushroom_image" && growBag.mushroom_image) {
                  const imageToDisplay = `${pb.baseUrl}/api/files/grow_bags/${growBag.id}/${growBag.mushroom_image}?thumb=10x30`;
                  const handleImageClick = () => {
                    const formattedDate = formatDate(growBag.harvest_date);
                    const newWindow = window.open("", "_blank");
                    if (newWindow) {
                      newWindow.document.write(`
                            <style>
                                body {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    font-family: Arial, sans-serif;
                                    padding: 5px;
                                    box-sizing: border-box;
                                }
                                .content-container {
                                    max-width: 100%;
                                    text-align: center;
                                    margin-top: 5px;
                                }
                                img {
                                    max-width: 700px;
                                    width: 100%;
                                    height: auto;
                                }
                            </style>
                            <div class="content-container">
                                <h1>Harvest ${growBag.batch_id_number}</h1>
                                <h2>Harvested on ${formattedDate} -- Culture: ${growBag.expand.culture_used.culture_name} -- Substrate: ${growBag.substrate_used}</h2>
                                <img src="${imageToDisplay}" alt="Mushroom" />
                            </div>
                        `);
                    }
                  };
                  return (
                    <td key={property}>
                      <div
                        onClick={handleImageClick}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={imageToDisplay}
                          alt="Mushroom"
                          style={{ width: "175px", height: "auto" }}
                        />
                      </div>
                    </td>
                  );
                }

                if (selectedOptions[key]) {
                  let value = growBag[property];
                  let retentionPercentage = null;

                  const retentionPairs = {
                    dried_grams_yielded: "fresh_grams_yielded",
                    grade_a_dried_grams: "grade_a_fresh_grams",
                    grade_b_dried_grams: "grade_b_fresh_grams",
                    grade_c_dried_grams: "grade_c_fresh_grams",
                    dried_substrate_weight_grams: "substrate_weight_grams",
                  };

                  if (retentionPairs[property]) {
                    const driedYield = growBag[property];
                    const freshYield = growBag[retentionPairs[property]];
                    retentionPercentage = driedYield
                      ? ((driedYield / freshYield) * 100).toFixed(2)
                      : "";
                  }
                  if (property === "harvest_date") {
                    const daysFromIncubation = calculateDaysDifference(
                      growBag.date_incubation_began,
                      value
                    );
                    const daysFromGrowth = calculateDaysDifference(
                      growBag.date_growing_began,
                      value
                    );
                    value = (
                      <div>
                        {formatDate(value)}
                        <div className="days-indentation">
                          <br />- {daysFromIncubation} days from incubation
                          <br />- {daysFromGrowth} days from growth start
                        </div>
                      </div>
                    );
                  } else if (
                    property === "culture_used" &&
                    growBag.expand.culture_used
                  ) {
                    value = growBag.expand.culture_used.culture_name;
                  } else if (key === "analyticData") {
                    const apiBaseUrl = pb.baseUrl;

                    return [
                      <td key={property}>
                        {formatAnalyticsData(growBag.analytics_data)}
                        <p key={`${property}-file`}>
                          {growBag.analytic_file &&
                            displayAnalyticFile(
                              `${apiBaseUrl}/api/files/grow_bags/${growBag.id}/${growBag.analytic_file}`
                            )}
                        </p>
                      </td>,
                    ];
                  } else if (key.includes("Yield")) {
                    const avgYieldPerContainer = growBag.number_of_bags
                      ? (value / growBag.number_of_bags).toFixed(0)
                      : 0;

                    value =
                      !value || value === 0 ? (
                        ""
                      ) : (
                        <>
                          {value}g
                          <div className="yield-value-indentation">
                            <br />- {avgYieldPerContainer}g / container avg
                            <br />
                            {retentionPercentage &&
                              `- ${retentionPercentage}% retention`}
                          </div>
                        </>
                      );
                  } else if (key.includes("Date")) {
                    value = formatDate(value);
                  }
                  return <td key={property}>{value}</td>;
                }

                return null;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
