import React, { useState, useContext, useEffect } from "react";
import { ProductTypeContext } from "./LoggedIn";
import { ProductInventoryTransactionContext } from "./LoggedIn";
import { InventoryContext } from "./LoggedIn";

import "css/addremoveproduct.css";
import PhoneInput from "./PhoneInput";

export default function AddRemoveProduct({
  cultures,
  substrates,
  mushrooms,
  currentUser,
  setAddingRemovingProduct,
  setShowSuccessModal,
  calculateInventoryByDriedType,
  productInventoryTransactions,
}) {
  const [productType, setProductType] = useState("");
  const [productTypeSelected, setProductTypeSelected] = useState(false);
  const [operationType, setOperationType] = useState("add");
  const [driedType, setDriedType] = useState("Dehydrated");
  const [mushroomUsed, setMushroomUsed] = useState("Cordyceps");
  const [quantity, setQuantity] = useState("");
  const [checkForNumber, setCheckForNumber] = useState("");
  const [cultureStrain, setCultureStrain] = useState("unknown");
  const [substrateRecipe, setSubstrateRecipe] = useState("unknown");
  const [totalAGrade, setTotalAGrade] = useState(0);
  const [totalBGrade, setTotalBGrade] = useState(0);
  const [totalCGrade, setTotalCGrade] = useState(0);
  const [totalAGradeAvailable, setTotalAGradeAvailable] = useState(0);
  const [totalBGradeAvailable, setTotalBGradeAvailable] = useState(0);
  const [totalCGradeAvailable, setTotalCGradeAvailable] = useState(0);
  const [checkForAGradeNumber, setCheckForAGradeNumber] = useState("");
  const [checkForBGradeNumber, setCheckForBGradeNumber] = useState("");
  const [checkForCGradeNumber, setCheckForCGradeNumber] = useState("");
  const [removalPurpose, setRemovalPurpose] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [dollarsReceived, setDollarsReceived] = useState("");
  const [gradeError, setGradeError] = useState("");
  const [removalPurposeError, setRemovalPurposeError] = useState("");
  const [date, setDate] = useState(new Date());
  // This is your state for the quantity dropdown
  const [quantityOptions, setQuantityOptions] = useState([]);
  const { productTypes } = useContext(ProductTypeContext);
  const {
    handleProductInventoryTransactionAdd,
    // handleProductInventoryTransactionChange,
  } = useContext(ProductInventoryTransactionContext);
  const { handleInventoryTransactionAdd } = useContext(InventoryContext);

  useEffect(() => {
    setTotalAGradeAvailable(
      calculateInventoryByDriedType("grade_a_dried", mushroomUsed, driedType)
    );
    setTotalBGradeAvailable(
      calculateInventoryByDriedType("grade_b_dried", mushroomUsed, driedType)
    );
    setTotalCGradeAvailable(
      calculateInventoryByDriedType("grade_c_dried", mushroomUsed, driedType)
    );
  }, [mushroomUsed, driedType, calculateInventoryByDriedType]);

  useEffect(() => {
    if (
      cultureStrain &&
      substrateRecipe &&
      productType &&
      operationType === "remove"
    ) {
      const maxQuantity = productInventoryTransactions.reduce(
        (total, record) => {
          if (
            record.culture_type === cultureStrain &&
            record.substrate_type === substrateRecipe &&
            record.added_or_removed === "add" &&
            record.expand &&
            record.expand.product_type &&
            record.expand.product_type.id === productType
          ) {
            return total + record.quantity;
          } else if (
            record.culture_type === cultureStrain &&
            record.substrate_type === substrateRecipe &&
            record.added_or_removed === "remove" &&
            record.expand &&
            record.expand.product_type &&
            record.expand.product_type.id === productType
          ) {
            return total - record.quantity;
          }
          return total;
        },
        0
      );

      const options = Array.from({ length: maxQuantity }, (_, i) => i + 1);
      setQuantityOptions(options);
    }
  }, [
    cultureStrain,
    substrateRecipe,
    productType,
    operationType,
    productInventoryTransactions,
  ]);

  const handlePhoneChange = (phone) => {
    setCustomerPhone(phone);
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      setCheckForNumber("Numbers only please in this box");
    } else {
      setCheckForNumber("");
      setQuantity(value);

      const selectedProductType = productTypes.find(
        (type) => type.id === productType
      );

      if (selectedProductType) {
        setTotalAGrade(
          Math.ceil(selectedProductType.g_mushroom_used_per_unit * value * 0.8)
        );
        setTotalBGrade(
          Math.floor(selectedProductType.g_mushroom_used_per_unit * value * 0.2)
        );
      }
    }
  };

  const handleAGradeChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      setCheckForAGradeNumber("Numbers only please in this box");
    } else if (checkInventory("a", value)) {
      setCheckForAGradeNumber("");
      setTotalAGrade(value);
    }
  };

  const handleBGradeChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      setCheckForBGradeNumber("Numbers only please in this box");
    } else if (checkInventory("b", value)) {
      setCheckForBGradeNumber("");
      setTotalBGrade(value);
    }
  };

  const handleCGradeChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      setCheckForCGradeNumber("Numbers only please in this box");
    } else if (checkInventory("c", value)) {
      setCheckForCGradeNumber("");
      setTotalCGrade(value);
    }
  };

  const checkInventory = (grade, amount) => {
    const available = calculateInventoryByDriedType(
      `grade_${grade}_dried`,
      mushroomUsed,
      driedType
    );
    if (amount > available) {
      setGradeError(
        `There are only ${available}g of ${grade.toUpperCase()} grade ${mushroomUsed} ${driedType} mushrooms available.`
      );
      return false;
    }
    setGradeError(""); // reset error if there's enough inventory
    return true;
  };

  const handleProductTypeChange = (e) => {
    setProductType(e.target.value);
    setProductTypeSelected(true);
  };

  const changeOperationType = (e) => {
    setOperationType(e.target.value);
    setQuantity("");
  };

  const handleRemovalPurposeChange = (e) => {
    setRemovalPurposeError("");
    setRemovalPurpose(e.target.value);
  };

  const handleInputChange = (setter) => (event) => {
    setter(parseFloat(event.target.value) || 0);
  };

  const handleSubmitProductInventoryTransaction = (e) => {
    e.preventDefault();

    // Don't submit form if product type is not selected
    if (productType === "") {
      setProductTypeSelected(true); // set selected to true if form is submitted without selecting product type
      return;
    }

    if (operationType === "add") {
      setRemovalPurposeError("");
    }

    if (operationType === "remove" && removalPurpose === "") {
      setRemovalPurposeError("Please select a removal purpose");
      return;
    }

    if (quantity === "" || quantity === 0) {
      setCheckForNumber("Please ensure you enter quantity");
      return;
    }

    // Check if enough A grade, B grade, or C grade are available
    if (
      !checkInventory("a", totalAGrade) ||
      !checkInventory("b", totalBGrade) ||
      !checkInventory("c", totalCGrade)
    ) {
      return;
    }

    // Find the selected productType from the productTypes array
    const selectedProductType = productTypes.find(
      (type) => type.id === productType
    );

    if (gradeError) {
      // Don't allow to submit form if there's an error with grade
      return;
    }

    const productInventoryData = {
      created_by: currentUser,
      product_type: productType,
      added_or_removed: operationType,
      quantity: Number(quantity),
      culture_type: cultureStrain,
      substrate_type: substrateRecipe,
      mushroom_type: mushroomUsed,
      dried_type: driedType,
      total_grams_a_grade_used: totalAGrade,
      total_grams_b_grade_used: totalBGrade,
      total_grams_c_grade_used: totalCGrade,
      total_grams_overall:
        Number(totalAGrade) + Number(totalBGrade) + Number(totalCGrade),
      [operationType === "add"
        ? "inventory_added_date"
        : "inventory_removed_date"]: date,
      customer_name: customerName,
      customer_email: customerEmail,
      customer_phone: customerPhone,
      money_received: dollarsReceived,
      type_of_removal: removalPurpose,
    };

    const inventoryData = {
      created_by: currentUser,
      transaction_type: "subtract",
      transaction_date: date,
      mushroom_type: mushroomUsed,
      total_dried:
        Number(totalAGrade) + Number(totalBGrade) + Number(totalCGrade),
      grade_a_dried: totalAGrade,
      grade_b_dried: totalBGrade,
      grade_c_dried: totalCGrade,
      dried_type: driedType,
      subtract_type: "Adding to Product Inventory",
      product_type: productType,
      quantity_of_product: Number(quantity),
      culture_type: cultureStrain,
      substrate_recipe: substrateRecipe,
    };

    handleProductInventoryTransactionAdd(productInventoryData);
    handleInventoryTransactionAdd(inventoryData);

    setShowSuccessModal({
      show: true,
      data: productInventoryData,
      productTypeName: selectedProductType.name,
    });
  };

  async function handleCancel() {
    setShowSuccessModal({ show: false, data: null, productTypeName: null });
    setAddingRemovingProduct(false);
  }

  return (
    <>
      <div>
        <div>
          <button
            className="product-inventory-close-btn"
            onClick={handleCancel}
          >
            &times;
          </button>
        </div>
        <form
          onSubmit={handleSubmitProductInventoryTransaction}
          className="input-container"
        >
          <div className="input-container">
            <label className="input-row">
              <span className="transaction-type-row-header">
                Transaction Type
              </span>
              <select value={operationType} onChange={changeOperationType}>
                <option value="add">Adding Product</option>
                <option value="remove">Removing Product</option>
              </select>
            </label>
            {operationType === "remove" && (
              <>
                <div className="input-container">
                  <label className="input-row">
                    <span className="row-header">Removal Purpose:</span>
                    <select
                      value={removalPurpose}
                      onChange={handleRemovalPurposeChange}
                    >
                      <option value="" disabled>
                        Please select removal purpose
                      </option>
                      <option value="Sale">Sell to Customer</option>
                      <option value="Free Sample">
                        Free Sample to Customer
                      </option>
                      <option value="Personal Use">Personal Use</option>
                      <option value="Analysis">Analysis</option>
                      <option value="Give Away">Give Away</option>
                      <option value="Other">Other</option>
                    </select>
                  </label>
                </div>
                {removalPurposeError && (
                  <p className="error-message-quantity">
                    {removalPurposeError}
                  </p>
                )}
              </>
            )}
            <label className="input-row">
              <span className="row-header">Product Type:</span>
              <select value={productType} onChange={handleProductTypeChange}>
                <option value="" disabled>
                  Please select a product
                </option>
                {productTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
              {productTypeSelected && productType === "" && (
                <p className="error-message-product">Please select a product</p>
              )}{" "}
              {/* display error message if form is submitted without selecting product type */}
            </label>
            <label className="input-row">
              <span className="row-header">Mushroom Type Used:</span>
              <select
                value={mushroomUsed}
                onChange={(e) => setMushroomUsed(e.target.value)}
              >
                {mushrooms.map((mushroom) => (
                  <option
                    key={mushroom.mushroom_name}
                    value={mushroom.mushroom_name}
                  >
                    {mushroom.mushroom_name}
                  </option>
                ))}
              </select>
            </label>
            <label className="input-row">
              <span className="row-header">Dried Type Mushrooms Used:</span>
              <select
                value={driedType}
                onChange={(e) => setDriedType(e.target.value)}
              >
                <option value="Dehydrated">Dehydrated</option>
                <option value="Freeze Dried">Freeze Dried</option>
              </select>
            </label>
            <label className="input-row">
              <span className="row-header">Culture Strain Used:</span>
              <select
                value={cultureStrain}
                onChange={(e) => setCultureStrain(e.target.value)}
              >
                <option value="unknown">Unknown</option>
                {cultures.map((culture) => (
                  <option
                    key={culture.culture_name}
                    value={culture.culture_name}
                  >
                    {culture.culture_name}
                  </option>
                ))}
              </select>
            </label>
            <label className="input-row">
              <span className="row-header">Substrate Recipe Used:</span>
              <select
                value={substrateRecipe}
                onChange={(e) => setSubstrateRecipe(e.target.value)}
              >
                <option value="unknown">Unknown</option>
                {substrates.map((substrate) => (
                  <option
                    key={substrate.recipe_name}
                    value={substrate.recipe_name}
                  >
                    {substrate.recipe_name}
                  </option>
                ))}
              </select>
            </label>
            {/* FINALLY, SEE IF YOU CAN GET DATABASE AND WOOCOMMERCE TO TALK!! */}
            {operationType === "remove" && (
              <>
                <div className="row-header">
                  <label>Quantity:</label>
                  <select
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    className="quantity-dropdown"
                  >
                    <option value="" disabled>
                      Select Quantity
                    </option>
                    {quantityOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                {checkForNumber && (
                  <p className="error-message">You need to select a quantity</p>
                )}

                {(removalPurpose === "Sale" ||
                  removalPurpose === "Free Sample") && (
                  <>
                    <div className="row-header">
                      <h2 className="input-row">Customer Info:</h2>
                      <h5 className="input-row-customer-info">
                        Leave blank if unknown
                      </h5>
                    </div>
                    <div className="row-header">
                      <label className="input-row">
                        Customer Name:
                        <input
                          type="text"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                      </label>
                    </div>
                    <div className="row-header">
                      <label className="input-row">
                        Customer Email:
                        <input
                          type="email"
                          value={customerEmail}
                          onChange={(e) => setCustomerEmail(e.target.value)}
                        />
                      </label>
                    </div>
                    <div className="row-header">
                      <label className="input-row">
                        <span className="phone-row-header-remove-product">
                          Customer Phone:
                        </span>
                        <PhoneInput onPhoneChange={handlePhoneChange} />
                      </label>
                    </div>
                    {removalPurpose === "Sale" && (
                      <div className="row-header">
                        <label>
                          Dollars Received:
                          <div className="dollar-input-container">
                            <span className="dollar-symbol">$</span>
                            <input
                              type="number"
                              step="0.01"
                              value={dollarsReceived}
                              onChange={handleInputChange(setDollarsReceived)}
                            />
                          </div>
                        </label>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {operationType === "add" && (
              <>
                <div className="row-header">
                  <label className="input-row">
                    Quantity:
                    <input
                      type="text"
                      value={quantity}
                      onChange={handleQuantityChange}
                    />
                  </label>
                </div>
                {(checkForNumber === "Numbers only please in this box" ||
                  checkForNumber === "Please ensure you enter quantity") && (
                  <p className="error-message-quantity">{checkForNumber}</p>
                )}
                {removalPurposeError && (
                  <p className="error-message">{removalPurposeError}</p>
                )}
                <div className="row-header">
                  <label className="input-row">
                    {operationType === "add"
                      ? "Date Added: "
                      : "Date Removed: "}
                    <input
                      type="date"
                      value={date.toISOString().substr(0, 10)}
                      onChange={(e) => setDate(new Date(e.target.value))}
                    />
                  </label>
                </div>
                <label className="input-row">
                  <span className="row-header">
                    Total grams of A grade mushrooms used:
                  </span>
                  <input
                    type="text"
                    value={totalAGrade}
                    onChange={handleAGradeChange}
                  />
                  {checkForAGradeNumber ===
                    "Numbers only please in this box" && (
                    <p className="error-message-quantity">
                      {checkForAGradeNumber}
                    </p>
                  )}
                  {gradeError.includes("A grade") && (
                    <p className="error-message-mushroom-quantity">
                      {gradeError}
                    </p>
                  )}

                  <p>
                    Total A grade {driedType} {mushroomUsed} available:{" "}
                    {totalAGradeAvailable}g
                  </p>
                </label>
                <label className="input-row">
                  <span className="row-header">
                    Total grams of B grade mushrooms used:
                  </span>
                  <input
                    type="text"
                    value={totalBGrade}
                    onChange={handleBGradeChange}
                  />
                  {checkForBGradeNumber ===
                    "Numbers only please in this box" && (
                    <p className="error-message-quantity">
                      {checkForBGradeNumber}
                    </p>
                  )}
                  {gradeError.includes("B grade") && (
                    <p className="error-message-mushroom-quantity">
                      {gradeError}
                    </p>
                  )}
                  <p>
                    Total B grade {driedType} {mushroomUsed} available:{" "}
                    {totalBGradeAvailable}g
                  </p>
                </label>
                <label className="input-row">
                  <span className="row-header">
                    Total grams of C grade mushrooms used:
                  </span>
                  <input
                    type="text"
                    value={totalCGrade}
                    onChange={handleCGradeChange}
                  />
                  {checkForCGradeNumber ===
                    "Numbers only please in this box" && (
                    <p className="error-message-quantity">
                      {checkForCGradeNumber}
                    </p>
                  )}
                  {gradeError.includes("C grade") && (
                    <p className="error-message-mushroom-quantity">
                      {gradeError}
                    </p>
                  )}

                  <p>
                    Total C grade {driedType} {mushroomUsed} available:{" "}
                    {totalCGradeAvailable}g
                  </p>
                </label>
              </>
            )}
            <button type="submit">
              {" "}
              {operationType === "add" ? "Add Product" : "Remove Product"}
            </button>
            {checkForNumber && (
              <p className="error-message-product">
                You need to select a quantity
              </p>
            )}
            {productTypeSelected && productType === "" && (
              <p className="error-message-product">Please select a product</p>
            )}
            {removalPurposeError && (
              <p className="error-message">{removalPurposeError}</p>
            )}
            {/* display error message if form is submitted without selecting product type */}
          </div>
        </form>
      </div>
    </>
  );
}
