import { useState, useEffect, useContext, useCallback } from "react";
import "css/inventory-transactions-modal.css";
import { InventoryContext } from "./LoggedIn";
import { MushroomContext } from "./LoggedIn";
import PhoneInput from "./PhoneInput";

const InventoryTransactionModal = ({
  closeModal,
  calculateInventory,
  calculateInventoryByDriedType,
  gramsToPounds,
  currentUser,
}) => {
  const { handleInventoryTransactionAdd } = useContext(InventoryContext);

  const { mushrooms } = useContext(MushroomContext);
  const { inventoryTransactions } = useContext(InventoryContext);

  const todayDefaultDate = getCurrentDateString();

  const [inventoryAction, setInventoryAction] = useState("subtract");
  const [removalType, setRemovalType] = useState("Powderizing");
  const [addingType, setAddingType] = useState("Outside Product");
  const [transactionDate, setTransactionDate] = useState(todayDefaultDate);
  const [selectedMushroom, setSelectedMushroom] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [driedSubstrateWeight, setDriedSubstrateWeight] = useState("");
  const [aGradeWeight, setAGradeWeight] = useState("");
  const [bGradeWeight, setBGradeWeight] = useState("");
  const [cGradeWeight, setCGradeWeight] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [dollarsReceived, setDollarsReceived] = useState("");
  const [costToPowderize, setCostToPowderize] = useState("");
  const [outsideProductLocation, setOutsideProductLocation] = useState("");
  const [addTotalMushroomWeight, setAddTotalMushroomWeight] = useState("");
  const [addAGradeWeight, setAddAGradeWeight] = useState("");
  const [addBGradeWeight, setAddBGradeWeight] = useState("");
  const [addCGradeWeight, setAddCGradeWeight] = useState("");
  const [transactionNote, setTransactionNote] = useState("");
  const [errorFreshTotalMessage, setErrorFreshTotalMessage] = useState("");
  const [errorDriedSubstrateMessage, setErrorDriedSubstrateMessage] =
    useState("");
  const [errorFreshAGradeMessage, setErrorFreshAGradeMessage] = useState("");
  const [errorFreshBGradeMessage, setErrorFreshBGradeMessage] = useState("");
  const [errorFreshCGradeMessage, setErrorFreshCGradeMessage] = useState("");
  const [errorBelowSubmitButton, setErrorBelowSubmitButton] = useState("");
  const [
    errorDehydratedDriedTotalMessage,
    setErrorDehydratedDriedTotalMessage,
  ] = useState("");
  const [
    errorDehydratedDriedAGradeMessage,
    setErrorDehydratedDriedAGradeMessage,
  ] = useState("");
  const [
    errorDehydratedDriedBGradeMessage,
    setErrorDehydratedDriedBGradeMessage,
  ] = useState("");
  const [
    errorDehydratedDriedCGradeMessage,
    setErrorDehydratedDriedCGradeMessage,
  ] = useState("");
  const [errorFreezeDriedTotalMessage, setErrorFreezeDriedTotalMessage] =
    useState("");
  const [errorFreezeDriedAGradeMessage, setErrorFreezeDriedAGradeMessage] =
    useState("");
  const [errorFreezeDriedBGradeMessage, setErrorFreezeDriedBGradeMessage] =
    useState("");
  const [errorFreezeDriedCGradeMessage, setErrorFreezeDriedCGradeMessage] =
    useState("");

  const handleInputChange = (setter) => (event) => {
    setter(parseFloat(event.target.value) || 0);
  };

  // Constant to validate input
  const validateInput = useCallback(() => {
    setErrorBelowSubmitButton("");
    if (inventoryAction === "subtract") {
      if (
        selectedProduct === "Fresh" &&
        totalWeight >
          calculateInventory(
            "total_fresh",
            selectedMushroom,
            inventoryTransactions
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more ${selectedMushroom} than you have in inventory.`
        );
        setErrorFreshTotalMessage(
          `You cannot remove more ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorFreshTotalMessage("");
      }

      console.log("Inventory Transaction", inventoryTransactions);

      if (
        selectedProduct === "Dried Substrate" &&
        driedSubstrateWeight >
          calculateInventoryByDriedType(
            "dried_substrate_grams",
            selectedMushroom,
            "Freeze"
          ) +
            calculateInventoryByDriedType(
              "dried_substrate_grams",
              selectedMushroom,
              "Dehydrated"
            )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more ${selectedMushroom} substrate than you have in inventory.`
        );
        setErrorDriedSubstrateMessage(
          `You cannot remove more ${selectedMushroom} substrate than you have in inventory.`
        );
      } else {
        setErrorDriedSubstrateMessage("");
      }
      if (
        selectedProduct === "Fresh" &&
        aGradeWeight >
          calculateInventory(
            "grade_a_fresh",
            selectedMushroom,
            inventoryTransactions
          )
      ) {
        setErrorFreshAGradeMessage(
          `You cannot remove more A Grade ${selectedMushroom} than you have in inventory.`
        );
        setErrorBelowSubmitButton(
          `You cannot remove more ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorFreshAGradeMessage("");
      }

      if (
        selectedProduct === "Fresh" &&
        bGradeWeight >
          calculateInventory(
            "grade_b_fresh",
            selectedMushroom,
            inventoryTransactions
          )
      ) {
        setErrorFreshBGradeMessage(
          `You cannot remove more B Grade ${selectedMushroom} than you have in inventory.`
        );
        setErrorBelowSubmitButton(
          `You cannot remove more ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorFreshBGradeMessage("");
      }

      if (
        selectedProduct === "Fresh" &&
        cGradeWeight >
          calculateInventory(
            "grade_c_fresh",
            selectedMushroom,
            inventoryTransactions
          )
      ) {
        setErrorFreshCGradeMessage(
          `You cannot remove more C Grade ${selectedMushroom} than you have in inventory.`
        );
        setErrorBelowSubmitButton(
          `You cannot remove more ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorFreshCGradeMessage("");
      }

      if (
        selectedProduct === "Dehydrated Dried" &&
        totalWeight >
          calculateInventoryByDriedType(
            "total_dried",
            selectedMushroom,
            "Dehydrated"
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more Dried ${selectedMushroom} than you have in inventory.`
        );
        setErrorDehydratedDriedTotalMessage(
          `You cannot remove more Dehydrated ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorDehydratedDriedTotalMessage("");
      }

      if (
        selectedProduct === "Dehydrated Dried" &&
        aGradeWeight >
          calculateInventoryByDriedType(
            "grade_a_dried",
            selectedMushroom,
            "Dehydrated"
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more Dried ${selectedMushroom} than you have in inventory.`
        );
        setErrorDehydratedDriedAGradeMessage(
          `You cannot remove more A Grade Dehydrated ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorDehydratedDriedAGradeMessage("");
      }

      if (
        selectedProduct === "Dehydrated Dried" &&
        bGradeWeight >
          calculateInventoryByDriedType(
            "grade_b_dried",
            selectedMushroom,
            "Dehydrated"
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more Dried ${selectedMushroom} than you have in inventory.`
        );
        setErrorDehydratedDriedBGradeMessage(
          `You cannot remove more B Grade Dehydrated ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorDehydratedDriedBGradeMessage("");
      }

      if (
        selectedProduct === "Dehydrated Dried" &&
        cGradeWeight >
          calculateInventoryByDriedType(
            "grade_c_dried",
            selectedMushroom,
            "Dehydrated"
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more Dried ${selectedMushroom} than you have in inventory.`
        );
        setErrorDehydratedDriedCGradeMessage(
          `You cannot remove more C Grade Dehydrated ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorDehydratedDriedCGradeMessage("");
      }

      if (
        selectedProduct === "Freeze Dried" &&
        totalWeight >
          calculateInventoryByDriedType(
            "total_dried",
            selectedMushroom,
            "Freeze"
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more Dried ${selectedMushroom} than you have in inventory.`
        );
        setErrorFreezeDriedTotalMessage(
          `You cannot remove more Freeze Dried ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorFreezeDriedTotalMessage("");
      }

      if (
        selectedProduct === "Freeze Dried" &&
        aGradeWeight >
          calculateInventoryByDriedType(
            "grade_a_dried",
            selectedMushroom,
            "Freeze"
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more Dried ${selectedMushroom} than you have in inventory.`
        );
        setErrorFreezeDriedAGradeMessage(
          `You cannot remove more A Grade Freeze Dried ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorFreezeDriedAGradeMessage("");
      }

      if (
        selectedProduct === "Freeze Dried" &&
        bGradeWeight >
          calculateInventoryByDriedType(
            "grade_b_dried",
            selectedMushroom,
            "Freeze"
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more Dried ${selectedMushroom} than you have in inventory.`
        );
        setErrorFreezeDriedBGradeMessage(
          `You cannot remove more B Grade Freeze Dried ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorFreezeDriedBGradeMessage("");
      }

      if (
        selectedProduct === "Freeze Dried" &&
        cGradeWeight >
          calculateInventoryByDriedType(
            "grade_c_dried",
            selectedMushroom,
            "Freeze"
          )
      ) {
        setErrorBelowSubmitButton(
          `You cannot remove more Dried ${selectedMushroom} than you have in inventory.`
        );
        setErrorFreezeDriedCGradeMessage(
          `You cannot remove more C Grade Freeze Dried ${selectedMushroom} than you have in inventory.`
        );
      } else {
        setErrorFreezeDriedCGradeMessage("");
      }
    }
  }, [
    driedSubstrateWeight,
    inventoryAction,
    totalWeight,
    aGradeWeight,
    bGradeWeight,
    cGradeWeight,
    selectedMushroom,
    inventoryTransactions,
    calculateInventory,
    calculateInventoryByDriedType,
    selectedProduct,
  ]);

  const handlePhoneChange = (phone) => {
    setCustomerPhone(phone);
  };

  // Use useEffect to update the selectedMushroom once the mushrooms data is fetched
  useEffect(() => {
    // Check if mushrooms data exists and is not an empty array
    if (mushrooms && mushrooms.length > 0) {
      // Find the mushroom object for "Cordyceps"
      const cordycepsMushroom = mushrooms.find(
        (mushroom) => mushroom.mushroom_name === "Cordyceps"
      );

      // If "Cordyceps" mushroom is found, set it as the selectedMushroom
      if (cordycepsMushroom) {
        setSelectedMushroom(cordycepsMushroom.mushroom_name);
      } else {
        // If "Cordyceps" mushroom is not found, default to the first mushroom in the list
        setSelectedMushroom(mushrooms[0].mushroom_name);
      }
    }
  }, [mushrooms]); // This useEffect will run every time the mushrooms data changes

  useEffect(() => {
    validateInput();
  }, [inventoryAction, totalWeight, selectedMushroom, validateInput]);

  useEffect(() => {
    if (inventoryAction === "subtract") {
      setTotalWeight(aGradeWeight + bGradeWeight + cGradeWeight);
    } else if (inventoryAction === "add") {
      setAddTotalMushroomWeight(
        addAGradeWeight + addBGradeWeight + addCGradeWeight
      );
    }
  }, [
    aGradeWeight,
    bGradeWeight,
    cGradeWeight,
    addAGradeWeight,
    addBGradeWeight,
    addCGradeWeight,
    inventoryAction,
  ]);

  async function submitTransactionData() {
    // Prevent function from running if there is an error message
    if (errorBelowSubmitButton) {
      return;
    }

    let productData = {};

    if (selectedProduct === "Fresh" && inventoryAction === "add") {
      productData = {
        total_fresh: addTotalMushroomWeight,
        grade_a_fresh: addAGradeWeight,
        grade_b_fresh: addBGradeWeight,
        grade_c_fresh: addCGradeWeight,
        add_type: addingType,
      };
    } else if (
      selectedProduct === "Dehydrated Dried" &&
      inventoryAction === "add"
    ) {
      productData = {
        total_dried: addTotalMushroomWeight,
        grade_a_dried: addAGradeWeight,
        grade_b_dried: addBGradeWeight,
        grade_c_dried: addCGradeWeight,
        dried_type: "Dehydrated",
        add_type: addingType,
      };
    } else if (
      selectedProduct === "Freeze Dried" &&
      inventoryAction === "add"
    ) {
      productData = {
        total_dried: addTotalMushroomWeight,
        grade_a_dried: addAGradeWeight,
        grade_b_dried: addBGradeWeight,
        grade_c_dried: addCGradeWeight,
        dried_type: "Freeze",
        add_type: addingType,
      };
    } else if (
      selectedProduct === "Dried Substrate" &&
      inventoryAction === "add"
    ) {
      productData = {
        dried_substrate_grams: driedSubstrateWeight,
        add_type: addingType,
      };
    } else if (inventoryAction === "subtract" && selectedProduct === "Fresh") {
      productData = {
        total_fresh: totalWeight,
        grade_a_fresh: aGradeWeight,
        grade_b_fresh: bGradeWeight,
        grade_c_fresh: cGradeWeight,
        subtract_type: removalType,
      };
    } else if (
      inventoryAction === "subtract" &&
      selectedProduct === "Dehydrated Dried"
    ) {
      productData = {
        total_dried: totalWeight,
        grade_a_dried: aGradeWeight,
        grade_b_dried: bGradeWeight,
        grade_c_dried: cGradeWeight,
        dried_type: "Dehydrated",
        subtract_type: removalType,
      };
    } else if (
      inventoryAction === "subtract" &&
      selectedProduct === "Freeze Dried"
    ) {
      productData = {
        total_dried: totalWeight,
        grade_a_dried: aGradeWeight,
        grade_b_dried: bGradeWeight,
        grade_c_dried: cGradeWeight,
        dried_type: "Freeze",
        subtract_type: removalType,
      };
    } else if (
      inventoryAction === "subtract" &&
      selectedProduct === "Dried Substrate"
    ) {
      productData = {
        dried_substrate_grams: driedSubstrateWeight,
        subtract_type: removalType,
      };
    }

    let saleData = {};

    if (removalType === "Sale" || removalType === "Free Sample") {
      saleData = {
        customer_name: customerName,
        customer_email: customerEmail,
        money_received: dollarsReceived,
        customer_phone: customerPhone,
      };
    }

    const inventoryData = {
      transaction_type: inventoryAction,
      transaction_date: transactionDate,
      mushroom_type: selectedMushroom,
      created_by: currentUser,
      transaction_note: transactionNote,
      cost_to_powderize: costToPowderize,

      ...productData,
      ...saleData,
    };

    await handleInventoryTransactionAdd(inventoryData);

    closeModal();
  }

  // Sets today's date as default
  function getCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="inventory-transaction-modal">
      <div className="inventory-transaction-modal-content">
        <div className="inventory-transaction-modal-header">
          <h4>Inventory Transaction</h4>
        </div>
        <button
          className="inventory-transaction-modal-close-btn"
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          &times;
        </button>
        <form>
          <label>
            Inventory Action:
            <select
              value={inventoryAction}
              onChange={(e) => setInventoryAction(e.target.value)}
            >
              <option value="subtract">Remove from Inventory</option>
              <option value="add">Add to Inventory</option>
            </select>
          </label>
          {inventoryAction === "add" && (
            <label>
              Adding Type:
              <select
                value={addingType}
                onChange={(e) => setAddingType(e.target.value)}
              >
                <option value="Outside Product">Adding Outside Product</option>
                <option value="Internal Product">
                  Adding Internal Product
                </option>
                <option value="Other">Other</option>
              </select>
            </label>
          )}
          {inventoryAction === "subtract" && (
            <>
              <label>
                Removal Type:
                <select
                  value={removalType}
                  onChange={(e) => setRemovalType(e.target.value)}
                >
                  <option value="Powderizing">Powderizing</option>
                  <option value="Sale">Sell to Customer</option>
                  <option value="Free Sample">Free Sample to Customer</option>
                  <option value="Personal Use">Personal Use</option>
                  <option value="Analysis">Analysis</option>
                  <option value="Give Away">Give Away</option>
                  <option value="Adjusting Inventory">
                    Adjusting Inventory
                  </option>
                  <option value="Other">Other</option>
                </select>
              </label>
            </>
          )}
          <label htmlFor="transactionDate">Transaction Date:</label>
          <input
            type="date"
            id="transactionDate"
            value={transactionDate}
            onChange={(e) => setTransactionDate(e.target.value)}
          />
          <label>
            Selected Mushroom:
            <select
              value={selectedMushroom}
              onChange={(e) => setSelectedMushroom(e.target.value)}
            >
              {mushrooms.map((mushroom) => (
                <option
                  key={mushroom.mushroom_name}
                  value={mushroom.mushroom_name}
                >
                  {mushroom.mushroom_name}
                </option>
              ))}
            </select>
          </label>
          <label>
            Type of {selectedMushroom}
            <select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
            >
              {selectedProduct === "" && (
                <>
                  <option value="" disabled>
                    Select type of {selectedMushroom}
                  </option>
                </>
              )}
              <option value="Fresh">Fresh {selectedMushroom}</option>
              <option value="Freeze Dried">
                Freeze Dried {selectedMushroom}
              </option>
              <option value="Dehydrated Dried">
                Dehydrated Dried {selectedMushroom}
              </option>
              <option value="Dried Substrate">
                Dried {selectedMushroom} Substrate
              </option>
            </select>
          </label>

          {selectedProduct && inventoryAction === "subtract" && (
            <>
              {selectedProduct === "Fresh" && (
                <dl className="inventory-display">
                  <dt>
                    Fresh Grade A {selectedMushroom} available in inventory:
                  </dt>
                  <dd>
                    {calculateInventory("grade_a_fresh", selectedMushroom)}g (
                    {gramsToPounds(
                      calculateInventory("grade_a_fresh", selectedMushroom)
                    )}
                    lbs)
                  </dd>
                </dl>
              )}
              {selectedProduct === "Dehydrated Dried" && (
                <dl className="inventory-display">
                  <dt>
                    Dehydrated Dried Grade A {selectedMushroom} available in
                    inventory:
                  </dt>
                  <dd>
                    {calculateInventoryByDriedType(
                      "grade_a_dried",
                      selectedMushroom,
                      "Dehydrated"
                    )}
                    g (
                    {gramsToPounds(
                      calculateInventoryByDriedType(
                        "grade_a_dried",
                        selectedMushroom,
                        "Dehydrated"
                      )
                    )}
                    lbs)
                  </dd>
                </dl>
              )}
              {selectedProduct === "Freeze Dried" && (
                <dl className="inventory-display">
                  <dt>
                    Freeze Dried Grade A {selectedMushroom} available in
                    inventory:
                  </dt>
                  <dd>
                    {calculateInventoryByDriedType(
                      "grade_a_dried",
                      selectedMushroom,
                      "Freeze"
                    )}
                    g (
                    {gramsToPounds(
                      calculateInventoryByDriedType(
                        "grade_a_dried",
                        selectedMushroom,
                        "Freeze"
                      )
                    )}
                    lbs)
                  </dd>
                </dl>
              )}
              {selectedProduct !== "Dried Substrate" && (
                <>
                  <label>
                    Grade A Weight in grams:
                    <input
                      type="number"
                      placeholder="0"
                      step="0.01"
                      value={aGradeWeight}
                      onChange={handleInputChange(setAGradeWeight)}
                    />
                    {(errorFreshAGradeMessage ||
                      errorDehydratedDriedAGradeMessage ||
                      errorFreezeDriedAGradeMessage) && (
                      <div style={{ color: "red" }}>
                        {errorFreshAGradeMessage}
                        {errorDehydratedDriedAGradeMessage}
                        {errorFreezeDriedAGradeMessage}
                      </div>
                    )}
                  </label>

                  {selectedProduct === "Fresh" && (
                    <dl className="inventory-display">
                      <dt>
                        Fresh Grade B {selectedMushroom} available in inventory:{" "}
                      </dt>
                      <dd>
                        {calculateInventory("grade_b_fresh", selectedMushroom)}g
                        (
                        {gramsToPounds(
                          calculateInventory("grade_b_fresh", selectedMushroom)
                        )}
                        lbs)
                      </dd>
                    </dl>
                  )}
                  {selectedProduct === "Dehydrated Dried" && (
                    <dl className="inventory-display">
                      <dt>
                        Dehydrated Dried Grade B {selectedMushroom} available in
                        inventory:
                      </dt>
                      <dd>
                        {calculateInventoryByDriedType(
                          "grade_b_dried",
                          selectedMushroom,
                          "Dehydrated"
                        )}
                        g (
                        {gramsToPounds(
                          calculateInventoryByDriedType(
                            "grade_b_dried",
                            selectedMushroom,
                            "Dehydrated"
                          )
                        )}
                        lbs)
                      </dd>
                    </dl>
                  )}
                  {selectedProduct === "Freeze Dried" && (
                    <dl className="inventory-display">
                      <dt>
                        Freeze Dried Grade B {selectedMushroom} available in
                        inventory:
                      </dt>
                      <dd>
                        {calculateInventoryByDriedType(
                          "grade_b_dried",
                          selectedMushroom,
                          "Freeze"
                        )}
                        g (
                        {gramsToPounds(
                          calculateInventoryByDriedType(
                            "grade_b_dried",
                            selectedMushroom,
                            "Freeze"
                          )
                        )}
                        lbs)
                      </dd>
                    </dl>
                  )}
                  <label>
                    Grade B Weight in grams:
                    <input
                      type="number"
                      placeholder="0"
                      step="0.01"
                      value={bGradeWeight}
                      onChange={handleInputChange(setBGradeWeight)}
                    />
                    {(errorFreshBGradeMessage ||
                      errorDehydratedDriedBGradeMessage ||
                      errorFreezeDriedBGradeMessage) && (
                      <div style={{ color: "red" }}>
                        {errorFreshBGradeMessage}
                        {errorDehydratedDriedBGradeMessage}
                        {errorFreezeDriedBGradeMessage}
                      </div>
                    )}
                  </label>
                  {selectedProduct === "Fresh" && (
                    <dl className="inventory-display">
                      <dt>
                        Fresh Grade C {selectedMushroom} available in inventory:{" "}
                      </dt>
                      <dd>
                        {calculateInventory("grade_c_fresh", selectedMushroom)}g
                        (
                        {gramsToPounds(
                          calculateInventory("grade_c_fresh", selectedMushroom)
                        )}
                        lbs)
                      </dd>
                    </dl>
                  )}
                  {selectedProduct === "Dehydrated Dried" && (
                    <dl className="inventory-display">
                      <dt>
                        Dehydrated Dried Grade C {selectedMushroom} available in
                        inventory:
                      </dt>
                      <dd>
                        {calculateInventoryByDriedType(
                          "grade_c_dried",
                          selectedMushroom,
                          "Dehydrated"
                        )}
                        g (
                        {gramsToPounds(
                          calculateInventoryByDriedType(
                            "grade_c_dried",
                            selectedMushroom,
                            "Dehydrated"
                          )
                        )}
                        lbs)
                      </dd>
                    </dl>
                  )}
                  {selectedProduct === "Freeze Dried" && (
                    <dl className="inventory-display">
                      <dt>
                        Freeze Dried Grade C {selectedMushroom} available in
                        inventory:
                      </dt>
                      <dd>
                        {calculateInventoryByDriedType(
                          "grade_c_dried",
                          selectedMushroom,
                          "Freeze"
                        )}
                        g (
                        {gramsToPounds(
                          calculateInventoryByDriedType(
                            "grade_c_dried",
                            selectedMushroom,
                            "Freeze"
                          )
                        )}
                        lbs)
                      </dd>
                    </dl>
                  )}
                  <label>
                    Grade C Weight in grams:
                    <input
                      type="number"
                      placeholder="0"
                      step="0.01"
                      value={cGradeWeight}
                      onChange={handleInputChange(setCGradeWeight)}
                    />
                    {(errorFreshCGradeMessage ||
                      errorDehydratedDriedCGradeMessage ||
                      errorFreezeDriedCGradeMessage) && (
                      <div style={{ color: "red" }}>
                        {errorFreshCGradeMessage}
                        {errorDehydratedDriedCGradeMessage}
                        {errorFreezeDriedCGradeMessage}
                      </div>
                    )}
                  </label>
                  <div className="wrapped-total-display">
                    {selectedProduct === "Fresh" && (
                      <dl className="inventory-display total-display">
                        <dt>
                          Total Fresh {selectedMushroom} available in inventory:{" "}
                        </dt>
                        <dd>
                          {calculateInventory("total_fresh", selectedMushroom)}g
                          (
                          {gramsToPounds(
                            calculateInventory("total_fresh", selectedMushroom)
                          )}
                          lbs)
                        </dd>
                      </dl>
                    )}
                    {selectedProduct === "Dehydrated Dried" && (
                      <dl className="inventory-display total-display">
                        <dt>
                          Total Dehydrated Dried {selectedMushroom} available in
                          inventory:
                        </dt>
                        <dd>
                          {calculateInventoryByDriedType(
                            "total_dried",
                            selectedMushroom,
                            "Dehydrated"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "total_dried",
                              selectedMushroom,
                              "Dehydrated"
                            )
                          )}
                          lbs)
                        </dd>
                      </dl>
                    )}
                    {selectedProduct === "Freeze Dried" && (
                      <dl className="inventory-display total-display">
                        <dt>
                          Total Freeze Dried {selectedMushroom} available in
                          inventory:
                        </dt>
                        <dd>
                          {calculateInventoryByDriedType(
                            "total_dried",
                            selectedMushroom,
                            "Freeze"
                          )}
                          g (
                          {gramsToPounds(
                            calculateInventoryByDriedType(
                              "total_dried",
                              selectedMushroom,
                              "Freeze"
                            )
                          )}
                          lbs)
                        </dd>
                      </dl>
                    )}

                    <div>
                      <p
                        className="inventory-modal-clickable-addition-button"
                        onClick={(e) =>
                          setTotalWeight(
                            aGradeWeight + bGradeWeight + cGradeWeight
                          )
                        }
                      >
                        Click to add all weights together
                      </p>
                      <label>
                        Total Weight in grams:
                        <input
                          type="number"
                          value={totalWeight}
                          placeholder="0"
                          onChange={handleInputChange(setTotalWeight)}
                        />
                        {(errorFreshTotalMessage ||
                          errorDehydratedDriedTotalMessage ||
                          errorFreezeDriedTotalMessage) && (
                          <div style={{ color: "red" }}>
                            {errorFreshTotalMessage}
                            {errorDehydratedDriedTotalMessage}
                            {errorFreezeDriedTotalMessage}
                          </div>
                        )}
                      </label>
                    </div>
                  </div>

                  {(removalType === "Sale" ||
                    removalType === "Free Sample") && (
                    <>
                      <label>
                        Customer Name:
                        <input
                          type="text"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                      </label>
                      <label>
                        Customer Email:
                        <input
                          type="email"
                          value={customerEmail}
                          onChange={(e) => setCustomerEmail(e.target.value)}
                        />
                      </label>
                      <div className="phone-input-styling">
                        <label>Customer Phone:</label>
                        <PhoneInput onPhoneChange={handlePhoneChange} />
                      </div>
                    </>
                  )}
                  {removalType === "Sale" && (
                    <div className="whole-dollar-style-container">
                      <label>
                        Dollars Received:
                        <div className="dollar-input-container">
                          <span className="dollar-symbol">$</span>
                          <input
                            type="number"
                            step="0.01"
                            value={dollarsReceived}
                            onChange={handleInputChange(setDollarsReceived)}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                </>
              )}
              {inventoryAction === "add" && selectedProduct && (
                <>
                  {addingType === "Outside Product" && (
                    <label>
                      Where is outside product coming from?
                      <input
                        type="text"
                        placeholder="Enter where product is from"
                        value={outsideProductLocation}
                        onChange={(e) =>
                          setOutsideProductLocation(e.target.value)
                        }
                      />
                    </label>
                  )}
                  <label>
                    Grade A {selectedMushroom} {selectedProduct} Weight (in
                    grams):
                    <input
                      type="number"
                      placeholder="0"
                      step="0.01"
                      value={addAGradeWeight}
                      // onChange={(e) => setAddAGradeWeight(e.target.value)}
                      onChange={handleInputChange(setAddAGradeWeight)}
                    />
                  </label>
                  <label>
                    Grade B {selectedMushroom} {selectedProduct} Weight (in
                    grams):
                    <input
                      type="number"
                      placeholder="0"
                      step="0.01"
                      value={addBGradeWeight}
                      // onChange={(e) => setAddBGradeWeight(e.target.value)}
                      onChange={handleInputChange(setAddBGradeWeight)}
                    />
                  </label>
                  <label>
                    Grade C {selectedMushroom} {selectedProduct} Weight (in
                    grams):
                    <input
                      type="number"
                      placeholder="0"
                      step="0.01"
                      value={addCGradeWeight}
                      // onChange={(e) => setAddCGradeWeight(e.target.value)}
                      onChange={handleInputChange(setAddCGradeWeight)}
                    />
                  </label>
                  <div className="add-inventory-total-weight">
                    <p
                      className="add-inventory-modal-clickable-addition-button"
                      onClick={(e) =>
                        setAddTotalMushroomWeight(
                          addAGradeWeight + addBGradeWeight + addCGradeWeight
                        )
                      }
                    >
                      Click to add all weights together
                    </p>
                    <label>
                      Total {selectedMushroom} {selectedProduct} Weight (in
                      grams):
                      <input
                        type="number"
                        placeholder="0"
                        step="0.01"
                        value={addTotalMushroomWeight}
                        // onChange={(e) => setAddTotalMushroomWeight(e.target.value)}
                        onChange={handleInputChange(setAddTotalMushroomWeight)}
                      />
                    </label>
                  </div>
                </>
              )}
            </>
          )}

          {selectedProduct === "Dried Substrate" && (
            <>
              <dl className="inventory-display">
                <dt>
                  Dried {selectedMushroom} Substrate available in inventory:
                </dt>
                <dd>
                  {calculateInventoryByDriedType(
                    "dried_substrate_grams",
                    selectedMushroom,
                    "Both"
                  )}
                  g (
                  {gramsToPounds(
                    calculateInventoryByDriedType(
                      "dried_substrate_grams",
                      selectedMushroom,
                      "Both"
                    )
                  )}
                  lbs)
                </dd>
              </dl>
              <label>
                Dried {selectedMushroom} Substrate Weight (in grams):
                <input
                  type="number"
                  placeholder="0"
                  step="0.01"
                  value={driedSubstrateWeight}
                  onChange={(e) =>
                    setDriedSubstrateWeight(parseFloat(e.target.value))
                  }
                />
              </label>
              {errorDriedSubstrateMessage && (
                <div style={{ color: "red" }}>{errorDriedSubstrateMessage}</div>
              )}
            </>
          )}

          {selectedProduct && (
            <>
              {inventoryAction === "subtract" &&
                removalType === "Powderizing" && (
                  <div className="whole-dollar-style-container">
                    <label>
                      Cost to Powderize:
                      <div className="dollar-input-container">
                        <span className="dollar-symbol">$</span>
                        <input
                          type="number"
                          step="0.01"
                          value={costToPowderize}
                          onChange={(e) =>
                            setCostToPowderize(parseFloat(e.target.value))
                          }
                        />
                      </div>
                    </label>
                  </div>
                )}
              <label
                htmlFor="transactionNote"
                className="transactions-add-a-note-label"
              >
                Add a Transaction Note:
              </label>
              <textarea
                name="transactionNote"
                id="transactionNote"
                className="transactions-add-a-note-textarea"
                value={transactionNote}
                onChange={(e) => setTransactionNote(e.target.value)}
              />
              <button type="button" onClick={() => submitTransactionData()}>
                {inventoryAction === "add"
                  ? `Add ${selectedMushroom} to Inventory`
                  : `Remove ${selectedMushroom} from Inventory`}
              </button>
              {errorBelowSubmitButton && (
                <div style={{ color: "red" }}>{errorBelowSubmitButton}</div>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
};
export default InventoryTransactionModal;
