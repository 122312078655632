import { useContext, useState } from "react";
import { GrowBagsContext } from "./LoggedIn";
import DriedModal from "./DriedModal";
import DiscardBagsModal from "./DiscardBagsModal";
import "css/growbags.css";

export default function GrowBags({
  selectedProperties,
  resetSelectedGrowBagsId,
  toggleInventoryModal,
  ...props
}) {
  selectedProperties = Array.isArray(selectedProperties)
    ? selectedProperties
    : [];
  const { handleGrowBagsSelect, handleGrowBagsChange, growBags } =
    useContext(GrowBagsContext);
  const {
    id,
    batch_id_number,
    mushroom_type,
    date_incubation_began,
    substrate_used,
    container_type,
    bag_type,
    number_of_bags,
    avg_ml_culture_per_bag,
    tent_number,
    current_status,
    date_growing_began,
    harvest_date,
    pre_incubation_note,
    pre_growth_note,
    post_harvest_note,
    fresh_grams_yielded,
    dried_date,
    dried_grams_yielded,
    post_dried_note,
    substrate_weight_grams,
    toggle_weigh_substrate,
    grade_a_fresh_grams,
    grade_b_fresh_grams,
    grade_c_fresh_grams,
    grade_a_dried_grams,
    grade_b_dried_grams,
    grade_c_dried_grams,
    dried_type,
  } = props;

  const todayDefaultDate = getCurrentDateString();

  const [showHarvestModal, setShowHarvestModal] = useState(false);
  const [harvestedBags, setHarvestedBags] = useState(0);
  const [freshWeight, setFreshWeight] = useState("");
  const [gradeA, setGradeA] = useState("");
  const [gradeB, setGradeB] = useState("");
  const [gradeC, setGradeC] = useState("");
  const [harvestDate, setHarvestDate] = useState(todayDefaultDate);
  const [postHarvestNote, setPostHarvestNote] = useState(null);
  const [harvestedBagsError, setHarvestedBagsError] = useState("");
  const [showGrades, setShowGrades] = useState(true);
  const [weighSubstrate, setWeighSubstrate] = useState(false);
  const [substrateWeight, setSubstrateWeight] = useState("");
  const [weightError, setWeightError] = useState(null);
  const [showDriedModal, setShowDriedModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [freshWeightManuallyUpdated, setFreshWeightManuallyUpdated] =
    useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "N/A";
    }
    return date.toLocaleDateString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  function getCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function daysSinceInoculation(dateIncubationBegan) {
    const today = new Date();
    const incubationDate = new Date(dateIncubationBegan);
    const diffTime = Math.abs(today - incubationDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  function tentNumberDisplay(status) {
    if (status === "Incubating") {
      return "Incubation Tent";
    } else {
      return `${tent_number}`;
    }
  }

  function displayNumberOfFreshGrams(status, freshYield) {
    if (status === "Incubating" || status === "Growing" || freshYield === 0) {
      return "No yield";
    } else {
      return `${freshYield} grams`;
    }
  }

  function displayNumberOfDriedGrams(status, driedYield) {
    if (
      status === "Incubating" ||
      status === "Growing" ||
      status === "Harvested" ||
      driedYield === 0
    ) {
      return "No yield";
    } else {
      return `${driedYield} grams`;
    }
  }

  function displaySubstrateWeight(toggleSubstrate, substrateWeight) {
    if (toggleSubstrate === true) {
      return `${substrateWeight} grams`;
    } else {
      return "No Substrate Weight";
    }
  }

  function displayDiscardModal() {
    setShowDiscardModal(true);
    handleGrowBagsSelect(undefined);
  }

  function displayBatchModal() {
    setShowHarvestModal(true);
    handleGrowBagsSelect(undefined);
  }

  function closeBatchModal() {
    setShowHarvestModal(false);
    setShowDriedModal(false);
    setShowDiscardModal(false);
    handleGrowBagsSelect(undefined);
    setHarvestDate(todayDefaultDate);
  }

  function findNextLetter(growBags, batch_id_number) {
    const regex = new RegExp(`^${batch_id_number}[a-z]$`);

    const existingLetters = growBags
      .filter((batch) => regex.test(batch.batch_id_number))
      .map((batch) => batch.batch_id_number.slice(-1))
      .sort();

    let nextLetter = "a";

    for (let i = 0; i < existingLetters.length; i++) {
      if (nextLetter !== existingLetters[i]) {
        return nextLetter;
      }
      nextLetter = String.fromCharCode(nextLetter.charCodeAt(0) + 1);
    }

    return nextLetter;
  }

  // Modify the onChange handler for the weighSubstrate toggle
  function handleWeighSubstrateToggle() {
    const newValue = !weighSubstrate;
    setWeighSubstrate(newValue);

    // If weighSubstrate is toggled on, set showGrades to false
    if (newValue) {
      setShowGrades(false);
    }
  }

  function daysFromIncubationToHarvest(harvest_date, date_incubation_began) {
    const harvestDate = new Date(harvest_date);
    const dateIncubationBegan = new Date(date_incubation_began);
    const diffTime = Math.abs(harvestDate - dateIncubationBegan);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  async function handleHarvestSubmit(growBags) {
    // Validate the form inputs and get the values for bags, freshWeight, gradeA, gradeB, and gradeC
    const totalWeight = gradeA + gradeB + gradeC;

    if (!showGrades) {
      setGradeA(0);
      setGradeB(0);
      setGradeC(0);
    } else if (totalWeight > freshWeight) {
      setWeightError(
        "Total weight of grades cannot exceed total fresh weight."
      );
    } else {
      setWeightError(null);
    }

    // Error handling
    if (harvestedBags === 0) {
      setHarvestedBagsError("Number of bags to harvest cannot be 0");
      return;
    } else if (totalWeight + freshWeight === 0) {
      setWeightError("You must input a number for weight");
      return;
    } else if (totalWeight > freshWeight) {
      setWeightError(
        "Total weight of grades cannot exceed total fresh weight."
      );
      return;
    } else {
      setHarvestedBagsError("");
      setWeightError("");
    }

    // Call the findNextLetter function to get the next available letter
    const nextLetter = findNextLetter(growBags, batch_id_number);
    // Create a new database entry for the harvested bags with the new information
    console.log("Harvest Props,", props);
    const newBatch = {
      ...props,
      id: "",
      culture_used: props.expand.culture_used,
      culture_name: props.expand?.culture_used?.culture_name || "",
      current_status: "Harvested",
      harvest_for_batch: id,
      batch_id_number: batch_id_number + nextLetter, // NEed to change this to ensure it is the next letter, not just a
      number_of_bags: harvestedBags,
      harvest_date: harvestDate,
      post_harvest_note: postHarvestNote,
      fresh_grams_yielded: freshWeight,
      grade_a_fresh_grams: gradeA,
      grade_b_fresh_grams: gradeB,
      grade_c_fresh_grams: gradeC,
      toggle_yield_grades: showGrades,
      toggle_weigh_substrate: weighSubstrate,
      substrate_weight_grams: substrateWeight,
    };

    // Update the original batch with the reduced number of bags
    const updatedOriginalBatch = {
      ...props,
      number_of_bags: props.number_of_bags - harvestedBags,
      number_of_harvests: props.number_of_harvests + 1,
    };
    await handleGrowBagsChange(props.id, updatedOriginalBatch);

    await handleGrowBagsChange(newBatch.id, newBatch);

    // Close the harvest modal
    setShowHarvestModal(false);
    setHarvestedBags(0);
    setGradeA("");
    setGradeB("");
    setGradeC("");
    setFreshWeight("");
    setHarvestDate(todayDefaultDate);
    setShowGrades(true);
    // Deselect the bag, hiding the GrowBagsEdit component
    resetSelectedGrowBagsId();
  }

  return (
    <div
      className="growbag"
      {...(!showHarvestModal && {
        onClick: () => handleGrowBagsSelect(id),
        style: { cursor: "pointer" },
      })}
    >
      <div className="selection-section">
        <div className="selection-header">Batch ID: {batch_id_number}</div>
        <div className="selection-contents">
          {number_of_bags} {container_type}s of {mushroom_type}
        </div>
        <div className="selection-contents small-break">{current_status}</div>
      </div>
      {selectedProperties.map((property) => {
        switch (property) {
          case "date_incubation_began":
            return (
              <div
                key={`date_incubation_began_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Date Incubation Began:</div>
                <div className="selection-contents">
                  {formatDate(date_incubation_began)} <br />
                  {daysSinceInoculation(date_incubation_began)} days since
                  inoculation
                </div>
              </div>
            );
          case "culture_used":
            return (
              <div key={`culture_used_${id}`} className="selection-section">
                <div className="selection-header">Culture Used:</div>
                <div className="selection-contents">
                  {props.culture_name ? props.culture_name : "Not Showing"}
                </div>
              </div>
            );
          case "substrate_used":
            return (
              <div key={`substrate_used_${id}`} className="selection-section">
                <div className="selection-header">Substrate Used:</div>
                <div className="selection-contents">{substrate_used}</div>
              </div>
            );
          case "tent_number_display":
            return (
              <div
                key={`tent_number_display_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Tent:</div>
                <div className="selection-contents">
                  {tentNumberDisplay(current_status)}
                </div>
              </div>
            );
          case "container_type":
            return (
              <div key={`container_type_${id}`} className="selection-section">
                <div className="selection-header">Container Type:</div>
                <div className="selection-contents">{container_type}</div>
              </div>
            );
          case "bag_type":
            return (
              <div key={`bag_type_${id}`} className="selection-section">
                <div className="selection-header">Bag Type:</div>
                <div className="selection-contents">{bag_type}</div>
              </div>
            );
          case "avg_ml_culture_per_bag":
            return (
              <div
                key={`avg_ml_culture_per_bag_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Avg mL Culture/Bag:</div>
                <div className="selection-contents">
                  {avg_ml_culture_per_bag} mL
                </div>
              </div>
            );
          case "date_growing_began":
            return (
              <div
                key={`date_growing_began_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Date Growth Began:</div>
                <div className="selection-contents">
                  {formatDate(date_growing_began)}
                </div>
              </div>
            );
          case "harvest_date":
            return (
              <div key={`harvest_date_${id}`} className="selection-section">
                <div className="selection-header">Harvest Date:</div>
                <div className="selection-contents">
                  {formatDate(harvest_date)}
                  <br />
                  {daysFromIncubationToHarvest(
                    harvest_date,
                    date_incubation_began
                  )}{" "}
                  days from inoculation to harvest
                </div>
              </div>
            );
          case "pre_incubation_note":
            return (
              <div
                key={`pre_incubation_note_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Pre-Incubation Note:</div>
                <div className="selection-contents">{pre_incubation_note}</div>
              </div>
            );
          case "pre_growth_note":
            return (
              <div key={`pre_growth_note_${id}`} className="selection-section">
                <div className="selection-header">Pre-Growth Note:</div>
                <div className="selection-contents">{pre_growth_note}</div>
              </div>
            );
          case "post_harvest_note":
            return (
              <div
                key={`post_harvest_note_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Post-Harvest Note:</div>
                <div className="selection-contents">{post_harvest_note}</div>
              </div>
            );
          case "fresh_grams_yielded":
            return (
              <div
                key={`fresh_grams_yielded_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Total Fresh Yield:</div>
                <div className="selection-contents">
                  {displayNumberOfFreshGrams(
                    current_status,
                    fresh_grams_yielded
                  )}
                </div>
              </div>
            );
          case "substrate_weight_grams":
            return (
              <div
                key={`substrate_weight_grams_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Substrate Weight:</div>
                <div className="selection-contents">
                  {displaySubstrateWeight(
                    toggle_weigh_substrate,
                    substrate_weight_grams
                  )}
                </div>
              </div>
            );
          case "dried_grams_yielded":
            return (
              <div
                key={`dried_grams_yielded_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Total Dried Yield:</div>
                <div className="selection-contents">
                  {displayNumberOfDriedGrams(
                    current_status,
                    dried_grams_yielded
                  )}
                </div>
                <div className="selection-contents">
                  Retention Percentage:{" "}
                  {((dried_grams_yielded / fresh_grams_yielded) * 100).toFixed(
                    2
                  )}{" "}
                  %
                </div>
              </div>
            );
          case "dried_type":
            return (
              <div key={`dried_type_${id}`} className="selection-section">
                <div className="selection-header">Dried Type:</div>
                <div className="selection-contents">{dried_type}</div>
              </div>
            );
          case "dried_date":
            return (
              <div key={`dried_date_${id}`} className="selection-section">
                <div className="selection-header">Dried Date:</div>
                <div className="selection-contents">
                  {formatDate(dried_date)}
                </div>
              </div>
            );
          case "post_dried_note":
            return (
              <div key={`post_dried_note_${id}`} className="selection-section">
                <div className="selection-header">Post-Dried Note:</div>
                <div className="selection-contents">{post_dried_note}</div>
              </div>
            );
          case "grade_a_fresh_grams":
            return (
              <div
                key={`grade_a_fresh_grams_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Fresh "Grade A" Yield:</div>
                <div className="selection-contents">
                  {displayNumberOfFreshGrams(
                    current_status,
                    grade_a_fresh_grams
                  )}
                </div>
              </div>
            );
          case "grade_b_fresh_grams":
            return (
              <div
                key={`grade_b_fresh_grams_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Fresh "Grade B" Yield:</div>
                <div className="selection-contents">
                  {displayNumberOfFreshGrams(
                    current_status,
                    grade_b_fresh_grams
                  )}
                </div>
              </div>
            );
          case "grade_c_fresh_grams":
            return (
              <div
                key={`grade_c_fresh_grams_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Fresh "Grade C" Yield:</div>
                <div className="selection-contents">
                  {displayNumberOfFreshGrams(
                    current_status,
                    grade_c_fresh_grams
                  )}
                </div>
              </div>
            );
          case "grade_a_dried_grams":
            return (
              <div
                key={`grade_a_dried_grams_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Dried "Grade A" Yield:</div>
                <div className="selection-contents">
                  {displayNumberOfDriedGrams(
                    current_status,
                    grade_a_dried_grams
                  )}
                </div>
                <div className="selection-contents">
                  Retention Percentage:{" "}
                  {((grade_a_dried_grams / grade_a_fresh_grams) * 100).toFixed(
                    2
                  )}{" "}
                  %
                </div>
              </div>
            );
          case "grade_b_dried_grams":
            return (
              <div
                key={`grade_b_dried_grams_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Dried "Grade B" Yield:</div>
                <div className="selection-contents">
                  {displayNumberOfDriedGrams(
                    current_status,
                    grade_b_dried_grams
                  )}
                </div>
                <div className="selection-contents">
                  Retention Percentage:{" "}
                  {((grade_b_dried_grams / grade_b_fresh_grams) * 100).toFixed(
                    2
                  )}{" "}
                  %
                </div>
              </div>
            );
          case "grade_c_dried_grams":
            return (
              <div
                key={`grade_c_dried_grams_${id}`}
                className="selection-section"
              >
                <div className="selection-header">Dried "Grade C" Yield:</div>
                <div className="selection-contents">
                  {displayNumberOfDriedGrams(
                    current_status,
                    grade_c_dried_grams
                  )}
                </div>
                <div className="selection-contents">
                  Retention Percentage:{" "}
                  {((grade_c_dried_grams / grade_c_fresh_grams) * 100).toFixed(
                    2
                  )}{" "}
                  %
                </div>
              </div>
            );
          default:
            return null;
        }
      })}
      {showDriedModal && (
        <div>
          <div className="harvest-modal-overlay"></div>
          <DriedModal
            closeModal={() => setShowDriedModal(false)}
            growBag={props}
            resetSelectedGrowBagsId={resetSelectedGrowBagsId}
          />
        </div>
      )}
      {showDiscardModal && (
        <div>
          <div className="harvest-modal-overlay"></div>
          <DiscardBagsModal
            closeModal={() => setShowDiscardModal(false)}
            growBag={props}
            resetSelectedGrowBagsId={resetSelectedGrowBagsId}
          />
        </div>
      )}

      {/* Puppy */}

      {props.current_status === "Incubating" && (
        <button
          className="discard-bags-btn"
          onClick={(e) => {
            e.stopPropagation();
            displayDiscardModal();
          }}
        >
          Discard Bags
        </button>
      )}

      {props.current_status === "Growing" && (
        <>
          <button
            className="harvest-batch-btn"
            onClick={(e) => {
              e.stopPropagation();
              displayBatchModal();
            }}
          >
            Harvest Batch
          </button>
          <button
            className="discard-bags-btn"
            onClick={(e) => {
              e.stopPropagation();
              displayDiscardModal();
            }}
          >
            Discard
          </button>
        </>
      )}
      {props.current_status === "Harvested" && (
        <button
          className="dry-batch-btn"
          onClick={(e) => {
            e.stopPropagation();
            setShowDriedModal(true);
          }}
        >
          Dry Batch
        </button>
      )}

      {(props.current_status === "Harvested" ||
        props.current_status === "Dried") && (
        <button
          className="send-to-inventory-btn"
          onClick={(e) => {
            e.stopPropagation();
            toggleInventoryModal(props);
          }}
        >
          Send to Inventory
        </button>
      )}

      {showHarvestModal && (
        <div>
          <div className="harvest-modal-overlay"></div>
          <div className="harvest-modal">
            <div className="harvest-modal-content">
              <div className="modal-header">
                <h4>
                  Harvesting {mushroom_type} {container_type}s -- Batch Id #:{" "}
                  {batch_id_number}
                </h4>
                <button
                  className="growbags-modal-close-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    closeBatchModal();
                  }}
                >
                  &times;
                </button>
              </div>

              <label htmlFor="bags">
                Number of {container_type}s to harvest:
              </label>
              <select
                id="bags"
                value={harvestedBags}
                onChange={(e) => {
                  setHarvestedBags(Number(e.target.value));
                }}
              >
                {Array.from({ length: props.number_of_bags + 1 }, (_, i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </select>
              <br />
              {harvestedBagsError && (
                <div className="num-bags-error-message">
                  {harvestedBagsError}
                </div>
              )}

              <label htmlFor="harvestDate">Harvest Date:</label>
              <input
                type="date"
                id="harvestDate"
                value={harvestDate}
                onChange={(e) => setHarvestDate(e.target.value)}
              />

              {/* Section where we need to ensure substrate weight gets measured */}

              <div className="toggle-container">
                <label
                  htmlFor="toggleWeighSubstrate"
                  className="growbags-edit__label"
                >
                  Input Substrate Weight?
                </label>
                <label className="switch">
                  <input
                    type="checkbox"
                    id="toggleWeighSubstrate"
                    checked={weighSubstrate}
                    onChange={handleWeighSubstrateToggle}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              {weighSubstrate && (
                <div className="substrate-weight-container">
                  <label htmlFor="substrateWeight">
                    Substrate weight (grams):
                  </label>
                  <input
                    type="number"
                    id="substrateWeight"
                    value={substrateWeight}
                    placeholder="0"
                    onChange={(e) => setSubstrateWeight(Number(e.target.value))}
                  />
                </div>
              )}

              <div className="toggle-container">
                <label
                  htmlFor="toggleShowGrades"
                  className="growbags-edit__label"
                >
                  Input Fruiting Body Weight by Grade?
                </label>
                <label className="switch">
                  <input
                    type="checkbox"
                    id="toggleShowGrades"
                    checked={showGrades}
                    onChange={() => {
                      const newValue = !showGrades;
                      setShowGrades(newValue);
                      setFreshWeightManuallyUpdated(
                        !freshWeightManuallyUpdated
                      );
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              {showGrades && (
                <div className="harvest-modal-grade-container">
                  <div className="harvest-modal-grade-input">
                    <label htmlFor="gradeA">
                      Grade A fruiting body weight (grams):
                    </label>
                    <input
                      type="number"
                      id="gradeA"
                      placeholder="0"
                      value={gradeA}
                      onChange={(e) => {
                        setGradeA(Number(e.target.value));
                        if (!freshWeightManuallyUpdated) {
                          setFreshWeight(
                            Number(e.target.value) + gradeB + gradeC
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="harvest-modal-grade-input">
                    <label htmlFor="gradeB">
                      Grade B fruiting body weight (grams):
                    </label>
                    <input
                      type="number"
                      id="gradeB"
                      placeholder="0"
                      value={gradeB}
                      onChange={(e) => {
                        setGradeB(Number(e.target.value));
                        if (!freshWeightManuallyUpdated) {
                          setFreshWeight(
                            Number(e.target.value) + gradeA + gradeC
                          );
                        }
                      }}
                    />
                  </div>

                  <div className="harvest-modal-grade-input">
                    <label htmlFor="gradeC">
                      Grade C fruiting body weight (grams):
                    </label>
                    <input
                      type="number"
                      id="gradeC"
                      placeholder="0"
                      value={gradeC}
                      onChange={(e) => {
                        setGradeC(Number(e.target.value));
                        if (!freshWeightManuallyUpdated) {
                          setFreshWeight(
                            Number(e.target.value) + gradeA + gradeB
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              <label htmlFor="freshWeight">
                Total fresh fruiting body weight (grams):
              </label>
              {showGrades && (
                <p
                  className="clickable-addition-button"
                  onClick={(e) => setFreshWeight(gradeA + gradeB + gradeC)}
                >
                  Click to add all fruiting body weights together
                </p>
              )}
              <input
                type="number"
                id="freshWeight"
                value={freshWeight}
                placeholder="0"
                onChange={(e) => {
                  setFreshWeight(Number(e.target.value));
                  setFreshWeightManuallyUpdated(true);
                }}
              />
              {weightError && (
                <div className="error-message">{weightError}</div>
              )}

              <label htmlFor="postHarvestNote">Enter Post Harvest Note:</label>
              <br />
              <textarea
                className="growbags-modal-textarea"
                name="postHarvestNote"
                id="postHarvestNote"
                value={postHarvestNote}
                onChange={(e) => setPostHarvestNote(e.target.value)}
              />
              <button onClick={() => handleHarvestSubmit(growBags)}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
