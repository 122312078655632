import { useState, useContext } from "react";
import GrowBags from "./GrowBags";
import { GrowBagsContext } from "./LoggedIn";
import "css/growbags.css";

export default function GrowBagsList({
  growBags,
  resetSelectedGrowBagsId,
  activeCultures,
}) {
  const { handleGrowBagsAdd } = useContext(GrowBagsContext);
  // State variables to keep track of mushroom type and grow bags state
  const [selectedMushroomType, setSelectedMushroomType] = useState("All");
  const [selectedGrowBagsState, setSelectedGrowBagsState] = useState("All");
  const [selectedTentNumber, setSelectedTentNumber] = useState("All");
  const [selectedCulture, setSelectedCulture] = useState("All");
  const [selectedMasterCulture, setSelectedMasterCulture] = useState("");
  const [selectedGroupBy, setSelectedGroupBy] = useState("mushroom_type");
  const [selectedSortBy, setSelectedSortBy] = useState("date_incubation_began");
  const [selectedProperties, setSelectedProperties] = useState(
    new Set([
      "batch_id_number",
      "number_of_bags",
      "date_incubation_began",
      "tent_number_display",
      "avg_ml_culture_per_bag",
      "culture_used",
    ])
  );
  const propertiesForDropdown = new Set([
    "batch_id_number",
    "number_of_bags",
    "date_incubation_began",
    "container_type",
  ]);
  const [sortDirection, setSortDirection] = useState(1); // 1 for descending

  // Filters grow bags based on selected mushroom type and grow bags state
  const filteredGrowBags = growBags.filter((relevantGrowBags) => {
    const selectedCultureFromGrowBag =
      selectedCulture + " from master culture: " + selectedMasterCulture;
    return (
      (selectedMushroomType === "All" ||
        relevantGrowBags.mushroom_type === selectedMushroomType) &&
      (selectedGrowBagsState === "All" ||
        relevantGrowBags.current_status === selectedGrowBagsState) &&
      (selectedTentNumber === "All" ||
        tentNumberDisplay(relevantGrowBags) === selectedTentNumber) &&
      (selectedCulture === "All" ||
        relevantGrowBags.culture_used === selectedCultureFromGrowBag)
    ); // Filter by culture with name in form of grow_bags on database
  });

  // Gets unique mushroom types from the growBags array
  const mushroomTypes = Array.from(
    new Set(growBags.map((growBags) => growBags.mushroom_type))
  );

  const tentNumbers = Array.from(
    new Set(
      growBags.map((growBags) =>
        growBags.current_status === "Incubating" || growBags.tent_number === 0
          ? "Incubation Tent"
          : `Tent ${growBags.tent_number}`
      )
    )
  ).sort((a, b) =>
    a === "Incubation Tent" ? -1 : b === "Incubation Tent" ? 1 : 0
  );

  const culturesForDropdown = Array.from(new Set(activeCultures));

  function tentNumberDisplay(relevantGrowBags) {
    if (relevantGrowBags.current_status === "Incubating") {
      return "Incubation Tent";
    } else {
      return `Tent ${relevantGrowBags.tent_number}`;
    }
  }

  function handleMushroomTypeChange(e) {
    setSelectedMushroomType(e.target.value);
  }

  function handleGrowBagsStateChange(e) {
    setSelectedGrowBagsState(e.target.value);
  }

  function handleTentNumberChange(e) {
    setSelectedTentNumber(e.target.value);
  }

  function handleCultureChange(e) {
    const selectedValues = JSON.parse(e.target.value);
    setSelectedCulture(selectedValues.cultureName);
    setSelectedMasterCulture(selectedValues.masterCultureName);
  }

  function handlePropertyChange(e) {
    const { name, checked } = e.target;
    setSelectedProperties((prevSelectedProperties) => {
      const newSelectedProperties = new Set(prevSelectedProperties);
      if (checked) {
        newSelectedProperties.add(name);
      } else {
        newSelectedProperties.delete(name);
      }
      return newSelectedProperties;
    });
  }

  function handleGroupByChange(e) {
    setSelectedGroupBy(e.target.value);
  }

  function handleSortDirectionChange() {
    setSortDirection((prevSortDirection) => (prevSortDirection === 1 ? -1 : 1));
  }

  function capitalizeFirstLetterOfEachWord(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <div className="growbags-list">
      <div className="growbags-list-container">
        <button
          className="growbags-list__add-growbags-btn-container"
          onClick={handleGrowBagsAdd}
        >
          Add Growth
        </button>
      </div>
      <div className="growbags-container">
        <div className="growbags-dropdown-list">
          <label htmlFor="mushroomTypeFilter">Filter by Mushroom Type:</label>
          <select
            name="mushroomTypeFilter"
            id="mushroomTypeFilter"
            value={selectedMushroomType}
            onChange={handleMushroomTypeChange}
          >
            <option value="All">All</option>
            {mushroomTypes.map((mushroomType, index) => (
              <option key={index} value={mushroomType}>
                {mushroomType}
              </option>
            ))}
          </select>
          <label htmlFor="cultureFilter">Filter by Culture:</label>
          <select
            name="cultureFilter"
            id="cultureFilter"
            value={JSON.stringify({
              cultureName: selectedCulture,
              masterCultureName: selectedMasterCulture,
            })}
            onChange={handleCultureChange}
          >
            <option
              value={JSON.stringify({
                cultureName: "All",
                masterCultureName: "",
              })}
            >
              All
            </option>{" "}
            {culturesForDropdown.map((culture, index) => (
              <option
                key={index}
                value={JSON.stringify({
                  cultureName: culture.culture_name,
                  masterCultureName: culture.master_culture_name,
                })}
              >
                {culture.culture_name}
              </option>
            ))}
          </select>
          <label htmlFor="growBagsStateFilter">
            Filter by Grow Bags State:
          </label>
          <select
            name="growBagsStateFilter"
            id="growBagsStateFilter"
            value={selectedGrowBagsState}
            onChange={handleGrowBagsStateChange}
          >
            <option value="All">All</option>
            <option value="Incubating">Incubating</option>
            <option value="Growing">Growing</option>
          </select>
          <label htmlFor="tentNumberFilter">Filter by Tent Number:</label>
          <select
            name="tentNumberFilter"
            id="tentNumberFilter"
            value={selectedTentNumber}
            onChange={handleTentNumberChange}
          >
            <option value="All">All</option>
            {tentNumbers.map((tentNumber, index) => (
              <option key={index} value={tentNumber}>
                {tentNumber}
              </option>
            ))}
          </select>
          <label htmlFor="groupByFilter">Group By:</label>
          <select
            name="groupByFilter"
            id="groupByFilter"
            value={selectedGroupBy}
            onChange={handleGroupByChange}
          >
            <option value="mushroom_type">Mushroom Type</option>
            <option value="tent_number">Tent Number</option>
            <option value="status">Status</option>
          </select>
          <span className="sort-label">Sort By:</span>
          <span
            className={`sort-direction ${
              sortDirection === 1 ? "ascending" : "descending"
            }`}
            onClick={handleSortDirectionChange}
          >
            {sortDirection === 1 ? "Ascending \u25BC" : "Descending \u25B2"}
          </span>
          <select
            name="sortByFilter"
            id="sortByFilter"
            value={selectedSortBy}
            onChange={(e) => setSelectedSortBy(e.target.value)}
          >
            {Array.from(propertiesForDropdown).map((property) => (
              <option key={property} value={property}>
                {capitalizeFirstLetterOfEachWord(property.replace(/_/g, " "))}
              </option>
            ))}
          </select>
        </div>
        <div className="properties-selection-section">
          <h3 className="properties-header">Select properties to display:</h3>
          <div className="checkbox-container">
            <div className="property-wrapper">
              {[
                "date_incubation_began",
                "culture_used",
                "substrate_used",
                "tent_number_display",
                "bag_type",
                "container_type",
                "avg_ml_culture_per_bag",
                "date_growing_began",
                "pre_incubation_note",
                "pre_growth_note",
              ].map((property) => (
                <div
                  key={property}
                  className={`property-box ${
                    selectedProperties.has(property) ? "selected" : ""
                  }`}
                  onClick={() =>
                    handlePropertyChange({
                      target: {
                        name: property,
                        checked: !selectedProperties.has(property),
                      },
                    })
                  }
                >
                  {property.replace(/_/g, " ")}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          {(() => {
            const groupByOptions = {
              mushroom_type: () => mushroomTypes,
              tent_number: () => tentNumbers,
              status: () => ["Incubating", "Growing"],
            };

            const getGroupByValue = {
              mushroom_type: (growBag) => growBag.mushroom_type,
              tent_number: (growBag) => tentNumberDisplay(growBag),
              status: (growBag) => growBag.current_status,
            };

            return groupByOptions[selectedGroupBy]().map((groupValue) => {
              if (
                (selectedGroupBy === "mushroom_type" &&
                  selectedMushroomType !== "All" &&
                  groupValue !== selectedMushroomType) ||
                (selectedGroupBy === "tent_number" &&
                  selectedTentNumber !== "All" &&
                  groupValue !== selectedTentNumber) ||
                (selectedGroupBy === "status" &&
                  selectedGrowBagsState !== "All" &&
                  groupValue !== selectedGrowBagsState)
              ) {
                return null;
              }

              const filteredGrowBagsByGroup = filteredGrowBags.filter(
                (growBag) =>
                  getGroupByValue[selectedGroupBy](growBag) === groupValue
              );

              return (
                <div key={groupValue}>
                  <h2 className="group-by-header">{groupValue}</h2>
                  {filteredGrowBagsByGroup
                    .sort((a, b) => {
                      let comparison = 0;
                      if (selectedSortBy === "batch_id_number") {
                        // Convert batch_id_number to integer for comparison
                        let numberA = parseInt(a[selectedSortBy], 10);
                        let numberB = parseInt(b[selectedSortBy], 10);

                        // Compare the numbers
                        if (numberA < numberB) {
                          comparison = -1;
                        }
                        if (numberA > numberB) {
                          comparison = 1;
                        }

                        return comparison * sortDirection;
                      } else {
                        if (a[selectedSortBy] < b[selectedSortBy]) {
                          comparison = -1;
                        }
                        if (a[selectedSortBy] > b[selectedSortBy]) {
                          comparison = 1;
                        }
                      }

                      // Multiply comparison by sort direction. If it's ascending, multiply by 1 (no change). If it's descending, multiply by -1 (reverse order)
                      return comparison * sortDirection;
                    })
                    .map(
                      (growBag) =>
                        (growBag.current_status === "Incubating" ||
                          growBag.current_status === "Growing") &&
                        growBag.number_of_bags > 0 && (
                          <GrowBags
                            key={growBag.id}
                            {...growBag}
                            selectedProperties={Array.from(selectedProperties)}
                            resetSelectedGrowBagsId={resetSelectedGrowBagsId}
                          />
                        )
                    )}
                </div>
              );
            });
          })()}
        </div>
      </div>
    </div>
  );
}
